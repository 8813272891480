import * as React from 'react';
import { FC, useCallback, useEffect, useState } from 'react';

import { container } from 'tsyringe';
import { Stack } from '@mui/system';
import { Controller, useForm } from 'react-hook-form';
import { FormControlLabel } from '@mui/material';
import _, { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { observer } from 'mobx-react-lite';
import { Filter, RangePicker } from '@/shared/ui';
import { Label } from '@/shared/ui/Label/Label';
import { Checkbox } from '@/shared/ui/Checkbox/Checkbox';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { FilterMesForm } from '@/shared/ui/FilterMessageTracing/type';
import {
  defaultFilter,
  defaultValues,
  MESSAGE_FILTER,
} from '@/shared/ui/FilterMessageTracing/utils/constants';
import { IndexedDBFilterService } from '@/shared/ui/FilterMessageTracing/api/IndexedDBFilterService';
import { DisplayMessageService } from '@/entities/MessageTracing';
import { DisplayMessageStore } from '@/entities/MessageTracing/model/DisplayMessageStore';
import { dateParser } from '@/shared/ui/FilterMessageTracing/utils/utils';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

export interface FilterMessageTracingProps {
  blockId: string;
  addTrace: boolean;
}

const displayMessageService = container.resolve(DisplayMessageService);
const displayMessageStore = container.resolve(DisplayMessageStore);
const indexedDBFilterService = container.resolve(IndexedDBFilterService);

export const FilterMessageTracing: FC<FilterMessageTracingProps> = observer(
  ({ blockId, addTrace }) => {
    const { flowId } = useParams();
    const { get } = useSearchParamsTemplate();
    const messageId = get(SearchParams.MessageId);
    const [isFilterActive, setIsFilterActive] = useState(false);
    const keyForIndexedDB = `filterData${flowId}.${blockId}`;

    const idbValue =
      displayMessageStore?.filterValues &&
      JSON.parse(JSON.stringify(displayMessageStore?.filterValues.filterData));

    const { filterId } = displayMessageStore;

    const formValues = useCallback(() => {
      if (isEmpty(idbValue) || keyForIndexedDB !== filterId) {
        return defaultValues;
      }
      return {
        ...idbValue,
        endDate: !isEmpty(idbValue.endDate) ? dateParser(idbValue.endDate) : null,
      };
    }, [idbValue, keyForIndexedDB, filterId]);

    const { control, watch, handleSubmit, reset } = useForm<FilterMesForm>({
      defaultValues,
      values: formValues(),
      mode: 'onChange',
    });

    useEffect(() => {
      if (formValues() === defaultValues) return;
      setIsFilterActive(true);
    }, [formValues]);

    const isLast = watch('isLast', defaultValues.isLast);
    const isSuccess = watch('isSuccess', defaultValues.isSuccess);
    const isFailed = watch('isFailed', defaultValues.isFailed);
    const endDate = watch('endDate', defaultValues.endDate);

    const tooltip = () => {
      const format = 'DD.MM.YYYY';

      const [start, end] = endDate || [];
      const renderDate = (date) => {
        if (!date) return '<не указано>';
        return dayjs(date).format(format);
      };
      return (
        <Stack gap={1}>
          <p>Фильтр</p>
          {(start || end) && (
            <Stack>
              <p>По дате последнего обновления:</p>
              <p>
                {renderDate(start)} - {renderDate(end)}
              </p>
            </Stack>
          )}
          {isSuccess && (
            <Stack direction='row' justifyContent='space-between' width='100%'>
              <p style={{ marginRight: 5 }}>Успешные:</p>
              <Checkbox checked />
            </Stack>
          )}
          {isFailed && (
            <Stack direction='row' justifyContent='space-between' width='100%'>
              <p style={{ marginRight: 5 }}>С ошибками:</p>
              <Checkbox checked />
            </Stack>
          )}
          {isLast && (
            <Stack direction='row' justifyContent='space-between' width='100%'>
              <p style={{ marginRight: 5 }}>Переотправленные:</p>
              <Checkbox checked />
            </Stack>
          )}
        </Stack>
      );
    };

    const resetFilter = useCallback(() => {
      reset();
      indexedDBFilterService.deleteFilterData(keyForIndexedDB).then();
      displayMessageStore.filterId = null;
      displayMessageStore.filterValues = null;
      displayMessageStore.filterList = defaultFilter;
      setIsFilterActive(false);
      displayMessageService.getMessages(blockId, {
        pagination: { size: 1, page: 0 },
        filterList: defaultFilter,
        addTrace,
        messageId,
      });
    }, [reset]);

    const onSubmit = (filterData: FilterMesForm) => {
      const date = new Date();
      const filter = _.pickBy(filterData, _.identity);
      const filterList = displayMessageService.setFilterList(filter);
      const body = !isEmpty(filterList) ? JSON.parse(JSON.stringify(filterList)) : [];
      setIsFilterActive(true);
      indexedDBFilterService
        .saveFilterData(
          keyForIndexedDB,
          {
            filterData,
            body,
          },
          date
        )
        .then();

      displayMessageService
        .getMessages(blockId, {
          pagination: { size: 1, page: 0 },
          filterList,
          addTrace,
          messageId,
        })
        .then();
    };

    return (
      <Filter
        isActive={isFilterActive}
        title='Фильтр'
        tooltip={tooltip()}
        onFilter={handleSubmit(onSubmit)}
        onReset={resetFilter}
        filterMessage
      >
        <form id='filterMessage'>
          <Stack gap={5}>
            <Stack gap={2.5}>
              <Label>Дата обработки сообщения</Label>
              <Stack direction='row' gap={2.5} height={48}>
                <Controller
                  name='endDate'
                  control={control}
                  render={({ field }) => (
                    <RangePicker
                      value={field.value || [null, null]}
                      onCalendarChange={(values) => {
                        const [start, end] = values;
                        const val = start || end ? values : [null, null];
                        // setDateValue(val);
                        field.onChange(val);
                      }}
                    />
                  )}
                />
              </Stack>

              {MESSAGE_FILTER.map((option) => (
                <Controller
                  control={control}
                  name={option.value}
                  key={option.value}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      style={{ marginLeft: 5 }}
                      control={
                        <Checkbox checked={value} onChange={onChange} style={{ marginRight: 10 }} />
                      }
                      label={option.label}
                    />
                  )}
                />
              ))}
            </Stack>
          </Stack>
        </form>
      </Filter>
    );
  }
);

FilterMessageTracing.displayName = 'FilterMessageTracing';
