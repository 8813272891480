import { FC, memo, useEffect, useState } from 'react';

import { container } from 'tsyringe';
import Paper from '@mui/material/Paper';
import { ExternalAppList } from '@/widgets/ExternalAppList';
import { ExternalAppService } from '@/entities/ExternalApp';
import { ErrorWrapper } from '@/shared/ui';
import { UpsertExternalAppDialogForm } from '@/features/UpsertExternalApp';
import { Loader } from '@/shared/ui/Loader/Loader';

import styles from './ExternalAppsPage.module.scss';

const externalAppService = container.resolve(ExternalAppService);

export interface ExternalAppsPageProps {}

export const ExternalAppsPage: FC<ExternalAppsPageProps> = memo(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [thereIsError, setThereIsError] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    externalAppService
      .getExternalApps()
      .then(() => setThereIsError(false))
      .catch(() => setThereIsError(true))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <Loader width={60} height={60} />;
  }

  if (thereIsError) {
    return (
      <ErrorWrapper
        title='Не удалось получить внешние сервисы'
        repeat={externalAppService.getExternalApps}
      />
    );
  }

  return (
    <Paper elevation={0} className={styles.page}>
      <div className={styles.root}>
        <ExternalAppList />
      </div>
      <UpsertExternalAppDialogForm />
    </Paper>
  );
});

ExternalAppsPage.displayName = 'ExternalAppsPage';
