export interface Normalized<T> {
  entities: { [key: string]: T };
  ids: string[];
}

export const normalize = <T>(list: T[], key: keyof T): Normalized<T> => {
  const entities: Record<string, T> = {};
  const ids: Set<string> = new Set();

  list.forEach((element) => {
    const elementKey = element[key];
    entities[elementKey as string] = element;
    ids.add(elementKey as string);
  });
  return { ids: Array.from(ids), entities };
};
