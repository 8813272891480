import { FC } from 'react';

import { observer } from 'mobx-react-lite';
import Stack from '@mui/system/Stack';
import { GlobalOutlined } from '@ant-design/icons'
import { notify } from "@/shared/ui/Toast/notify";
import Tooltip from '@/shared/ui/Tooltip';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { DownloadIcon } from '@/shared/ui/Icons/DownloadIcon/DownloadIcon';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import { SettingsIcon } from '@/shared/ui/Icons/SettingsIcon/SettingsIcon';
import { LoaderIcon } from '@/shared/ui/Icons/LoaderIcon/LoaderIcon';
import { transformBytesToKb } from '@/shared/lib/transformBytesToKb';

import styles from './FileCard.module.scss';

export interface FileCardProps extends Pick<File, 'name' | 'size'> {
  isNew?: boolean;
  loading?: boolean;
  downloadLink?: string;
  downloadHeaders?: string;
  isRemove?: boolean;
  type?: string;

  onRemove(): void;
  onSettings?(): void;
}

function saveFile(url: string, filename: string) {
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "file-name";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export const FileCard: FC<FileCardProps> = observer(
  ({
    name,
    size,
    isNew,
    loading,
    downloadLink,
    downloadHeaders,
    isRemove = true,
    onRemove,
    type,
    onSettings,
  }) => {
    if (loading) {
      return (
        <Stack className={styles.root} gap={1} alignItems='center' justifyContent='center'>
          <LoaderIcon />
        </Stack>
      );
    }

    const renderContent = () => {
      if (type === 'image/jpeg' || type === 'image/webp') {
        return (
          <Stack className={styles.image}>
            <img src={downloadLink} alt={name} />
          </Stack>
        );
      }
      if (type === 'video/mp4') {
        return (
          <Stack className={styles.image}>
            <video controls>
              <source src={downloadLink} type='video/mp4' />
            </video>
          </Stack>
        );
      }
      return null;
    };

    const handleDownLoad = async () => {
      const headers = downloadHeaders && Object.fromEntries(
        downloadHeaders.split(/^/m)
          .map((hdr) => hdr.split(':')
            .map((entry) => entry.trim()))
          .filter((arr) => arr.length === 2)
      );
      try {
        const response = await fetch(downloadLink, headers ? { headers } : {});
        const blob = await response.blob()
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blobUrl = URL.createObjectURL(blob);
        saveFile(blobUrl, name);

        URL.revokeObjectURL(blobUrl);
      } catch (e) {
        try {
          saveFile(downloadLink, name);
        } catch (err) {
          notify.error(`Не удалось скачать файл: ${name}`);
          console.error("Error in fetching and downloading file:", err);
        }
      }
    }

    const renderDownloadTrigger = () => {
      if (!downloadLink) return null;
      const downloadIcon = size ? <DownloadIcon /> : <GlobalOutlined style={{ fontSize: 16 }} />;
      const virtualDownload = size < 100 * 1024 * 1024;// 100mb
      const downloadBtn = (
        <Button
          circle
          size={ButtonSizes.Small}
          variant={ButtonVariants.Outlined}
          onClick={virtualDownload && handleDownLoad}
        >
          {downloadIcon}
        </Button>
      );
      if (virtualDownload) return <Tooltip title='Скачать'>{downloadBtn}</Tooltip>;
      return (
        <Tooltip title='Скачать'>
          <a href={downloadLink} download={name}>
            {downloadBtn}
          </a>
        </Tooltip>
      );
    };

    return (
      <Stack className={styles.root} gap={1}>
        {isNew && (
          <div className={styles.ribbon}>
            <span>новый</span>
          </div>
        )}
        <Stack gap={0.5}>
          <p className={styles.title}>{name}</p>
        </Stack>
        {renderContent()}
        <Stack direction='row' justifyContent='space-between'>
          <Stack direction="row" gap={2} alignItems="center">
            {size && (
              <p className={styles.description}>{transformBytesToKb(size)} Kb</p>
            )}
          </Stack>
          <Stack direction='row' gap={1} zIndex={500}>
            <>
              {renderDownloadTrigger()}
              {onSettings && (
                <Tooltip title='Настройки'>
                  <span>
                    <Button
                      circle
                      size={ButtonSizes.Small}
                      variant={ButtonVariants.Outlined}
                      onClick={onSettings}
                    >
                      <SettingsIcon />
                    </Button>
                  </span>
                </Tooltip>
              )}
              {isRemove && (
                <Tooltip title='Удалить'>
                  <span>
                    <Button
                      circle
                      size={ButtonSizes.Small}
                      variant={ButtonVariants.Outlined}
                      onClick={onRemove}
                    >
                      <TrashIcon />
                    </Button>
                  </span>
                </Tooltip>
              )}
            </>
          </Stack>
        </Stack>
      </Stack>
    );
  }
);

FileCard.displayName = 'FileCard';
