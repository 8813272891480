import React, { FC, useLayoutEffect } from 'react';

import { useFieldArray } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import Box from '@mui/system/Box';
import { Label } from '@/shared/ui/Label/Label';
import { ListItem } from '../FieldList';
import { DraggableList } from '@/shared/ui/DraggableList';

import styles from '../FieldList/view/List.module.scss';

export interface FieldObjectProps {
  control: any;
  name: string;
  label?: string;
  defaultValue?: any;
  description?: string;
  renderElement: (index: number) => JSX.Element;
  disabled?: boolean;
  isDivider?: boolean;
  draggable?: boolean;
}

export const FieldObject: FC<FieldObjectProps> = observer(
  ({
    control,
    name,
    label,
    defaultValue,
    description,
    renderElement,
    disabled,
    isDivider = false,
    draggable = false,
  }) => {
    const { fields, append } = useFieldArray({
      name,
      control,
    });

    useLayoutEffect(() => {
      if (fields.length === 0) {
        if (defaultValue && Array.isArray(defaultValue) && defaultValue.length > 0) {
          append(defaultValue);
        } else {
          append({});
        }
      }
    }, [fields.length]);

    const renderItem = ({ id }: { id: string }, index: number) => (
      <ListItem
        id={id}
        key={id}
        draggable={draggable}
        render={() => renderElement(index)}
        isDivider={isDivider}
      />
    );

    return (
      <div className={cn(styles.root, { [styles.disabled]: disabled })}>
        <Box gap={2} display='flex' flexDirection='column'>
          {label && (
            <Label tooltip={description}>
              <h3 className={styles.title}>{label}</h3>
            </Label>
          )}
          <DraggableList items={fields} draggable={draggable} renderItem={renderItem} />
        </Box>
      </div>
    );
  }
);
