import { makeAutoObservable, runInAction } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';

import { ExecutorLogStore } from '../model/ExecutorLogStore';
import { ExecutorLog } from '../model/types';

@singleton()
export class ExecutorLogService {
  constructor(private apiService: ApiService, private executorLogStore: ExecutorLogStore) {
    makeAutoObservable(this);
  }

  async findById(id: string) {
    runInAction(() => {
      this.executorLogStore.isLoading = true;
      this.executorLogStore.executorLog = null;
    });
    const response = await this.apiService.instance.get<ExecutorLog>(
      `/editor/api/v2/monitoring/executorLog/${id}`
    );
    runInAction(() => {
      this.executorLogStore.executorLog = response.data;
      this.executorLogStore.isLoading = false;
    });
    return response;
  }

  async findByBlockId(blockId: string, typeList: string[] = ['Block']) {
    runInAction(() => {
      this.executorLogStore.isLoadingByBlock = true;
    });
    const response = await this.apiService.instance.post<ExecutorLog[]>(
      `/editor/api/v1/executorLog/search`,
      {
        pagination: {
          page: 0,
          size: 10,
        },
        filterList: [
          {
            typeCriteria: 'and',
            name: 'blockId',
            operator: 'is',
            value: blockId,
          },
          {
            typeCriteria: 'and',
            name: 'type',
            operator: 'in',
            value: typeList,
          },
        ],
        sortList: [
          {
            name: 'createDate',
            direction: 'DESC',
          },
        ],
      }
    );
    runInAction(() => {
      this.executorLogStore.executorLogByBlock = response.data.reverse();
      this.executorLogStore.isLoadingByBlock = false;
    });
    return response.data;
  }

  async search(typeList: string[] = ['Block']) {
    runInAction(() => {
      this.executorLogStore.isLoadingByIntegra = true;
    });
    const response = await this.apiService.instance.post<ExecutorLog[]>(
      `/editor/api/v1/executorLog/search`,
      {
        pagination: {
          page: 0,
          size: 500,
        },
        filterList: [
          {
            typeCriteria: 'and',
            name: 'type',
            operator: 'in',
            value: typeList,
          },
        ],
        sortList: [
          {
            name: 'createDate',
            direction: 'DESC',
          },
        ],
      }
    );
    runInAction(() => {
      this.executorLogStore.executorLogByIntegra = response.data.reverse();
      this.executorLogStore.isLoadingByIntegra = false;
    });
    return response.data;
  }

  get executorLog() {
    return this.executorLogStore.executorLog;
  }
}
