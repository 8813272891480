import React, { FC } from 'react';

import Stack from '@mui/system/Stack';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import Box from '@mui/system/Box';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { isEmpty } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';

import { Tenant, TenantBody } from '@/entities/Tenant/stores/types';
import { TenantStore } from '@/entities/Tenant/stores/TenantStore';
import { TenantService } from '@/entities/Tenant/api/TenantService';
import { FieldList } from '@/shared/ui/Fields/components/FieldList/FieldList';
import Button from '../../../shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { defaultValue } from '@/entities/Tenant/utils/constant';

import { FieldSwitch } from '@/shared/ui/Fields/components/FieldSwitch/FieldSwitch';
import {
  tenantFormAddValidation,
  tenantFormEditValidation,
} from '@/entities/Tenant/lib/TenantFormValidation';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

const tenantStore = container.resolve(TenantStore);
const tenantService = container.resolve(TenantService);

export interface TenantFormProps {
  tenantId?: string;
  hideChooseScriptButton?: boolean;

  onSuccess?(tenant: TenantBody): void;
}

export const TenantForm: FC<TenantFormProps> = observer(({ tenantId, onSuccess }) => {
  const { set } = useSearchParamsTemplate();
  const isEditMode = !!tenantId;
  const validation = isEditMode ? tenantFormEditValidation : tenantFormAddValidation;
  const initialValues = isEditMode ? tenantStore.tenants?.entities[tenantId] : defaultValue;

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Tenant>({
    resolver: yupResolver(validation),
    values: initialValues,
    defaultValues: defaultValue,
    mode: 'onChange',
  });

  const isActive = useWatch({ control, name: 'isActive' });

  const isChangeActive = tenantStore.tenants?.entities[tenantId]?.isActive;

  const isVisibleSave = isChangeActive !== isActive;

  const buttonText = tenantId ? 'Сохранить' : 'Добавить';

  const prepareFormData = (formData: TenantBody): TenantBody => {
    const baseData = {
      companyId: formData.companyId,
      name: formData.name,
      isActive: formData.isActive,
    };

    if (isEditMode) {
      return {
        ...baseData,
        id: tenantId!,
        deactivationComment: formData.isActive ? null : formData.deactivationComment,
      };
    }

    return {
      ...baseData,
      accountList: formData.accountList.map((account) => ({
        ...account,
        createDate: dayjs().valueOf().toString(),
        isActive: true,
      })),
      isActive: true,
    };
  };

  const handleTenantAction = async (data: TenantBody) => {
    const res = isEditMode
      ? await tenantService.updateTenant(data)
      : await tenantService.createTenant(data);
    if (res) onSuccess?.(res);
  };
  const onValid: SubmitHandler<TenantBody> = async (formData) => {
    const preparedData = prepareFormData(formData);
    await handleTenantAction(preparedData);
  };
  const handleClick = () => {
    set([SearchParams.ShowDeleteTenantDialog]);
  };

  return (
    <Box
      component='form'
      flexDirection='column'
      height='100%'
      padding='15px'
      sx={{ height: 'calc(100vh - 272px)', overflow: 'auto' }}
      display='flex'
      onSubmit={handleSubmit(onValid)}
    >
      <Stack gap={2.5} flex={1}>
        <Stack gap={2.5} flex={1}>
          <FieldInput
            label='Наименование тенанта'
            disabled={!isEmpty(tenantId)}
            error={errors.name}
            name='name'
            register={register}
            localeName='name'
          />
          <FieldInput
            label='Идентификатор тенанта'
            disabled={!isEmpty(tenantId)}
            error={errors.companyId}
            name='companyId'
            register={register}
            localeName='companyId'
          />
          {isEmpty(tenantId) && (
            <FieldList
              name='accountList'
              register={register}
              control={control}
              isDivider={true}
              renderElement={(index) => (
                <Stack gap={2.5}>
                  <FieldInput
                    label='Имя администратора'
                    error={`${errors}[${index}].name`}
                    name={`accountList[${index}].name`}
                    register={register}
                    localeName='name'
                  />
                  <FieldInput
                    label='Логин администратора'
                    error={`${errors}[${index}].login`}
                    name={`accountList[${index}].login`}
                    register={register}
                    localeName='login'
                  />
                  <FieldInput
                    label='Пароль администратора'
                    error={`${errors}[${index}].password`}
                    name={`accountList[${index}].password`}
                    register={register}
                    localeName='password'
                  />
                </Stack>
              )}
            />
          )}
          {!isEmpty(tenantId) && (
            <>
              <FieldSwitch
                label='Активность'
                control={control}
                register={register}
                error={errors.isActive}
                name='isActive'
              />
              {isActive === false && (
                <FieldInput
                  textarea
                  register={register}
                  control={control}
                  label='Комментарий для пользователя'
                  error={errors.deactivationComment}
                  name='deactivationComment'
                />
              )}
            </>
          )}
        </Stack>
        <Stack direction='row' justifyContent='space-between'>
          <Box width={212}>
            {tenantId && isChangeActive === false && (
              <Button
                loading={tenantStore.isLoading}
                variant={ButtonVariants.Secondary}
                onClick={handleClick}
              >
                Удалить
              </Button>
            )}
          </Box>
          {(isEmpty(tenantId) || isVisibleSave) && (
            <Box width={212} justifyContent='flex-end'>
              <Button
                disabled={!isValid}
                loading={tenantStore.isLoading}
                type='submit'
                variant={ButtonVariants.Primary}
              >
                {buttonText}
              </Button>
            </Box>
          )}
        </Stack>
      </Stack>
    </Box>
  );
});

TenantForm.displayName = 'TenantForm';
