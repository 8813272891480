/* eslint-disable react/function-component-definition */
import React, { FC, PropsWithChildren } from 'react';

import { NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { DownOutlined, QuestionCircleOutlined, RightOutlined } from '@ant-design/icons';
import ListItemButton from '@mui/material/ListItemButton';
import MUIMenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Tooltip from '@/shared/ui/Tooltip';

import styles from '../SettingsDrawer.module.scss';


const Button: FC<PropsWithChildren<{
  isExpand: boolean;
  selected: boolean;
  link?: string;
  handler: () => void;
}>> = ({
  children,
  isExpand,
  selected,
  handler,
  link,
}) => {
  let linkProps = {};
  if (!selected && link) linkProps = {
    component: NavLink,
    to: link,
  }

  return (
    <ListItemButton
      className={cn({
        expand: isExpand,
        active: selected,
      })}
      onClick={handler}
      {...linkProps}
    >
      {children}
    </ListItemButton>
  );
};

const StartIcon = ({ icon: Icon = QuestionCircleOutlined }) => (
  <ListItemIcon className={styles.icon}>
    <Icon style={{ fontSize: 20 }} />
  </ListItemIcon>
);

const Text = ({ label }: { label: string }) => (
  <Typography
    className={styles.label}
    variant="inherit"
    noWrap
  >
    {label}
  </Typography>
);

const EndIcon = ({ isOpen }: { isOpen?: boolean }) => {
  if (typeof isOpen !== 'boolean') return null;
  const Icon = isOpen ? DownOutlined : RightOutlined;
  return (
    <ListItemIcon className={styles['end-icon']}>
      <Icon style={{ fontSize: 14 }} />
    </ListItemIcon>
  );
}

interface MenuItemLinkProps {
  expanded: boolean;
  isOpen?: boolean;
  handler: () => void;
  data: { [key: string]: any };
}

export function MenuItemLink ({
  expanded,
  isOpen,
  handler,
  data,
}: MenuItemLinkProps) {
  const {
    slug,
    label,
    link,
    icon,
    items,
  } = data;
  const location = useLocation();
  const activePath = location.pathname;
  const selected = [link, ...(items || []).map((itm: { link: string }) => itm.link)].includes(activePath);

  return (
    <Tooltip
      title={label}
      placement="right"
      isTooltipActive={!expanded}
    >
      <MUIMenuItem
        key={slug}
        className={styles['menu-item']}
      >
        <Button
          link={link}
          handler={handler}
          isExpand={expanded}
          selected={selected}
        >
          <StartIcon icon={icon} />
          <Text label={label} />
          <EndIcon isOpen={isOpen} />
        </Button>
      </MUIMenuItem>
    </Tooltip>
  );
}
