import { Node, Edge } from "reactflow";
import { flatten, groupBy, identity, isEmpty, last, partition } from "lodash";
import { normalize } from "@/shared/lib/normalize";


const getLinkedNodes = (edgesMap: { [key: string]: Edge[] }) => (id: string) => {
  let res: string[] = [];
  let ids = [id];
  while(ids.length) {
    res = res.concat(ids);
    ids = ids.flatMap((id) => edgesMap[id]?.map(({ target }) => target)).filter(identity);
  }
  return res;
};

const setupBuildExpander = (
  nodeMap: { [key: string]: Node },
  groupedEdges: { [key: string]: Edge[] },
  initialSize: number,
) => {
  const getNodeById = (id: string) => nodeMap[id];
  const getLinked = getLinkedNodes(groupedEdges);

  return (list: Edge[]) => {
    const node1 = getNodeById(list[0].target);
    const showBtn = list.length > initialSize
    const lastEdge = showBtn ? list[initialSize - 1] : last(list);
    const nodeLast = getNodeById(lastEdge.target);
    const { style, position, id } = node1;
    const calcPosition = ({ position: pos }) => ({
      x: position.x,
      y: pos.y + parseInt(style.height, 10) + 30,
    });
    const childIds = list.map(({ target }) => target);


    // expander node
    return {
      id: `expander-${id}`,
      position: calcPosition(nodeLast),
      type: 'expanderNode',
      isParent: true,
      draggable: false,
      data: {
        childIds,
        getLinked,
        calcPosition,
        showedCount: initialSize,
        showBtn,
      },
      style: {
        width: parseInt(style.width, 10),
        height: '3em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
      },
    };
  };
}

export const collapseNodes = (nodes, edges) => {
  const initialSize = 20;
  const groupedEdges = groupBy(edges, 'source');
  const [largeNet, commonNet] = partition(groupedEdges, (arr) => arr.length > 1);

  if (isEmpty(largeNet)) return { nodes, edges };

  const { entities: nodeMap } = normalize(nodes, 'id');
  const getLinked = getLinkedNodes(groupedEdges);
  const buildExpander = setupBuildExpander(nodeMap, groupedEdges, initialSize);
  const expanderNodes = largeNet.map(buildExpander);
  const restrictEdges = largeNet.map(([edge]) => edge);
  const nodeIdsToHide = largeNet.flatMap(
    (list) => list
      .slice(initialSize)
      .map(({ target }) => target)
      .flatMap(getLinked),
  );

  nodes.forEach((node) => {
    if (!nodeIdsToHide.includes(node.id)) return;
    node.hidden = true;
  });
  return {
    nodes: [...nodes, ...expanderNodes],
    edges: [...flatten(commonNet), ...restrictEdges],
  };
}
