import * as React from 'react';
import { FC, memo } from 'react';

import { get, isArray } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { Stack } from '@mui/system';
import { SelectOption } from '@mui/base';
import { FieldProps } from '@/shared/ui/Fields/Fields';
import { Autocomplete } from '@/shared/ui/Autocomplete/Autocomplete';
import { amendDraggable } from '@/shared/lib/extendDndkit';

import { Label } from '../../../Label/Label';

import styles from './FieldAutocomplete.module.scss';

export interface FieldSelectProps extends Omit<FieldProps, 'options' | 'error'> {
  options: SelectOption<string>[] | string[] | string;
  error?: any,
  multiple?: boolean;
  renderOption?: any;
  getOptionLabel?: any;
}

const getOptions = (src: string, path: string, cntx: Object) => {
  const list = get(cntx, src, []);
  if (!isArray(list)) return [];
  if (!path) return list;
  return list.map((el) => get(el, path, '<none>'));
}

/**
 * Мультиселект CronSelect для генератора форм
 */
export const FieldAutocomplete: FC<FieldSelectProps> = memo(
({
  name,
  label,
  options: opts,
  isRequired,
  description,
  error,
  control,
  multiple,
  defaultValue,
  props,
  onChange,
  renderOption,
  getOptionLabel,
}) => {
  const formApi = useFormContext();
  const source = get(props, 'optSrc');
  const path = get(props, 'optPath');
  let options = isArray(opts) ? opts : [];
  if (source) {
    const { getValues } = formApi;
    options = getOptions(source, path, getValues());
  }

  return (
    <Stack spacing={1}>
      <Stack position='relative' gap={2}>
        <Label name={name} required={isRequired} tooltip={description}>
          {label}
        </Label>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Autocomplete
              value={field.value || []}
              options={options}
              autoComplete
              freeSolo
              multiple={multiple}
              filterSelectedOptions
              {...amendDraggable(true)}
              {...props}
              onChange={(event, value) => {
                field.onChange(value);
                if (onChange) onChange();
              }}
              onInputChange={(event, value) => {
                if (multiple === true) return;
                field.onChange(value);
              }}
              renderOption={renderOption}
              getOptionLabel={getOptionLabel}
            />
          )}
        />
      </Stack>
      {error && <p className={styles.helpText}>{error}</p>}
    </Stack>
  );
});
