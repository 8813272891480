import { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Stack } from '@mui/system';
import { container } from 'tsyringe';
import { TenantSidebar } from '@/entities/Tenant/entities/TenantSidebar';
import { TenantContent } from '@/entities/Tenant/entities/TenantContent';
import { TenantService } from '@/entities/Tenant/api/TenantService';

export interface TenantPageProps {}

const tenantService = container.resolve(TenantService);
export const TenantPage: FC<TenantPageProps> = observer(() => {
  useEffect(() => {
    tenantService.getTenants().then();
  }, []);
  return (
    <Stack direction='row' gap={3}>
      <TenantSidebar />
      <TenantContent />
    </Stack>
  );
});
