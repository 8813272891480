import { makeAutoObservable, runInAction } from 'mobx';
import { singleton } from 'tsyringe';
import _ from 'lodash';
import { notify } from '@/shared/ui/Toast/notify';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { TenantStore } from '@/entities/Tenant/stores/TenantStore';
import { normalize } from '@/shared/lib/normalize';
import { TenantBody, TenantRequest } from '@/entities/Tenant/stores/types';

const API_ENDPOINTS = {
  COMPANIES: '/editor/company',
  ALL_COMPANIES: '/editor/company/all',
} as const;

const ERROR_MESSAGES = {
  GET_TENANTS: 'Не удалось получить тенанты',
  DELETE_TENANT: 'Не удалось удалить тенант',
  CREATE_TENANT: 'Не удалось создать тенант',
  UPDATE_TENANT: 'Не удалось изменить тенант',
  DEFAULT: 'Произошла ошибка',
} as const;

const NORMALIZATION_KEY = 'id';

@singleton()
export class TenantService {
  isLoading: boolean;

  constructor(private apiService: ApiService, private tenantStore: TenantStore) {
    this.isLoading = false;
    makeAutoObservable(this);
  }

  private get tenantsData() {
    return this.tenantStore.tenants;
  }

  async getTenants() {
    this.isLoading = true;

    try {
      const response = await this.apiService.instance.get<TenantRequest[]>(
        API_ENDPOINTS.ALL_COMPANIES
      );
      const normalized = normalize(response.data, NORMALIZATION_KEY);
      const currentTenants = this.tenantsData;

      runInAction(() => {
        if (!currentTenants) {
          this.tenantStore.tenants = normalized;
          return;
        }

        const mergedIds = _.uniq([...currentTenants.ids, ...normalized.ids]);
        const mergedEntities = { ...currentTenants.entities, ...normalized.entities };

        this.tenantStore.tenants.ids = mergedIds;
        this.tenantStore.tenants.entities = mergedEntities;
      });
    } catch (error) {
      notify.error(ERROR_MESSAGES.GET_TENANTS);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async deleteTenant(tenantId: string) {
    this.isLoading = true;

    try {
      await this.apiService.instance.delete(`${API_ENDPOINTS.COMPANIES}/${tenantId}`);
      const tenantsStore = this.tenantsData;

      runInAction(() => {
        if (!tenantsStore) return;

        const index = tenantsStore.ids.indexOf(tenantId);
        if (index > -1) {
          tenantsStore.ids.splice(index, 1);
        }
        delete tenantsStore.entities[tenantId];
      });
    } catch (error) {
      notify.error(ERROR_MESSAGES.DELETE_TENANT);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateTenant(data: TenantBody) {
    return this.addTenant(data, data.id);
  }

  async createTenant(data: TenantBody) {
    return this.addTenant(data);
  }

  async addTenant(body: TenantBody, tenantId?: string) {
    try {
      const method = tenantId ? 'put' : 'post';
      const response = await this.apiService.instance[method](API_ENDPOINTS.COMPANIES, body);
      const currentTenants = this.tenantsData;

      runInAction(() => {
        const tenantData = response.data;

        if (!currentTenants) {
          this.tenantStore.tenants = {
            ids: [tenantData.id],
            entities: { [tenantData.id]: tenantData },
          };
          return;
        }

        if (!tenantId) {
          currentTenants.ids.unshift(tenantData.id);
        }
        currentTenants.entities[tenantData.id] = tenantData;
      });

      return response.data;
    } catch (error) {
      const message = tenantId ? ERROR_MESSAGES.UPDATE_TENANT : ERROR_MESSAGES.CREATE_TENANT;
      notify.error(message);

      const errorMessage = error.response?.data?.message || ERROR_MESSAGES.DEFAULT;
      notify.error(errorMessage);

      throw error;
    }
  }
}
