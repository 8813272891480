export const defaultValue = {
  companyId: '',
  name: '',
  accountList: [
    {
      name: '',
      login: '',
      password: '',
      createDate: '',
    },
  ],
  deactivationComment: '',
};

export const API_PATHS = {
  GET_ALL: '/editor/company/all',
  COMPANY: '/editor/company',
} as const;

export const ERROR_MESSAGES = {
  GET_TENANTS_FAILED: 'Не удалось получить тенанты',
  DELETE_TENANT_FAILED: 'Не удалось удалить тенант',
  CREATE_TENANT_FAILED: 'Не удалось создать тенант',
  UPDATE_TENANT_FAILED: 'Не удалось изменить тенант',
} as const;

export const NOTIFICATION_ERRORS = {
  DEFAULT: 'Произошла ошибка',
} as const;

export const NORMALIZATION_KEY = 'id';
