import { FC, memo, useCallback } from 'react';

import { Box } from '@mui/system';
import { TooltipProps } from '@mui/material/Tooltip';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Tooltip from '@/shared/ui/Tooltip';
import { ACTIONS_SEARCH_PARAMS, SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { DocumentIcon } from '@/shared/ui/Icons/DocumentIcon/DocumentIcon';
import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';

export interface OpenBlockTestingProps {
  blockId: string;
  toolbarPlacement?: TooltipProps['placement'];
}

export const OpenBlockTesting: FC<OpenBlockTestingProps> = memo(
  ({ blockId, toolbarPlacement = 'left' }) => {
    const { has, set, remove } = useSearchParamsTemplate();
    const isOpen = has(SearchParams.ShowBlockTesting);

    const handleClick = useCallback(() => {
      remove(ACTIONS_SEARCH_PARAMS);
      if (isOpen) return;
      set([SearchParams.ShowBlockTesting, { [SearchParams.BlockId]: blockId }]);
    }, [blockId, set]);

    eventEmitter.on(`OpenBlockTesting${blockId}`, handleClick)

    return (
      <Tooltip title='Тесты' placement={toolbarPlacement} disableInteractive>
        <Box height='max-content'>
          <Button
            tabIndex={1}
            circle
            size={ButtonSizes.Small}
            variant={ButtonVariants.Outlined}
            onClick={handleClick}
          >
            <DocumentIcon />
          </Button>
        </Box>
      </Tooltip>
    );
  }
);

OpenBlockTesting.displayName = 'OpenBlockTesting';
