import { FC } from 'react';

import { Grid } from '@mui/material';
import { toJS } from "mobx";
import { container } from 'tsyringe';
import { get as ldGet, keyBy } from 'lodash';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { FieldSelect } from '@/shared/ui/Fields/components/FieldSelect/FieldSelect';
import { FieldAutocomplete } from '@/shared/ui/Fields/components/FieldAutocomplete/FieldAutocomplete';
import TypographySecToText from '@/shared/ui/Typography/TypographySecToText';
import { FieldInputNumber } from '@/shared/ui/Fields/components/FieldInputNumber/FieldInputNumber';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { RegistrableValues } from '@/shared/lib/types';
import {
  CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE,
  SearchParams,
  useSearchParamsTemplate,
} from '@/hooks/useTemplateSearchParams';
import { useResolve } from '@/hooks/useResolve';
import { SettingsService } from '@/entities/Settings/api/SettingsService';

import {
  LOG_SETTINGS_DATA_LEVEL_OPTIONS,
  LOG_SETTINGS_EXCLUDE_LOG_OPTIONS,
  LOG_SETTINGS_LEVEL_OPTIONS
} from './constants';
import { BlockService } from '../../services/BlockService';
import { LogSettings } from '../../types';
import { logSettingsValidationSchema } from './validationSchema';


const settingsService = container.resolve(SettingsService);

const toSec = (val: number) => Math.round(val / 1000);

export const LogSettingsFormContainer: FC = observer(() => {
  const { flowId } = useParams();
  const { remove, get } = useSearchParamsTemplate();

  const blockService = useResolve(BlockService, { [RegistrableValues.FlowId]: flowId });
  const blockId = get(SearchParams.BlockId);
  const block = blockService.blocks?.entities[blockId];

  const { logStorages } = settingsService;
  const mapLocations = keyBy(logStorages, 'id');
  const initialLogLocations = ldGet(block, 'logSettings.logLocations', []);

  const initialRetentionTime = toSec(ldGet(block, 'logSettings.retentionTime', 0));

  let values;
  if (block?.logSettings) {
    values = {
      ...toJS(block.logSettings),
      retentionTime: initialRetentionTime,
      logLocations: initialLogLocations,
    };
  }

  const formApi = useForm<LogSettings>({
    resolver: yupResolver(logSettingsValidationSchema),
    values,
    mode: 'onChange',
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = formApi;

  const logLocations = useWatch({
    control,
    name: 'logLocations',
    defaultValue: initialLogLocations,
  });

  const getLabelLocation = (id: string) => mapLocations[id]?.label;


  const retentionTime = useWatch({
    control,
    name: 'retentionTime',
    defaultValue: initialRetentionTime,
  });

  const level = useWatch({
    control,
    name: 'level',
    defaultValue: block?.logSettings.level,
  });

  const dataLevel = useWatch({
    control,
    name: 'dataLevel',
    defaultValue: block?.logSettings.dataLevel,
  });

  const excludeLog = useWatch({
    control,
    name: 'excludeLog',
    defaultValue: block?.logSettings.excludeLog,
  });

  const onSubmit: SubmitHandler<LogSettings> = ({ retentionTime, ...formData }) => {
    const data = {
      ...formData,
      retentionTime: retentionTime * 1000,
    };
    if (block) {
      blockService.updateBlock({ ...block, logSettings: data });
    }

    remove([
      SearchParams.ShowMessageDeliverySettings,
      SearchParams.ShowLogSettings,
      ...CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE,
    ]);
  };

  const clearError = async () => {
    await blockService.clearError(blockId);
  };

  const clearLog = async () => {
    await blockService.clearLog(blockId);
  };

  return (
    <FormProvider {...formApi}>
      <form>
        <div className='col-span-12 px-5'>
          <Grid container gap={2.5}>
            <Grid xs={12} lg={12}>
              <FieldAutocomplete
                control={control}
                multiple
                label='Место хранения логов'
                description="Если не указано, используются хранилища по умолчанию"
                options={logStorages.map(({ id }) => id)}
                error={ldGet(errors, 'storages')}
                name='logLocations'
                defaultValue={logLocations}
                getOptionLabel={getLabelLocation}
              />
            </Grid>
            <Grid xs={12} lg={12}>
              <FieldSelect
                control={control}
                label='Уровень логирования трассировки данных'
                options={LOG_SETTINGS_LEVEL_OPTIONS}
                error={ldGet(errors, 'level')}
                name='level'
                defaultValue={level}
              />
            </Grid>
            <Grid xs={12} lg={12}>
              <FieldSelect
                control={control}
                label='Уровень логирования данных'
                options={LOG_SETTINGS_DATA_LEVEL_OPTIONS}
                error={ldGet(errors, 'dataLevel')}
                name='dataLevel'
                defaultValue={dataLevel}
              />
            </Grid>
            <Grid xs={12} lg={12}>
              <FieldAutocomplete
                control={control}
                multiple
                label='Исключить из мониторинга'
                options={LOG_SETTINGS_EXCLUDE_LOG_OPTIONS}
                error={ldGet(errors, 'excludeLog')}
                name='excludeLog'
                defaultValue={excludeLog}
              />
            </Grid>
            <>
              <Grid xs={12} lg={12}>
                <FieldInputNumber
                  control={control}
                  label="Время хранения во внутреннем хранилище (в секундах)"
                  error={ldGet(errors, 'retentionTime')}
                  name="retentionTime"
                  step={86400}
                />
              </Grid>
              <div className='col-span-12'>
                <span>Время хранения:</span> <TypographySecToText time={retentionTime} />
              </div>
            </>
            <Grid container gap={2.5}>
              <Grid xs={5} lg={5}>
                <Button variant={ButtonVariants.Secondary} onClick={clearError}>
                  Очистить ошибки
                </Button>
              </Grid>
              <Grid xs={5} lg={5}>
                <Button variant={ButtonVariants.Secondary} onClick={clearLog}>
                  Очистить логи
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className='grid grid-cols-12 col-span-12 self-end pb-10 px-5'>
          <div className='col-span-3 col-start-10'>
            <Button onClick={handleSubmit(onSubmit)}>Сохранить</Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
});
