import { FC, memo, useCallback, useState } from 'react';

import { Stack } from '@mui/system';
import { AnimatePresence, motion, MotionProps } from 'framer-motion';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { SettingsIcon } from '@/shared/ui/Icons/SettingsIcon/SettingsIcon';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import { bindTrigger } from 'material-ui-popup-state';
import { Tag } from '@/shared/ui/Tag/Tag';
import { TagSizes } from '@/shared/ui/Tag/types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  ACTIONS_SEARCH_PARAMS,
  getConnectorFormSearchParamsTemplate,
  SearchParams,
  useSearchParamsTemplate,
} from '@/hooks/useTemplateSearchParams';
import { ConnectorContextMenu } from '@/widgets/ConnectorContext';
import { ConsumerInformationBadge } from '@/entities/ConsumerInformation';
import Typography from '@mui/material/Typography';

import Button from '../../../../shared/ui/Button';
import { ConnectSizes, ConnectTypes } from '../ConnectList/types';

import styles from './ConnectEditor.module.scss';

export interface ConnectorEditorProps {
  size?: ConnectSizes;
  title: string;
  type: ConnectTypes;
  connector: string;
  connectorNameLocale: string | undefined;
  connectorId: string;
  blockId: string;
  manyToOne?: boolean;
  isHighlight?: boolean;
  isHighlightManyToOne?: string;
  draggable?: boolean;
  active?: boolean;
}

const animation: MotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { ease: 'easeInOut' },
  exit: { opacity: 0 },
};

export const ConnectEditor: FC<ConnectorEditorProps> = memo(
  ({
    size = ConnectSizes.Small,
    title,
    type,
    connector,
    connectorNameLocale,
    manyToOne,
    connectorId,
    isHighlight,
    isHighlightManyToOne,
    blockId,
    draggable,
    active,
  }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
      id: connectorId,
    });
    const { set, remove } = useSearchParamsTemplate();

    const [isShowAction, setIsShowAction] = useState(false);

    const style = {
      transform: CSS.Transform.toString(transform),
      transition: transition || undefined,
    };

    const handleMouseEnter = (): void => {
      setIsShowAction(true);
    };

    const handleMouseLeave = (): void => {
      setIsShowAction(false);
    };

    const handleEdit = useCallback(() => {
      remove([
        ...ACTIONS_SEARCH_PARAMS.filter((param) => param !== SearchParams.ShowBlockForm),
        SearchParams.ShowMessageDeliverySettings,
        SearchParams.ShowLogSettings,
      ]);
      set(
        getConnectorFormSearchParamsTemplate({
          connectorName: connector,
          connectorId,
          blockId,
          connectorType: type,
        })
      );
    }, [blockId, connector, connectorId, remove, set, type]);

    const handleDelete = useCallback(() => {
      set([
        SearchParams.ShowConnectDeleteModal,
        {
          [SearchParams.ConnectorName]: connector,
          [SearchParams.ConnectorType]: type,
          [SearchParams.ConnectorId]: connectorId,
          [SearchParams.BlockId]: blockId,
        },
      ]);
    }, [blockId, connector, connectorId, set, type]);

    switch (size) {
      case ConnectSizes.Large: {
        const content = (): JSX.Element => {
          if (isShowAction) {
            return (
              <motion.div {...animation}>
                <Stack direction='row' spacing={1}>
                  <Button circle size={ButtonSizes.Small} variant={ButtonVariants.Outlined}>
                    <SettingsIcon onClick={handleEdit} />
                  </Button>
                  <Button
                    circle
                    size={ButtonSizes.Small}
                    variant={ButtonVariants.Outlined}
                    onClick={handleDelete}
                  >
                    <TrashIcon />
                  </Button>
                </Stack>
              </motion.div>
            );
          }

          return <span style={{ userSelect: 'none', pointerEvents: 'none' }}>{connectorNameLocale}</span>;
        };

        return (
          <ConsumerInformationBadge id={connectorId} color='secondary'>
            <Tag
              draggableProps={{ ...attributes, ...listeners }}
              style={style}
              ref={setNodeRef}
              key={connectorId}
              value={connectorId}
              onMouseOver={handleMouseEnter}
              onMouseOut={handleMouseLeave}
              size={TagSizes.Large}
              inactive={!active}
              draggable={draggable}
            >
              <motion.div>
                <AnimatePresence>{content()}</AnimatePresence>
              </motion.div>
            </Tag>
          </ConsumerInformationBadge>
        );
      }

      case ConnectSizes.Small: {
        return (
          <ConnectorContextMenu
            isActiveConnector={Boolean(active)}
            connectorId={connectorId}
            blockId={blockId}
            connectorType={type}
            onEdit={handleEdit}
            onDelete={handleDelete}
          >
            {(popupState) => (
              <ConsumerInformationBadge id={connectorId} color='secondary'>
                <Tag
                  role='menuitem'
                  className='nodrag'
                  size={TagSizes.Small}
                  isHighlight={isHighlight}
                  isHighlightManyToOne={manyToOne ? isHighlightManyToOne : 'true'}
                  inactive={!active}
                  onClick={handleEdit}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    bindTrigger(popupState).onClick(e);
                  }}
                >
                  <Stack direction='row' gap={1} className={styles.root}>
                    <Typography>{connectorNameLocale}</Typography>
                    {title && (
                      <Typography color='white' component='span' noWrap maxWidth={500}>
                        {title}
                      </Typography>
                    )}
                  </Stack>
                </Tag>
              </ConsumerInformationBadge>
            )}
          </ConnectorContextMenu>
        );
      }
    }
  }
);
