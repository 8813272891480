import React, { FC, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import Tooltip from '@/shared/ui/Tooltip';

import { OpenApiService } from '@/entities/OpenApi/features/api/OpenApiService';

const openApiService = container.resolve(OpenApiService);

export interface DeleteOpenAppButtonProps {
  openAppId: string;
}

export const DeleteOpenAppButton: FC<DeleteOpenAppButtonProps> = observer(({ openAppId }) => {
  const handleClick = useCallback(async () => {
    openApiService.openDeleteForm = true;
    openApiService.selectedId = openAppId;
  }, [openAppId]);

  return (
    <Tooltip title='Удалить OpenAPI-сервис' placement='top'>
      <span>
        <Button
          circle
          variant={ButtonVariants.Outlined}
          size={ButtonSizes.Small}
          onClick={handleClick}
        >
          <TrashIcon />
        </Button>
      </span>
    </Tooltip>
  );
});

DeleteOpenAppButton.displayName = 'DeleteOpenAppButton';
