import { useEffect, useState } from "react";


import { observer } from "mobx-react-lite";
import { container } from "tsyringe";
import { Dropdown, MenuProps } from 'antd';
import { UpOutlined, DownOutlined } from "@ant-design/icons";

import Tooltip from "@/shared/ui/Tooltip";
import { useSearchParamsTemplate } from "@/hooks/useTemplateSearchParams";
import { PeriodService } from "@/entities/Dashboard/widgets/DashboardPlatform/services/PeriodService";

import styles from "./HistoryManager.module.scss";


const periodService = container.resolve(PeriodService);

export const Interval = observer(() => {
  const { get, set } = useSearchParamsTemplate();
  const qsInterval = get('interval');
  const { intervalOptions, interval } = periodService;

  const [open, setOpen] = useState(false);

  const handleSelect: MenuProps['onSelect'] = ({ key }) => {
    const opt = periodService.getIntervalOption(key);
    periodService.interval = opt.key;
  };

  const StateIcon = open ? UpOutlined : DownOutlined;

  useEffect(() => {
    const opt = periodService.getIntervalOption(qsInterval);
    periodService.interval = opt.key;
  }, []);

  useEffect(() => {
    set({ interval });
  }, [interval]);

  const { label = '' } = periodService.getIntervalOption(interval);

  const icon = (
    <>
      {label}
      &nbsp;
      <StateIcon />
    </>
  );

  return (
    <Tooltip title='Шаг запроса данных' placement="top" disableInteractive>
      <span>
        <Dropdown.Button
          className={styles['refresh-btn']}
          icon={icon}
          buttonsRender={([, btn]) => [null, btn]}
          menu={{
            items: intervalOptions,
            selectable: true,
            selectedKeys: [interval],
            onSelect: handleSelect,
          }}
          onOpenChange={setOpen}
        />
      </span>
    </Tooltip>
  );
});
