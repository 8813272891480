import React, { ChangeEventHandler } from "react";

import { observer } from "mobx-react-lite";
import { container } from "tsyringe";

import { MenuItem, Stack } from "@mui/material";

import { Autocomplete } from '@/shared/ui/Autocomplete/Autocomplete';
import { Input } from "@/shared/ui";
import { DashboardService } from "@/entities/Dashboard/services/DashboardService";
import { BlockMiniService } from "@/entities/Block/services/BlockMiniService";
import { Switch } from "@/shared/ui/Switch/Switch";

import { defaultPanelData } from "./CounterPanel";
import { DataCounterPanel } from "@/entities/Dashboard/types";

const dashboardService = container.resolve(DashboardService);
const blockMiniService = container.resolve(BlockMiniService);

export interface CounterPanelSettingsProps {
  dashboardPanelId: string;
}

export const Settings = observer((props: CounterPanelSettingsProps) => {
  const { dashboardPanelId } = props;

    const { miniBlocks } = blockMiniService;

    const dataPanel = dashboardService.getPanelData(dashboardPanelId);

    const values = dataPanel || defaultPanelData;
    // while backend don't clean idList on remove block/s
    const blockIdList = values.blockIdList.filter((id: string) => miniBlocks.entities[id]);

    const onChangeGraphType = (newType: string) => {
      const data: DataCounterPanel = {
        graphType: newType,
        blockIdList,
      };
      if (!newType) data.blockIdList = [];
      dashboardService.changeDashboardPanel(dashboardPanelId, { data })
    }

    const onChangeFullSelect: ChangeEventHandler<HTMLInputElement> = ({ target: { checked: selectAll } }) => {
      const data: DataCounterPanel = { selectAll };
      dashboardService.changeDashboardPanel(dashboardPanelId, { data });
    }

    const onChangeBlockIdList = (newList: string[]) => {
      const data = { blockIdList: newList };
      dashboardService.changeDashboardPanel(dashboardPanelId, { data })
    }

    const getOptionLabel = (id: string) => {
      const { name, flowName } = miniBlocks.entities[id];
      return `${flowName} - ${name}`;
    }

    const renderSelectGraphType = () => (
      <Input
        select
        label="Тип графика"
        value={values.graphType}
        SelectProps={{
          placeholder: "выберите тип графика",
          value: values.graphType,
          onChange: (event) => {
            onChangeGraphType(event.target.value as string);
          },
        }}
      >
        <MenuItem key="line" value="line">
          Линейный график
        </MenuItem>
        {/* <MenuItem key={'bar'} value={'bar'}> */}
        {/*  Столбчатый график */}
        {/* </MenuItem> */}
        {/* <MenuItem key={'area'} value={'area'}> */}
        {/*  Area график */}
        {/* </MenuItem> */}
        {/* <MenuItem key={'pie'} value={'pie'}> */}
        {/*  Pie график */}
        {/* </MenuItem> */}
      </Input>
    )

    const renderSelectAllBlocks = () => {
      if (!values.graphType) return null;

      return (
        <Stack
          width='100%'
          direction='row'
          gap="1.5em"
          pl=".5em"
        >
          <Switch
            id="select-all"
            checked={values.selectAll}
            onChange={onChangeFullSelect}
          />
          <p>
            Данные всех блоков
          </p>
        </Stack>
      );
    }

    const renderSelectBlockIdList = () => {
      if (values.selectAll || !values.graphType || !miniBlocks) return null;

      return (
        <Autocomplete
          value={blockIdList || []}
          options={miniBlocks.ids}
          autoComplete
          freeSolo
          multiple
          filterSelectedOptions
          disabled={values.selectAll}
          onChange={(event, value) => {
            onChangeBlockIdList(value);
          }}
          renderOption={(props: any, id) => (
            <li {...props} key={id} value={id}>
              {getOptionLabel(id)}
            </li>
          )}
          getOptionLabel={getOptionLabel}
        />
      )
    }

    return (
      <>
        {renderSelectGraphType()}
        {renderSelectAllBlocks()}
        {renderSelectBlockIdList()}
      </>
    )
  },
)
