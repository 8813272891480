import { DBSchema, IDBPDatabase, openDB } from 'idb';
import { injectable } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import { notify } from '@/shared/ui/Toast/notify';
import { FilterMesForm } from '@/shared/ui/FilterMessageTracing/type';
import { daysBetween } from '@/service/utils/daysBetween';
import { DisplayMessageStore } from '@/entities/MessageTracing/model/DisplayMessageStore';

export interface FilterDataDB extends DBSchema {
  filterData: {
    key: string;
    value: {
      value: any;
      key: string;
      date: Date;
    };
  };
}

@injectable()
export class IndexedDBFilterService {
  static dbFilterData: IDBPDatabase<FilterDataDB>;

  openNotification: boolean;

  constructor(private displayMessageStore: DisplayMessageStore) {
    this.openNotification = false;
    makeAutoObservable(this);
  }

  static async initDBFilterData() {
    if (!IndexedDBFilterService.dbFilterData) {
      IndexedDBFilterService.dbFilterData = await openDB<FilterDataDB>('filterData', 1, {
        upgrade(dbFilterData) {
          if (!dbFilterData.objectStoreNames.contains('filterData')) {
            dbFilterData.createObjectStore('filterData');
          }
        },
      });
    }
  }

  async getFilterData(key: string): Promise<FilterMesForm | boolean> {
    await IndexedDBFilterService.initDBFilterData();
    try {
      const response = await IndexedDBFilterService.dbFilterData.get('filterData', key);
      const today = new Date();
      const date = new Date(response?.date);
      const limitDays = 1;
      const diffDays = daysBetween(today, date);
      if (diffDays >= limitDays) {
        await this.deleteFilterData(key);
      }
      if (diffDays <= limitDays) {
        this.displayMessageStore.filterValues = response?.value;
        this.displayMessageStore.filterId = response?.key;
        this.displayMessageStore.filterList = response.value.body;
        return true;
      }
    } catch (err) {
      notify.error('Не удалось получить данные');
    }
    return false;
  }

  saveFilterData = async (key: string, value: any, date: Date) => {
    await IndexedDBFilterService.initDBFilterData();
    return IndexedDBFilterService.dbFilterData.put('filterData', { value, key, date }, key);
  };

  deleteFilterData = async (key: string) => {
    await IndexedDBFilterService.initDBFilterData();
    return IndexedDBFilterService.dbFilterData.delete('filterData', key);
  };
}
