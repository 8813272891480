import React, { FC, useEffect, useRef } from 'react';

import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import { Tree, TreeProps } from 'antd';
import {
  FolderOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';
import { container } from 'tsyringe';
import { get } from 'lodash';
import { toJS } from 'mobx';
import Skeleton from '@mui/material/Skeleton';
import { Stack } from '@mui/system';
import type { GFlow } from '@/entities/Flow/types';
import { FlowService } from '@/entities/Flow/services/FlowService';
import { GFlowStore } from '@/entities/Flow/stores/GFlowStore';
import { GFlowService } from '@/entities/Flow/services/GFlowService';
import TreeNode from '@/entities/Flow/entities/TreeNode/TreeNode';
import { useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
// @ts-ignore
import { ReactComponent as FlowTreeIcon } from '@/assets/icons/flow_tree.svg';
import useWindowSize from '@/hooks/useWindowSize';

import useExpand from './useExpand';

import styles from './FlowTree.module.scss';

const flowService = container.resolve(FlowService);
const groupStore = container.resolve(GFlowStore);
const gflowService = container.resolve(GFlowService);

const topOffset = 232; // from top of window to tree

export const FlowTree: FC = observer(() => {
  const treeRef = useRef(null);
  const { flowId } = useParams();
  const navigate = useNavigate();
  const { get: getQS, set: setQS } = useSearchParamsTemplate();
  const nodeId = getQS('itemId');
  const blockId = getQS('blockId');
  const selectedKey = [nodeId];
  const { loadedKeys } = groupStore;
  const { selectedNodeId } = gflowService;
  const { selectedFlowId } = flowService;

  useEffect(() => {
    gflowService.getInitialGFlows(nodeId, flowId, [])
  }, []);

  useEffect(() => {
    if (gflowService.isLoadingGFlows || !treeRef.current) return;
    if (flowId && !selectedNodeId) {
      navigate('/flow?mode=tree');
    }
    if (!selectedNodeId) return;
    const expanded = get(treeRef.current, 'state.expanded', []);
    const path = gflowService.getPathToNode(selectedNodeId);
    treeRef.current?.setExpandedKeys([...expanded, ...path]);
    treeRef.current?.scrollTo({ key: nodeId, align: 'top', offset: 300 });
  }, [gflowService.isLoadingGFlows]);

  useEffect(() => {
    if (!treeRef.current) return;
    if (!selectedNodeId || selectedNodeId === nodeId) return;
    const sp = new URLSearchParams({ itemId: selectedNodeId, mode: 'tree' }).toString();
    let path = `?${sp}`;
    if (selectedFlowId && selectedFlowId !== flowId) {
      path = `${selectedFlowId}${path}`;
    }
    navigate(`/flow/${path}`);
  }, [selectedNodeId]);

  useEffect(() => {
    if (!flowId || nodeId) return;
    const id = gflowService.flowsHash[flowId] || null;
    setQS({ itemId: id, mode: 'tree' });
    gflowService.selectedNodeId = id;
    setTimeout(() => {
      const expanded = get(treeRef.current, 'state.expanded', []);
      const path = gflowService.getPathToNode(selectedNodeId);
      treeRef.current?.setExpandedKeys([...expanded, ...path]);
      treeRef.current?.scrollTo({ key: id, align: 'top', offset: 300 })
    }, 200)
  }, [flowId, blockId]);

  const [, height] = useWindowSize();

  if (gflowService.isLoadingGFlows) {
    const list = new Array(15).fill('');
    const holder = (
      <Stack margin="1em 0">
        <Skeleton animation="wave" width="95%" />
        <Skeleton animation="wave" width="85%" />
      </Stack>
    );
    return list.map(() => holder);
  }

  const onExpand = useExpand({ treeRef });
  const onSelect: TreeProps['onSelect'] = (selectedKeys) => {
    const nodeId = selectedKeys[0] as string || null;
    gflowService.selectedNodeId = nodeId;
    if (!nodeId) {
      navigate('/flow?mode=tree');
    }
  }

  const onDragStart: TreeProps['onDragStart'] = ({ node }) => {
    onSelect([node.key], null);
  };

  const onRightClick: TreeProps['onRightClick'] = ({ node }) => {
    onSelect([node.key], null);
  }

  const switcherIcon: TreeProps['switcherIcon'] = ({ expanded = false }) => {
    if (expanded) return <FolderOpenOutlined style={{ fontSize: '1.2em' }} />;
    return <FolderOutlined style={{ fontSize: '1.2em' }} rotate={360} />;
  }

  const titleRender: TreeProps['titleRender'] = ({ id }) => <TreeNode id={id} selected={selectedKey?.indexOf(id) >= 0} />;
  const treeData = gflowService.gflows ? toJS(gflowService.gflows.children) : [];

  const onDrop: TreeProps<GFlow>['onDrop'] = (info) => {
    const dropKey = info.node.key as string;
    const dragKey = info.dragNode.key as string;
    const dropPos = info.node.pos.split('-');
    // the drop position relative to the drop node, inside 0, top -1, bottom 1
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const { node: { type } } = info;

    let mode = dropPosition === -1 ? 'top' : 'bottom';
    if (!info.dropToGap && type === 'group') mode = 'parent';
    gflowService.reorderGFlow(dragKey, dropKey, mode).then();
  };

  const editCfg = { onDrop, draggable: { icon: false }, onDragStart };

  return (
    <Tree
      className={styles.treeFlows}
      ref={treeRef}
      height={height - topOffset}
      selectedKeys={selectedKey}
      defaultExpandedKeys={selectedKey}
      onSelect={onSelect}
      onRightClick={onRightClick}
      onExpand={onExpand}
      expandAction="click"
      loadedKeys={loadedKeys}
      fieldNames={{
        key: 'id',
      }}
      titleRender={titleRender}
      switcherIcon={switcherIcon}
      blockNode
      showLine={{ showLeafIcon: <FlowTreeIcon /> }}
      treeData={treeData}
      {...editCfg}
    />
  );
});
