import { FC, useCallback, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { SubmitHandler, useForm } from 'react-hook-form';
import { container } from 'tsyringe';
import Stack from '@mui/material/Stack';
import _, { isEmpty } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { FieldSelect } from '@/shared/ui/Fields/components/FieldSelect/FieldSelect';
import { WSDLService } from '@/entities/WSDLService/features/api/WSDLService';
import { WSDLResponse } from '@/entities/WSDLService/types';
import { Drawer } from '@/shared/ui/Drawer/Drawer';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { FieldSwitch } from '@/shared/ui/Fields/components/FieldSwitch/FieldSwitch';
import { Resizable } from '@/shared/ui';
import { authenticationOption } from '@/entities/WSDLService/utils/WSDLConst';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { WSDLAppStore } from '@/entities/WSDLService/stores/WSDLAppStore';
import { wsdlFormValidation} from '@/shared/lib';

import styles from './WSDLDialogForm.module.scss';

export interface WSDLDialogFormProps {}

const wsdlService = container.resolve(WSDLService);
const wsdlAppStore = container.resolve(WSDLAppStore);

export const WSDLDialogForm: FC<WSDLDialogFormProps> = observer(() => {
  const { remove } = useSearchParamsTemplate();
  const formValues = wsdlService.selectedId
    ? _.omitBy(wsdlService.currentWSDLApp, _.isNil)
    : {};
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm<WSDLResponse>({
    resolver: yupResolver(wsdlFormValidation),
    values: formValues,
    mode: 'onChange',
  });
  useEffect(() => {
    if (isEmpty(wsdlService.selectedId)) {
      reset({ name: '', description: '', url: '', server: '' });
    }
  }, [wsdlService.selectedId]);

  const title = wsdlService.selectedId
    ? 'Изменение WSDL-сервиса'
    : 'Добавление WSDL-сервиса';

  const buttonSubmitText = wsdlService.selectedId ? 'Изменить' : 'Добавить';

  const authenticationValue = watch('authentication');

  const handleClose = useCallback(() => {
    wsdlService.open = false;
    wsdlService.selectedId = null;
    reset();
    remove([SearchParams.WSDLId]);
  }, [reset]);

  const handleValidSubmit: SubmitHandler<WSDLResponse> = useCallback(
    async (wsdlApp) => {
      if (wsdlService.selectedId) {
        await wsdlService.editWSDLApp(wsdlApp).then(() => {
          if (!wsdlAppStore.isErrorSubmit) handleClose();
        });
      }
      else {
        await wsdlService.addWSDLApp(wsdlApp).then(() => {
          if (!wsdlAppStore.isErrorSubmit) handleClose();
        });
      }
    },
    [wsdlAppStore, handleClose]
  );

  if (!wsdlService.open) return null;

  return (
    <Resizable maxWidth={window.innerWidth - 260} initialWidth={434} minWidth={434}>
      <Drawer
        id='handlerTimer'
        className={styles.drawer}
        title={title}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit(handleValidSubmit)} className='p-5'>
          <Stack paddingBottom='100px' gap={2} padding={2}>
            <FieldInput
              error={errors.name}
              name='name'
              label='Уникальное имя сервиса'
              register={register}
              control={control}
            />
            <FieldInput
              error={errors.label}
              name='label'
              label='Название сервиса'
              register={register}
              control={control}
            />
            <FieldInput
              textarea
              error={errors.description}
              name='description'
              label='Описание'
              register={register}
              control={control}
              minRows={1}
            />
            <FieldInput
              textarea
              error={errors.url}
              name='url'
              label='URL до файла-конфигурации'
              register={register}
              control={control}
              minRows={1}
            />
            <FieldSelect
              options={authenticationOption}
              draggable=''
              error={errors.authentication}
              defaultValue={authenticationOption[0].value}
              name='authentication'
              label='Способ аутентификации'
              register={register}
              control={control}
            />

            {authenticationValue === 'Basic' && (
              <>
                <FieldInput
                  error={errors.username}
                  name='username'
                  label='Логин'
                  register={register}
                  control={control}
                />
                <FieldInput error='' name='password' label='Пароль' register={register} />
              </>
            )}
            {authenticationValue === 'Bearer' && (
              <FieldInput
                error={errors.bearerToken}
                name='bearerToken'
                label='Bearer токен'
                register={register}
              />
            )}
            <FieldSwitch
              defaultValue='false'
              label='Проверять обновление спецификации'
              name='autoCheck'
              control={control}
              error={errors.autoCheck}
            />
          </Stack>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
              padding: 5,
            }}
          >
            <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
              Отмена
            </Button>
            <Button
              type='submit'
              variant={ButtonVariants.Popup}
              loading={wsdlAppStore.isLoadingUpdateWSDLApp}
            >
              {buttonSubmitText}
            </Button>
          </div>
        </form>
      </Drawer>
    </Resizable>
  );
});

WSDLDialogForm.displayName = 'WSDLDialogForm';
