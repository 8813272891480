import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { normalize } from '@/shared/lib/normalize';
import { OpenApiAppStore } from '@/entities/OpenApi/stores/OpenApiAppStore';
import { OpenApiList, OpenApiResponse } from '@/entities/OpenApi/types';
import { notify } from '@/shared/ui/Toast/notify';

@singleton()
export class OpenApiService {
  open: boolean;

  openDeleteForm: boolean;

  selectedId: string | null;

  constructor(private apiService: ApiService, private openApiAppStore: OpenApiAppStore) {
    this.open = false;
    this.selectedId = null;
    this.openDeleteForm = false;

    makeAutoObservable(this);
  }

  get currentOpenApiApp() {
    if (this.selectedId) {
      return this.openApiAppStore.openApiApps?.entities[this.selectedId];
    }

    return null;
  }

  async getOpenApiApps() {
    this.openApiAppStore.isLoadingOpenApi = true;
    try {
      const response = await this.apiService.instance.get<OpenApiList[]>(
        '/editor/open-api-services'
      );
      this.openApiAppStore.openApiApps = normalize(response.data, 'id');
    } catch (error) {
      notify.error('Не удалось получить данные');
    } finally {
      this.openApiAppStore.isLoadingOpenApi = false;
    }
  }

  async addOpenApiApp(openApiApp: OpenApiResponse) {
    this.openApiAppStore.isLoadingUpdateOpenApi = true;
    try {
      await this.apiService.instance.post<OpenApiResponse>(`/editor/open-api-services`, openApiApp);
      this.openApiAppStore.isErrorSubmit = false;
      notify.success('Внешний сервис добавлен');
      await this.getOpenApiApps();
    } catch (error) {
      this.openApiAppStore.isErrorSubmit = true;
      notify.error(error?.response.data.message);
    } finally {
      this.openApiAppStore.isLoadingUpdateOpenApi = false;
    }
  }

  async editOpenApiApp(openApiApp: OpenApiResponse) {
    this.openApiAppStore.isLoadingUpdateOpenApi = true;
    try {
      await this.apiService.instance.put<OpenApiResponse>(
        `/editor/open-api-services/${this.selectedId}`,
        openApiApp
      );
      this.openApiAppStore.isErrorSubmit = false;
      notify.success('Внешний сервис изменен');
      await this.getOpenApiApps();
      this.openApiAppStore.isErrorSubmit = false;
    } catch (error) {
      this.openApiAppStore.isErrorSubmit = true;
      notify.error(error?.response.data.message);
    } finally {
      this.openApiAppStore.isLoadingUpdateOpenApi = false;
    }
  }

  async deleteOpenApiApp(deactivation: boolean) {
    this.openApiAppStore.isLoadingDeleteOpenApi = true;
    try {
      if (!this.selectedId) return;

      const params = new URLSearchParams();
      console.log('deleteOpenApiApp: ', deactivation);
      if (deactivation) params.set('deactivation', 'true');

      await this.apiService.instance.delete(`/editor/open-api-services/${this.selectedId}?${params.toString()}`);
      await this.getOpenApiApps();

    } catch (error) {
      notify.error('Не удалось удалить сервис');
      throw error;
    } finally {
      this.openApiAppStore.isLoadingDeleteOpenApi = true;
    }
  }
}
