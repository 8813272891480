import { FC, useCallback } from 'react';

import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { cutText } from '@/shared/lib/cutText';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { GFlowService } from '@/entities/Flow/services/GFlowService';
import Button from '@/shared/ui/Button';
import { FLOW_ACTIONS_SEARCH_PARAMS, SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';

const gflowService = container.resolve(GFlowService);

export const DeleteNodeModal: FC = observer(() => {
  const navigate = useNavigate();
  const { get, has, remove } = useSearchParamsTemplate();

  const nodeId = get('itemId');
  const isOpenDeleteModal = has(SearchParams.ShowTFlowDeleteModal);

  const handleDialogClose = useCallback(() => {
    remove(FLOW_ACTIONS_SEARCH_PARAMS);
  }, [remove]);

  const handleDialogOk = useCallback(() => {
    if (nodeId) {
      gflowService.deleteGFlow(nodeId).then(() => {
        navigate('/flow?mode=tree');
      });
    }
  }, [nodeId, navigate]);

  if (!isOpenDeleteModal) return null;

  const node = gflowService.gflowsMap[nodeId];
  if (!node) return null;

  const typeMsg = node.type === 'group' ? 'группу' : 'поток';
  let infoMsg: JSX.Element;
  if (node.children?.length) {
    infoMsg = (
      <>
        <br />
        <sup>*</sup>
        <em style={{ fontSize: '.8em' }}>содержимое будет перемещено в родительскую группу</em>
      </>
    );
  }

  return (
    <Dialog fullWidth open onClose={handleDialogClose}>
      <DialogTitle onClose={handleDialogClose}>Удалить</DialogTitle>
      <DialogContent>
        <Stack spacing={5}>
          <div>
            <p>
              <span>Вы точно хотите удалить {typeMsg}:&nbsp;</span>
              <b title={node.data?.name}>
                {cutText(node.data?.name || '', 20, true)}
              </b>?
              {infoMsg}
            </p>
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant={ButtonVariants.Secondary} onClick={handleDialogClose}>
          Отмена
        </Button>
        <Button
          loading={gflowService.isLoadingDeleteNode}
          variant={ButtonVariants.Popup}
          onClick={handleDialogOk}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
});

DeleteNodeModal.displayName = 'DeleteNodeModal';
