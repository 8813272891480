import { FC, memo } from 'react';

import Grid from '@mui/system/Unstable_Grid';
import { FieldSelect } from '@/shared/ui/Fields/components/FieldSelect/FieldSelect';
import {
  arrayDateInputType,
  arrayDateOutputType,
  arrayEmptyRule,
  arrayStatus,
  dateStringVariants,
} from '@/shared/ui/Fields/components/MappingData/constants';
import { Controller, FieldValues, UseFormRegister } from 'react-hook-form';
import { FieldAutocomplete } from '@/shared/ui/Fields/components/FieldAutocomplete/FieldAutocomplete';

export interface MapDateProps {
  control: any;
  index: number;
  name: string;
  register: UseFormRegister<FieldValues>;
}

export const MapDate: FC<MapDateProps> = memo(({ control, name, index, register }) => {
  return (
    <>
      <Grid xs={6} sm={6} lg={4} xl={3}>
        <FieldSelect
          label='Тип даты на входе'
          control={control}
          options={arrayDateInputType}
          error=''
          name={`${name}.${index}.inputType`}
        />
      </Grid>
      <Controller
        control={control}
        name={`${name}.${index}.inputType`}
        render={({ field }) =>
          field.value === 'stringDate' ? (
            <Grid xs={6} sm={6} lg={4} xl={3}>
              <FieldAutocomplete
                register={register}
                error=''
                control={control}
                name={`${name}.${index}.inputFormat`}
                label='Формат даты на входе'
                options={dateStringVariants}
              />
            </Grid>
          ) : (
            <p />
          )
        }
      />

      <Grid xs={6} sm={6} lg={4} xl={3}>
        <FieldSelect
          label='Тип даты на выходе'
          control={control}
          options={arrayDateOutputType}
          error=''
          name={`${name}.${index}.outputType`}
        />
      </Grid>
      <Controller
        control={control}
        name={`${name}.${index}.outputType`}
        render={({ field }) =>
          field.value === 'stringDate' ? (
            <Grid xs={6} sm={6} lg={4} xl={3}>
              <FieldAutocomplete
                register={register}
                error=''
                control={control}
                name={`${name}.${index}.outputFormat`}
                label='Формат даты на выходе'
                options={dateStringVariants}
              />
            </Grid>
          ) : (
            <p />
          )
        }
      />
      <Grid xs={6} sm={6} lg={4} xl={3}>
        <FieldSelect
          label='Действие'
          control={control}
          options={arrayStatus}
          error=''
          name={`${name}.${index}.status`}
        />
      </Grid>
      <Grid xs={6} sm={6} lg={4} xl={3}>
        <FieldSelect
          label='Если пустой'
          control={control}
          options={arrayEmptyRule}
          error=''
          name={`${name}.${index}.emptyRule`}
        />
      </Grid>
    </>
  );
});

MapDate.displayName = 'MapDate';
