import { GraphTrace } from "@/entities/Connect/types";

export const enrichList = (graphTrace: GraphTrace) => {
  const exchangeList = graphTrace.exchangeList.map((exchange) => {
    if (exchange.key === null || exchange.key === undefined) return exchange;
    return { ...exchange, id: exchange.key };
  });
  if (!graphTrace.data) return exchangeList;
  return exchangeList.map((exchange) => {
    if (exchange.inputConnectId) {
      return { ...exchange, inputConnect:  graphTrace.data[exchange.inputConnectId] };
    }
    if (exchange.processorId) {
      return { ...exchange, processor:  graphTrace.data[exchange.processorId] };
    }
    if (exchange.outputConnectId) {
      return { ...exchange, outputConnect:  graphTrace.data[exchange.outputConnectId] };
    }
    return exchange;
  });
};
