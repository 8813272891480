import React, { FC, useState } from 'react';

import cn from "classnames";
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import MuiDrawer from '@mui/material/Drawer';
import MenuList from '@mui/material/MenuList';
import IconButton from '@mui/material/IconButton';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import { MenuItemSub } from "./components/MenuItemSub";
import { MenuItemLink } from "./components/MenuItemLink";

import styles from './SettingsDrawer.module.scss';
import useLocalStorage from "@/hooks/useLocalStorage";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

interface ItemMenu {
  label: string;
  slug: string;
  predicate?: () => boolean;
}

interface LinkItemMenu extends ItemMenu {
  icon: FC;
  items?: ItemMenu[];
}

interface SettingsDrawerProp {
  items: LinkItemMenu[];
}

export function SettingsDrawer ({
  items,
}: SettingsDrawerProp) {
  const theme = useTheme();
  const [isExpand, setIsExpand] = useLocalStorage('settingsMenu.isExpand', false);
  const [selectedSlug, setSelectedSlug] = React.useState('');
  const resetExpandedSubmenu = () => { setSelectedSlug(''); };
  const toggleOpen = () => {
    setIsExpand(!isExpand);
  };

  const isLtr = theme.direction !== 'rtl';
  const ToggleIcon = isExpand && isLtr ? MenuFoldOutlined : MenuUnfoldOutlined;

  return (
    <Drawer
      variant="permanent"
      open={isExpand}
      className={styles.drawer}
    >
      <DrawerHeader className={cn(styles['drawer-header'], { open: isExpand })}>
        <Typography>Настройки</Typography>
        <IconButton
          onClick={toggleOpen}
        >
          <ToggleIcon style={{ fontSize: 20 }} />
        </IconButton>
      </DrawerHeader>
      <MenuList>
        {items.map(({
          predicate,
          ...item
        }) => {
          if (predicate && !predicate()) return null;
          const {
            slug,
            items,
          } = item;

          if (items?.length) {
            const handler = () => {
              setSelectedSlug(isExpand && slug !== selectedSlug ? slug : '');
            };

            return (
              <MenuItemSub
                key={slug}
                data={item}
                expanded={isExpand}
                isOpen={selectedSlug === slug}
                handler={handler}
              />
            );
          }

          return (
            <MenuItemLink
              key={slug}
              expanded={isExpand}
              data={item}
              handler={resetExpandedSubmenu}
            />
          );
        })}
      </MenuList>
    </Drawer>
  );
}
