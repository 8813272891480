import { FC, MouseEvent } from 'react';

import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import Tooltip from '@/shared/ui/Tooltip';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { VersionIcon } from '@/shared/ui/Icons/VersionIcon/VersionIcon';
import { ACTIONS_SEARCH_PARAMS, SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

export const OpenVersionsButton: FC = observer(() => {
  const { set, has, remove } = useSearchParamsTemplate();
  const active = has(SearchParams.ShowFlowVersionList);
  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    remove(ACTIONS_SEARCH_PARAMS);
    if (active) return;
    set([SearchParams.ShowFlowVersionList]);
  };

  return (
    <Tooltip title='Версии потока' placement='top'>
      <span>
        <Button
          className={cn({ active })}
          circle
          size={ButtonSizes.Small}
          variant={ButtonVariants.Outlined}
          onClick={handleClick}
        >
          <VersionIcon />
        </Button>
      </span>
    </Tooltip>
  );
});

OpenVersionsButton.displayName = 'OpenVersionsButton';
