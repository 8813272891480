import React, { useEffect } from "react";

import { observer } from "mobx-react-lite";
import { container } from "tsyringe";
import { Normalized } from "@/shared/lib/normalize";
import { DashboardService } from "@/entities/Dashboard/services/DashboardService";
import { CounterService } from "@/entities/Dashboard/widgets/DashboardPlatform/services/CounterService";
import { PeriodService } from "@/entities/Dashboard/widgets/DashboardPlatform/services/PeriodService";
import { BlockMiniService } from "@/entities/Block/services/BlockMiniService";
import { GraphView } from "@/entities/Dashboard/widgets/DashboardPlatform/components/View/GraphView/GraphView";
import { useSearchParamsTemplate } from "@/hooks/useTemplateSearchParams";
import { refreshOptions } from "@/entities/Dashboard/widgets/DashboardPlatform/components/HistoryManager/constants";
import { DataCounterPanel } from "@/entities/Dashboard/types";

import { CounterTimeline } from "./types";

const periodService = container.resolve(PeriodService);
const counterService = container.resolve(CounterService);
const dashboardService = container.resolve(DashboardService);
const blockMiniService = container.resolve(BlockMiniService);

export interface CounterPanelProps {
  dashboardPanelId: string;
}

const getRefreshTimeout = (key: string) => {
  const opt = refreshOptions.find((itm) => itm.key === key);
  return opt?.value;
}

export const defaultPanelData: DataCounterPanel = {
  graphType: "",
  blockIdList: [],
  selectAll: false,
};

export const CounterPanel = observer((props: CounterPanelProps) => {
  const { dashboardPanelId } = props;
  const { get } = useSearchParamsTemplate();
  const refresh = get('refresh');
  const { period, mode, interval, intervalOptions } = periodService;
  const { miniBlocks } = blockMiniService;

  const panelData = dashboardService.getPanelData(dashboardPanelId);
  const { graphType, blockIdList: corruptedList, selectAll } = panelData || defaultPanelData;
  // while backend don't clean idList on remove block/s
  const blockIdList = corruptedList.filter((id: string) => miniBlocks.entities[id]);

  const { counterTimelines } = counterService;

  const dataWatcher = blockIdList.slice().sort().join();
  useEffect(() => {
    const updateIds = (ids: string[]) => {
      dashboardService.changeDashboardPanel(dashboardPanelId, { data: { blockIdList: ids } });
    };
    const ids = selectAll ? [] : blockIdList;
    counterService.getCounterTimeLinesByBlockIds(
      ids,
      periodService.unixPeriod,
      periodService.intervalOption.value,
      selectAll && updateIds,
    );

    if (!refresh || mode !== 'live') return undefined;

    const timeout = getRefreshTimeout(refresh) * 1000; // seconds
    if (!timeout) return undefined;

    const id = setInterval(() => {
      if (periodService.mode === 'history') {
        clearInterval(id);
        return;
      }
      counterService.getCounterTimeLinesByBlockIds(
        ids,
        periodService.unixPeriod,
        periodService.intervalOption.value,
      );
    }, timeout);
    return () => clearInterval(id);
  }, [mode, dataWatcher, refresh, period, interval, intervalOptions, selectAll]);

  const constructXKeys = (counterTimelineList: Normalized<CounterTimeline>) => {
    const { ids } = counterTimelineList;
    return ids.filter((id) => blockIdList.includes(id));
  };

  const xKeys = constructXKeys(counterTimelines);

  const constructData = () => {
    const arrCounter = xKeys.map(id => counterTimelines.entities[id]);
    const [start, end] = periodService.unixPeriod;

    let list = arrCounter.flatMap(({
        counterInformationList,
        blockId
      }) => counterInformationList
        .map((point) => ({ ...point, blockId }))
    );
    if (selectAll) list = list.filter(({ t }) => t >= start && t <= end);
    const timeLineMap = list.reduce((acc, { blockId, t: timeStamp, v: value}) => {
      // @ts-ignore
      const point = acc[timeStamp] || {};
      return {
        ...acc,
        [timeStamp]: {
          ...point,
          t: timeStamp,
          [blockId]: value,
        }
      }
    }, {});

    // @ts-ignore
    return Object.values(timeLineMap).sort((a, b) => a.t - b.t)
  }

  return (
    <GraphView
      type={graphType}
      data={constructData()}
      xKeys={xKeys}
    />
  );
});
