import { FC, useCallback, useEffect } from 'react';

import { useFieldArray } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { Stack } from '@mui/system';
import Box from '@mui/system/Box';
import { isEmpty } from 'lodash';
import { Label } from '@/shared/ui/Label/Label';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { PlusIcon } from '@/shared/ui/Icons/PlusIcon/PlusIcon';
import { DraggableList } from "@/shared/ui/DraggableList";
import { ListItem } from "./ListItem";

import styles from './List.module.scss';

export interface ListProps {
  control: any;
  name: string;
  label?: string;
  defaultValue?: any;
  description?: string;
  renderElement?: (index: number) => JSX.Element;
  beforeList?: JSX.Element;
  withoutAdding?: boolean;
  disabled?: boolean;
  isDivider?: boolean;
  draggable?: boolean;
}

/**
 * Компонент списка полей
 */
export const List: FC<ListProps> = observer(
  ({
    control,
    name,
    label,
    defaultValue,
    description,
    renderElement,
    beforeList,
    withoutAdding,
    disabled,
    isDivider = false,
    draggable = false,
  }) => {
    const {
      fields: arrayFields,
      append,
      remove,
      move,
    } = useFieldArray({
      name,
      control,
    });

    const handleButtonAppend = useCallback(() => {
      append({});
    }, []);

    useEffect(() => {
      if (arrayFields.length) return;
      if (Array.isArray(defaultValue) && defaultValue.length) {
        append([...defaultValue]);
      }
      if (isEmpty(defaultValue)) {
        append({});
      }
    }, [arrayFields.length]);

    const renderItem = ({ id }: { id: string }, index: number) => (
      <ListItem
        id={id}
        key={id}
        draggable={draggable}
        render={() => renderElement(index)}
        handleRemove={() => remove(index)}
        isDivider={isDivider}
      />
    );

    return (
      <div className={cn(styles.root, { [styles.disabled]: disabled })}>
        <Box gap={2} display='flex' flexDirection='column'>
          {label && (
            <Label tooltip={description}>
              <h3 className={styles.title}>{label}</h3>
            </Label>
          )}
          {beforeList}
          <DraggableList
            items={arrayFields}
            draggable={draggable}
            onReorder={move}
            renderItem={renderItem}
          />
          {!withoutAdding && (
            <Box width={216}>
              <Button
                doesUseInFormOrTable
                variant={ButtonVariants.Secondary}
                onClick={handleButtonAppend}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  <PlusIcon />
                  <span>Добавить</span>
                </Stack>
              </Button>
            </Box>
          )}
        </Box>
      </div>
    );
  }
);
