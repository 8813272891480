import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { PropsWithChildren, useState } from 'react';

interface DraggableItemProps {
  id: string;
  draggable: boolean;
}

export function DraggableItem({ id, children, draggable }: PropsWithChildren<DraggableItemProps>) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const [isDragging, setIsDragging] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: 'relative' as React.CSSProperties['position'],
    cursor: isDragging ? 'grabbing' : 'auto',
    touchAction: 'none',
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  if (!draggable) return children;

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...(draggable ? listeners : {})}
      {...(draggable ? attributes : {})}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onTouchStart={handleDragStart}
      onTouchEnd={handleDragEnd}
    >
      {children}
    </div>
  );
}
