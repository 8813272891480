import { FC, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { UserService } from '@/entities/User';
import { RoleService } from '@/entities/Role';
import { DeleteUserDialog } from '@/features/DeleteUser';
import { CreateUserFormDialog } from '@/features/CreateUser';
import { UserList } from "./UserList";
import { UserSearch } from "./UserSearch";

const userService = container.resolve(UserService);
const roleService = container.resolve(RoleService);

export interface UsersPageProps {}

export const UsersPage: FC<UsersPageProps> = observer(() => {
  useEffect(() => {
    (async () => {
      await roleService.getRoles();
      await userService.getUsers(0);
    })();
  }, []);


  return (
    <>
      <Paper elevation={0} sx={{ padding: 4, height: '100%' }}>
        <Stack gap={2} height="100%" justifyContent="space-between" display='flex' flexDirection='column'>
          <UserSearch />
          <UserList />
        </Stack>
      </Paper>
      <DeleteUserDialog />
      <CreateUserFormDialog />
    </>
  );
});

UsersPage.displayName = 'UsersPage';
