import { CSSProperties, FC, useCallback } from 'react';

import Box from '@mui/system/Box';
import Paper from '@mui/material/Paper';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { Sidebar } from '@/shared/ui/Sidebar/Sidebar';
import { PythonBeanList } from '@/entities/PythonBean';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { UpsertPythonBeanService } from '@/features/UpsertPythonBean';

export interface PythonBeanSidebarProps {
  height?: CSSProperties['height'];
}

const upsertPythonBeanService = container.resolve(UpsertPythonBeanService);

export const PythonBeanSidebar: FC<PythonBeanSidebarProps> = observer(({ height }) => {
  const { get, remove } = useSearchParamsTemplate();

  const handleAdd = useCallback(() => {
    remove([SearchParams.PythonScriptId]);
    upsertPythonBeanService.showForm = true;
  }, [remove]);

  return (
    <Paper elevation={0} sx={{ pb: 2 }}>
      <Sidebar
        title='Скрипты'
        textAddButton='Добавить'
        onAdd={handleAdd}
        addonAfter={
          <Box height={height || 'calc(100vh - 285px)'} overflow='auto' width='100%'>
            <PythonBeanList pythonScriptId={get(SearchParams.PythonScriptId)} />
          </Box>
        }
      />
    </Paper>
  );
});

PythonBeanSidebar.displayName = 'PythonBeanSidebar';
