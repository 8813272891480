import { Node } from 'reactflow';
import { isArray } from "lodash";

import { ExecutorHistory } from "@/entities/Connect/types";
import { BlockNodeProps } from "../BlockNode";


const traverseExecutorHistory = (
  executorHistory: ExecutorHistory,
  fn: (parent: ExecutorHistory, lvl: number) => void,
  lvl: number = 0,
) => {
  fn(executorHistory, lvl);
  const { executorHistoryNext } = executorHistory;
  if (!isArray(executorHistoryNext)) return;
  executorHistoryNext.forEach((executorHistory2) => {
    traverseExecutorHistory(executorHistory2, fn, lvl + 1);
  });
}

const nodeStyle = {
  style: {
    width: 160,
    height: 60,
    display: 'flex',
  },
};

export function getNodesForBlockTracing(
  executorHistory: ExecutorHistory,
): Node<BlockNodeProps>[] {
  if (!executorHistory) return [];

  const res: Node<BlockNodeProps, string>[] = [];
  const mapCount: { [key: number]: number } = {};
  const addNode = (data: ExecutorHistory, lvl: number) => {
    mapCount[lvl] = (mapCount[lvl] || 0) + 1;
    const node = {
      type: 'blockNode',
      id: data.id,
      draggable: false,
      data: {
        id: data.id,
        blockId: data.blockId,
        flowId: data.flowId,
        name: data.blockName || '',
        flowName: data.flowName || '',
        value: '',
        executorHistory: data,
      },
      position: {
        x: lvl * 220 + 50,
        y: mapCount[lvl] * 100 + 50,
      },
      ...nodeStyle,
    }
    res.push(node);
  }
  traverseExecutorHistory(executorHistory, addNode);
  return res;
}
