import { FC, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { ObjectId } from 'bson';
import Stack from '@mui/system/Stack';
import Grid from '@mui/system/Unstable_Grid';
import { LinkOutlined } from '@ant-design/icons';

import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { FileCard } from '@/shared/ui/FileCard/FileCard';
import { amendDraggable } from '@/shared/lib/extendDndkit';
import { Attachment } from '@/features/UploadFiles/api/types';
import { UploadField } from "./UploadField";

import styles from './FileField.module.scss';

export interface FileFieldProps {
  downloading?: boolean;
  onDownload: (files: File[]) => void;
  attachments: Attachment[];
  files: File[];

  onDrop(files: File[]): void;

  onRemove(fileName: string, key: string): void;
  onSettings?(attachment: Attachment): () => void;
}

export const FileField: FC<FileFieldProps> = observer(
  ({
    downloading,
    attachments,
    files,
    onDownload,
    onDrop,
    onRemove,
    onSettings,
  }) => {
    const handleDownload = useCallback(() => {
      onDownload(files);
    }, [onDownload, files]);

    const handleRemove = useCallback(
      (fileName: string, key: string) => () => {
        onRemove(fileName, key);
      },
      [onRemove]
    );

    const handleNewLink = useCallback(() => {
      const guid = (new ObjectId()).toString();
      const data = {
        guid,
        fileName: '',
        url: '',
        downloadHeaders: '',
      };
      const handler = onSettings(data);
      handler();
    }, [onSettings]);

    return (
      <Stack gap={2.5} {...amendDraggable(true)}>
        <Grid container spacing={2}>
          {attachments.map((attachment) => {
            const { fileName, fileSize, url, guid, downloadHeaders } = attachment;
            const handleSettings = onSettings && onSettings(attachment);
            return (
              <Grid key={guid} xl={3} lg={4} md={6} xs={12} title={fileName}>
                <FileCard
                  name={fileName}
                  size={fileSize}
                  downloadLink={url}
                  onRemove={handleRemove(guid, 'attachments')}
                  onSettings={handleSettings}
                  downloadHeaders={downloadHeaders}
                />
              </Grid>
            );
          })}
          {files.map((file) => {
            const { lastModified, name, size } = file;
            return (
              <Grid key={`${name}${lastModified}`} xl={3} lg={4} md={6} xs={12} title={name}>
                <FileCard
                  loading={downloading}
                  isNew
                  name={name}
                  size={size}
                  onRemove={handleRemove(name, 'files')}
                />
              </Grid>
            );
          })}
          <Grid key="upload-place" xl={3} lg={4} md={6} xs={12}>
            <Stack direction="row" className={styles['upload-place']}>
              <UploadField onDrop={onDrop} />
              <Button
                className={styles.addBtn}
                variant={ButtonVariants.Secondary}
                doesUseInFormOrTable
                onClick={handleNewLink}
              >
                <LinkOutlined />
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Stack direction="row" width="100%">
          {Boolean(files.length) && (
            <Button
              className={styles.uploadBtn}
              variant={ButtonVariants.Secondary}
              doesUseInFormOrTable
              onClick={handleDownload}
            >
              {files.length > 1 ? 'Загрузить файлы' : 'Загрузить файл'}
            </Button>
          )}
        </Stack>
      </Stack>
    );
  }
);

FileField.displayName = 'FileField';
