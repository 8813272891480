import { injectable } from 'tsyringe';
import { makeAutoObservable, runInAction } from 'mobx';
import { pick, reduce, uniq } from 'lodash';
import { AxiosResponse } from 'axios';

import { normalize } from '@/shared/lib/normalize';
import { ApiService } from '@/shared/api/Api/services/ApiService';

import { CounterStore } from '../stores/CounterStore';
import { CounterTimeline } from "../components/DashboardPanel/CounterPanel/types";

@injectable()
export class CounterService {
  constructor(
    private apiService: ApiService,
    private counterStore: CounterStore,
  ) {
    makeAutoObservable(this);
  }

  get counterTimelines(): CounterStore['counterTimelines'] {
    return this.counterStore.counterTimelines;
  }

  get statistic(): CounterStore['statistic'] {
    return this.counterStore.statistic;
  }

  getStatisticBlocks(blockIdList: string[]) {
    if (!this.statistic) return null;

    const statisticMap = pick(this.statistic.entities, blockIdList);
    return reduce(statisticMap, (
      acc,
      { started, errors, success },
      ) => ({
        started: acc.started + started,
        errors: acc.errors + errors,
        success: acc.success + success,
      }), { started: 0, success: 0, errors: 0 });
  }

  async getCounterTimeLinesByBlockIds(
    blockIdList : string[],
    period: number[],
    interval: number,
    updateIds?: (ids: string[]) => void,
  ): Promise<void> {
    const [start, end] = period;
    try {
      const response = await this.apiService.instance.post<
        CounterTimeline[],
        AxiosResponse<CounterTimeline[]>,
        CounterTimeline[]
      >('editor/dashboard/counter/search', {
        blockIdList,
        start,
        end,
        interval,
      });

      const normalizedCounterTimelines = normalize(response.data, 'blockId');

      runInAction(() => {
        if (updateIds) updateIds(normalizedCounterTimelines.ids);
        this.counterStore.counterTimelines.ids = uniq([...this.counterStore.counterTimelines.ids, ...normalizedCounterTimelines.ids]);
        this.counterStore.counterTimelines.entities = {
          ...this.counterStore.counterTimelines.entities,
          ...normalizedCounterTimelines.entities,
        };
      });
    } catch (error) {
      // todo
      throw error;
    }
  }

  async getCounterStatistic(blockIdList: string[], period: number[]): Promise<void> {
    const [start, end] = period;

    try {
      const response = await this.apiService.instance.post(`editor/dashboard/counter/search/count`, {
        blockIdList,
        start,
        end,
      });

      const normalized = normalize(response.data, 'blockId');

      runInAction(() => {
        this.counterStore.statistic.ids = uniq([...this.counterStore.statistic.ids, ...normalized.ids]);
        this.counterStore.statistic.entities = {
          ...this.counterStore.statistic.entities,
          ...normalized.entities,
        };
      });
    } catch (error) {
      // todo
      throw error;
    }
  }

}
