import { FC, useLayoutEffect, useState } from 'react';

import { SettingsUserAuthSidebar } from '@/widgets/SettingsUserAuthSidebar';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import {
  SettingsUserAuthService,
  SettingsUserAuthStore,
  SettingsUserAuthTypes,
} from '@/entities/SettingsUserAuth';
import { CreateSettingsUserAuthDialog } from '@/features/CreateSettingsUserAuth';
import { DeleteSettingsUserAuthDialog } from '@/features/DeleteSettingsUserAuth';
import Stack from '@mui/material/Stack';
import { UpdateSettingsBasicAuthForm } from '@/features/UpdateSettingsBasicAuth';
import Paper from '@mui/material/Paper';
import { ErrorWrapper } from '@/shared/ui';
import { notify } from '@/shared/ui/Toast/notify';
import { UpdateSettingsIdmAuthForm } from '@/features/UpdateSettingsIdmAuth';

const settingsUserAuthService = container.resolve(SettingsUserAuthService);
const settingsUserAuthStore = container.resolve(SettingsUserAuthStore);

export interface SettingsUserAuthPageProps {}

export const SettingsUserAuthPage: FC<SettingsUserAuthPageProps> = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const renderForm = () => {
    let content: JSX.Element | null = null;

    if (settingsUserAuthStore.selectedId) {
      if (settingsUserAuthStore.selectedType === SettingsUserAuthTypes.SettingsBasic) {
        content = <UpdateSettingsBasicAuthForm key={settingsUserAuthStore.selectedId} />;
      }

      if (settingsUserAuthStore.selectedType === SettingsUserAuthTypes.SettingsIdm) {
        content = <UpdateSettingsIdmAuthForm key={settingsUserAuthStore.selectedId} />;
      }

      return (
        <Paper elevation={0} sx={{ padding: 4, flex: 1 }}>
          {content}
        </Paper>
      );
    }

    return null;
  };

  useLayoutEffect(() => {
    setIsLoading(true);

    settingsUserAuthService
      .getSettingsUserAuth()
      .then(() => setError(false))
      .catch(() => {
        notify.error('Не удалось получить список хранилищ');
        setError(true);
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (error) {
    return (
      <Paper elevation={0} sx={{ padding: 4, margin: 4 }}>
        <ErrorWrapper
          title='Не удалось получить список хранилищ'
          repeat={settingsUserAuthService.getSettingsUserAuth}
        />
      </Paper>
    );
  }

  return (
    <>
      <Stack direction='row' gap={3}>
        <SettingsUserAuthSidebar isLoading={isLoading} />

        <Stack flex={1}>
          {renderForm()}
        </Stack>
      </Stack>
      <CreateSettingsUserAuthDialog />
      <DeleteSettingsUserAuthDialog />
    </>
  );
});

SettingsUserAuthPage.displayName = 'SettingsUserAuthPage';
