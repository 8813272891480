import { FC, memo } from 'react';

import Grid from '@mui/system/Unstable_Grid';
import { FieldSelect } from '@/shared/ui/Fields/components/FieldSelect/FieldSelect';
import { arrayEmptyRule, arrayStatus } from '@/shared/ui/Fields/components/MappingData/constants';

export interface MapPrimitivesProps {
  control: any;
  index: number;
  name: string;
  draggable?: boolean;
}

export const MapPrimitives: FC<MapPrimitivesProps> = memo(({ control, name, index, draggable }) => {
  return (
    <>
      <Grid xs={12} sm={6} lg={4} xl={3}>
        <FieldSelect
          label='Действие'
          control={control}
          draggable={draggable}
          options={arrayStatus}
          error=''
          name={`${name}.${index}.status`}
        />
      </Grid>
      <Grid xs={12} sm={6} lg={4} xl={3}>
        <FieldSelect
          label='Если пустой'
          control={control}
          draggable={draggable}
          options={arrayEmptyRule}
          error=''
          name={`${name}.${index}.emptyRule`}
        />
      </Grid>
    </>
  );
});

MapPrimitives.displayName = 'MapPrimitives';
