import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Stack } from '@mui/system';
import { PythonMonitoringPods } from '@/entities/PythonLib/entities/PythonMonitoringPods/PythonMonitoringPods';

export const PythonLibContent: FC = observer(() => {
  return (
    <Stack flex={1}>
      <PythonMonitoringPods />
    </Stack>
  );
});
PythonLibContent.displayName = 'PythonLibContent';
