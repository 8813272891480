import React, { useEffect, useState } from 'react';
import { container } from 'tsyringe';
import { Popover, Button as AButton } from 'antd';
import {
  ClockCircleOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import cn from "classnames";
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';

import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { Button } from '@/shared/ui';
import { useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { PeriodService } from '@/entities/Dashboard/widgets/DashboardPlatform/services/PeriodService';
import { Interval } from "./Interval";
import { ZoomOutBtn } from './ZoomOutBtn';
import { PeriodPicker } from './PeriodPicker';
import { PredefinedPeriods } from './PredefinedPeriods';
import { Refresh } from './Refresh';
import { describeTimeRange, defaultFormat } from './datemath';

import styles from './HistoryManager.module.scss';

const periodService = container.resolve(PeriodService);

export const HistoryManager = observer(() => {
  const { get } = useSearchParamsTemplate();

  const { mode, period } = periodService;
  const historyMode = mode === 'history';
  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);

  const hide = () => {
    setClicked(false);
    setHovered(false);
  };


  const handleHoverChange = (open: boolean) => {
    if (clicked) return;
    periodService.updatePeriod();
    setHovered(open);
    setClicked(false);
  };

  const handleClickChange = (open: boolean) => {
    setHovered(false);
    setClicked(open);
  };

  useEffect(() => {
    periodService.setupInitialCfg(
      get('from'),
      get('to'),
    );
  }, []);

  const handleStep = (forward: boolean) => () => {
    const [start, end] = periodService.unixPeriod;
    periodService.fixPeriod(); // must be second string
    const duration = end - start;

    const unixPeriod: [number, number] = forward
      ? [start + duration, end + duration]
      : [start - duration, end - duration];
    periodService.unixToDayjs(unixPeriod);
  };

  const [start, end] = period;
  const renderTitle = () => describeTimeRange([start.raw ?? start, end.raw ?? end]);
  const StateIcon = clicked ? UpOutlined : DownOutlined;

  const popoverContent = (
    <>
      <PredefinedPeriods
        closePopup={hide}
      />
      <PeriodPicker />
    </>
  );

  const hoverContent = (
    <span style={{ fontSize: '.875em' }}>
      {start.format(defaultFormat)}
      &nbsp;по&nbsp;
      {end.format(defaultFormat)}
    </span>
  );

  return (
    <div className={styles.root}>
      <Interval />
      {historyMode && (
        <Button
          variant={ButtonVariants.Secondary}
          size={ButtonSizes.Medium}
          doesUseInFormOrTable
          onClick={handleStep(false)}
        >
          <NavigateBeforeOutlinedIcon />
        </Button>
      )}
      <Popover
        style={{ width: 500 }}
        content={hoverContent}
        trigger="hover"
        placement="topLeft"
        arrow={false}
        open={hovered && !historyMode}
        onOpenChange={handleHoverChange}
      >
        <Popover
          overlayClassName={styles.popover}
          content={popoverContent}
          trigger="click"
          open={clicked}
          arrow={false}
          placement='bottomLeft'
          onOpenChange={handleClickChange}
        >
          <AButton
            className={cn(styles['time-manager-btn'], {
              'mode-history': historyMode,
            })}
            icon={<ClockCircleOutlined style={{ fontSize: '1.3em' }} />}
          >
            {renderTitle()}
            &nbsp;
            <StateIcon />
          </AButton>
        </Popover>
      </Popover>
      {historyMode && (
        <Button
          variant={ButtonVariants.Secondary}
          size={ButtonSizes.Medium}
          doesUseInFormOrTable
          onClick={handleStep(true)}
        >
          <NavigateNextOutlinedIcon />
        </Button>
      )}
      <ZoomOutBtn />
      {!historyMode && <Refresh />}
    </div>
  );
});
