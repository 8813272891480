import { FC, useCallback, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { SubmitHandler, useForm } from 'react-hook-form';
import { container } from 'tsyringe';
import Stack from '@mui/material/Stack';
import _, { isEmpty } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { FieldSelect } from '@/shared/ui/Fields/components/FieldSelect/FieldSelect';
import { OpenApiService } from '@/entities/OpenApi/features/api/OpenApiService';
import { OpenApiResponse } from '@/entities/OpenApi/types';
import { Drawer } from '@/shared/ui/Drawer/Drawer';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { FieldSwitch } from '@/shared/ui/Fields/components/FieldSwitch/FieldSwitch';
import { Resizable } from '@/shared/ui';
import { authenticationOption } from '@/entities/OpenApi/utils/OpenApiConst';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { OpenApiAppStore } from '@/entities/OpenApi/stores/OpenApiAppStore';
import { openApiFormValidation } from '@/shared/lib';

import styles from './OpenApiDialogForm.module.scss';

export interface OpenApiDialogFormProps {}

const openApiService = container.resolve(OpenApiService);
const openApiStore = container.resolve(OpenApiAppStore);

export const OpenApiDialogForm: FC<OpenApiDialogFormProps> = observer(() => {
  const { remove } = useSearchParamsTemplate();

  const { selectedId, currentOpenApiApp } = openApiService;

  const formValues = selectedId ? _.omitBy(currentOpenApiApp, _.isNil) : {};

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm<OpenApiResponse>({
    resolver: yupResolver(openApiFormValidation),
    values: formValues,
    mode: 'onChange',
  });

  useEffect(() => {
    if (isEmpty(selectedId)) {
      reset({ name: '', description: '', url: '', server: '' });
    }
  }, [selectedId]);

  const title = selectedId ? 'Изменение OpenAPI-сервиса' : 'Добавление OpenAPI-сервиса';

  const buttonSubmitText = selectedId ? 'Изменить' : 'Добавить';

  const authenticationValue = watch('authentication');

  const handleClose = useCallback(() => {
    openApiService.open = false;
    openApiService.selectedId = null;
    reset();
    remove([SearchParams.OpenApiId]);
  }, [reset]);

  const handleValidSubmit: SubmitHandler<OpenApiResponse> = useCallback(
    async (openApiApp) => {
      if (!selectedId) await openApiService.addOpenApiApp(openApiApp);
      else await openApiService.editOpenApiApp(openApiApp);
      if (!openApiStore.isErrorSubmit) handleClose();
    },

    [openApiStore.isErrorSubmit, handleClose, selectedId]
  );

  if (!openApiService.open) return null;

  return (
    <Resizable maxWidth={window.innerWidth - 260} initialWidth={434} minWidth={434}>
      <Drawer id='handlerTimer' className={styles.drawer} title={title} onClose={handleClose}>
        <form onSubmit={handleSubmit(handleValidSubmit)} className='p-5'>
          <Stack paddingBottom='100px' gap={2} padding={2}>
            <FieldInput
              error={errors.name}
              name='name'
              disabled={!isEmpty(selectedId)}
              label='Уникальное имя сервиса'
              register={register}
              control={control}
            />
            <FieldInput
              error={errors.label}
              name='label'
              label='Название сервиса'
              register={register}
              control={control}
            />
            <FieldInput
              textarea
              error={errors.description}
              name='description'
              label='Описание'
              register={register}
              control={control}
            />
            <FieldInput
              textarea
              error={errors.url}
              name='url'
              label='URL до файла-конфигурации'
              register={register}
              control={control}
            />
            <FieldInput
              textarea
              error={errors.server}
              name='server'
              label='Сервер'
              register={register}
              control={control}
            />
            <FieldSelect
              options={authenticationOption}
              draggable=''
              error={errors.authentication}
              defaultValue={authenticationOption[0].value}
              name='authentication'
              label='Способ аутентификации'
              register={register}
              control={control}
            />

            {authenticationValue === 'Basic' && (
              <>
                <FieldInput
                  error={errors.username}
                  name='username'
                  label='Логин'
                  register={register}
                  control={control}
                />
                <FieldInput error='' name='password' label='Пароль' register={register} />
              </>
            )}
            {authenticationValue === 'Bearer' && (
              <FieldInput
                error={errors.bearerToken}
                name='bearerToken'
                label='Bearer токен'
                register={register}
              />
            )}
            <FieldSwitch
              defaultValue='false'
              label='Проверять обновление спецификации'
              name='autoCheck'
              control={control}
              error={errors.autoCheck}
            />
          </Stack>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
              padding: 5,
            }}
          >
            <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
              Отмена
            </Button>
            <Button
              type='submit'
              variant={ButtonVariants.Popup}
              loading={openApiStore.isLoadingUpdateOpenApi}
            >
              {buttonSubmitText}
            </Button>
          </div>
        </form>
      </Drawer>
    </Resizable>
  );
});

OpenApiDialogForm.displayName = 'OpenApiDialogForm';
