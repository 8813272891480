import React from 'react';

import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { identity } from 'lodash';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  SettingOutlined,
  LinkOutlined,
  ApiOutlined,
  KeyOutlined,
  BankOutlined,
  NotificationOutlined,
  AuditOutlined,
  TeamOutlined,
  SolutionOutlined,
  PythonOutlined,
  ToolOutlined,
  CrownOutlined,
} from '@ant-design/icons';

import { PythonBeanPage } from '@/pages/PythonBeanPage';
import { UpsertSettingsForm } from '@/features/UpsertSettings';
import { CompanySettings } from '@/pages/CompanySettings';
import { NotificationPage } from '@/pages/NotificationPage';
import { UsersPage } from '@/pages/UsersPage';
import { RolesPage } from '@/pages/RolesPage';
import { ExternalAppsPage } from '@/pages/ExternalAppsPage';
import { SettingsUserAuthPage } from '@/pages/SettingsUserAuthPage';
import { KeyValueStorePage } from '@/pages/KeyValueStorePage';
import { OpenApiPage } from '@/entities/OpenApi/pages/OpenApiPage/OpenApiPage';

import { PythonLibsPage } from '@/entities/PythonLib/pages/PythonLibsPage';
import useLocalStorageRedux from '@/hooks/useLocalStorageRedux';
import { TenantPage } from '@/entities/Tenant/pages/TenantPage';
import { SettingsDrawer } from './SettingsDrawer';
import { WSDLPage } from '@/entities/WSDLService/pages/WSDLPage/WSDLPage';

const linkBuilder = (domain: string) => (path: string) => `${domain}settings/${path}`;

const sx = { padding: '32px', height: '100%' };

export function SettingsPage() {
  const serverPath = useSelector((state) => state.location.serverPath);
  const getLink = linkBuilder(serverPath);
  const [companyId] = useLocalStorageRedux('companyId');

  const items = [
    {
      label: 'Основные',
      icon: SettingOutlined,
      slug: 'global',
      // noPage: true,
      // items: [{
      //   label: 'Общие настройки',
      //   slug: 'global',
      // },{
      //   label: 'Настройки сервера почты',
      //   slug: 'email',
      // },{
      //   label: 'Настройки Telegram',
      //   slug: 'telegram',
      // }],
    },
    {
      label: 'Внешние сервисы',
      icon: LinkOutlined,
      slug: 'externalService',
    },
    {
      label: 'OpenAPI-сервисы',
      icon: ApiOutlined,
      slug: 'openApiService',
    },
    {
      label: 'WSDL-сервисы',
      icon: ApiOutlined,
      slug: 'wsdlService',
    },
    {
      label: 'KeyValue Store',
      icon: KeyOutlined,
      slug: 'keyValueStore',
    },
    {
      label: 'Компания',
      icon: BankOutlined,
      slug: 'company',
    },
    {
      label: 'Уведомления',
      icon: NotificationOutlined,
      slug: 'notifications',
    },
    {
      label: 'Учетные системы',
      icon: AuditOutlined,
      slug: 'auth',
      // items: [{
      //   label: 'LDAP',
      //   slug: 'ldap'
      // },{
      //   label: 'Keycloak',
      //   slug: 'keycloak'
      // },{
      //   label: 'asdf',
      //   slug: 'asdf'
      // }],
    },
    {
      label: 'Пользователи',
      icon: TeamOutlined,
      slug: 'users',
    },
    {
      label: 'Роли пользователей',
      icon: SolutionOutlined,
      slug: 'roles',
    },
    {
      label: 'Тенанты',
      icon: CrownOutlined,
      slug: 'tenants',
      predicate: () => companyId === 'default',
    },
    {
      label: 'Python скрипты',
      icon: PythonOutlined,
      slug: 'pythonBeans',
    },
    {
      label: 'Управление Python обработчиком',
      icon: ToolOutlined,
      slug: 'pythonLibs',
    },
  ]
    .map(({ slug, noPage, items, ...itm }) => {
      let submenu = {};
      if (items) {
        submenu = { items: items.map((el) => ({ ...el, link: getLink(el.slug), parent: slug })) };
      }
      if (noPage && !items) return null;
      const link = getLink(noPage ? items[0].slug : slug);
      return {
        ...itm,
        link,
        ...submenu,
        slug,
      };
    })
    .filter(identity);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: 'calc(100vh - var(--header-height))',
        overflow: 'hidden',
      }}
    >
      <SettingsDrawer items={items} />
      <Box component='main' sx={{ flexGrow: 1, p: 3, width: 'calc(100vw - 240px)' }}>
        <Routes>
          <Route path='' element={<Navigate to='global' replace />} />
          <Route
            path='global'
            element={(
              <Paper elevation={0} sx={sx}>
                <UpsertSettingsForm />
              </Paper>
            )}
          />
          <Route path='externalService' element={<ExternalAppsPage />} />
          <Route path='openApiService' element={<OpenApiPage />} />
          <Route
            path='wsdlService'
            element={(
              <Paper elevation={0} sx={sx}>
                <WSDLPage />
              </Paper>
            )}
          />
          <Route path='keyValueStore' element={<KeyValueStorePage />} />
          <Route path='pythonBeans' element={<PythonBeanPage hideChooseScriptButton />} />
          <Route path='pythonLibs' element={<PythonLibsPage />} />
          <Route path='auth' element={<SettingsUserAuthPage />} />
          <Route path='users' element={<UsersPage />} />
          <Route
            path='roles'
            element={(
              <Paper elevation={0} sx={sx}>
                <RolesPage />
              </Paper>
            )}
          />
          {companyId === 'default' && <Route path='tenants' element={<TenantPage />} />}
          <Route
            path='company'
            element={(
              <Paper elevation={0} sx={sx}>
                <CompanySettings />
              </Paper>
            )}
          />
          <Route path='notifications' element={<NotificationPage />} />
        </Routes>
      </Box>
    </Box>
  );
}
