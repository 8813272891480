import { FC, memo, useEffect, useState } from 'react';
import { container } from 'tsyringe';
import Paper from '@mui/material/Paper';
import { ErrorWrapper } from '@/shared/ui';
import { Loader } from '@/shared/ui/Loader/Loader';
import { OpenApiList } from '@/entities/OpenApi/entities/OpenApiList/OpenApiList';
import { OpenApiService } from '@/entities/OpenApi/features/api/OpenApiService';
import { OpenApiDialogForm } from '@/entities/OpenApi/OpenApiForm/OpenApiDialogForm';
import styles from './OpenApiPage.module.scss';
import { DeleteOpenApiAppDialog } from '@/entities/OpenApi/DeleteOpenApiAppDialog/DeleteOpenApiAppDialog';

const openApiService = container.resolve(OpenApiService);

export interface OpenApiPageProps {}

export const OpenApiPage: FC<OpenApiPageProps> = memo(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [thereIsError, setThereIsError] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    openApiService
      .getOpenApiApps()
      .then(() => setThereIsError(false))
      .catch(() => setThereIsError(true))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <Loader width={60} height={60} />;
  }

  if (thereIsError) {
    return (
      <ErrorWrapper title='Не удалось получить данные' repeat={openApiService.getOpenApiApps} />
    );
  }

  return (
    <Paper elevation={0} sx={{ p: 4, height: '100%', position: 'relative', display: 'flex' }}>
      <div className={styles.root}>
        <OpenApiList />
      </div>
      <OpenApiDialogForm />
      <DeleteOpenApiAppDialog />
    </Paper>
  );
});

OpenApiPage.displayName = 'OpenApiPage';
