import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import React, { useState } from 'react';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Overlay } from './components/Overlay'; // Предполагается, что у вас есть компонент Overlay

interface DraggableListProps {
  draggable: boolean;
  items: { id: string }[];
  onReorder: (from: number, to: number) => void;
  renderItem: ({ id }: { id: string }, index: number) => JSX.Element;
}

export function DraggableList({ items, renderItem, onReorder, draggable }: DraggableListProps) {
  const [activeId, setActiveId] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 500,
        tolerance: 5,
      },
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);
      onReorder(oldIndex, newIndex);
    }
    setActiveId(active.id as string);
  };
  const children = items.map((itm, idx) => renderItem(itm, idx));

  if (!draggable) return children;

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={({ active }) => setActiveId(active.id as string)}
      onDragEnd={handleDragEnd}
      onDragCancel={() => setActiveId(null)}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {children}
        <DragOverlay>{activeId ? <Overlay id={activeId} /> : null}</DragOverlay>
      </SortableContext>
    </DndContext>
  );
}
