import { FC, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { Drawer } from '@/shared/ui/Drawer/Drawer';
import { LoaderIcon } from "@/shared/ui/Icons/LoaderIcon/LoaderIcon";
import {
  CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE,
  SearchParams,
  useSearchParamsTemplate,
} from '@/hooks/useTemplateSearchParams';
import { SettingsService } from '@/entities/Settings/api/SettingsService';

import { LogSettingsFormContainer } from './LogSettingsFormContainer/LogSettingsFormContainer';

const settingsService = container.resolve(SettingsService);

export const LogSettingsContainer: FC = observer(() => {
  const { remove, has } = useSearchParamsTemplate();
  const { logStorages } = settingsService;

  const handleDrawerClose = () => {
    remove([
      SearchParams.ShowMessageDeliverySettings,
      SearchParams.ShowLogSettings,
      ...CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE,
    ]);
  };

  useEffect(() => {
    settingsService.getLogLocations();
  }, []);

  if (!has(SearchParams.ShowLogSettings)) return null;

  const content = logStorages
    ? <LogSettingsFormContainer />
    : <LoaderIcon style={{ margin: '50px auto', height: '30px' }} width={30} height={30} />;



  return (
    <div className='col-span-1'>
      <Drawer largeTitle title='Настройка логирования' onClose={handleDrawerClose}>
        {content}
      </Drawer>
    </div>
  );
});
