import { FC, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import Paper from '@mui/material/Paper';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { TenantForm } from '@/entities/Tenant/TenantForm/TenantForm';
import { TenantSaveDialog } from '@/entities/Tenant/TenantSaveDialog/TenantSaveDialog';
import { TenantDeleteDialog } from '@/entities/Tenant/TenantDeleteDialog/DeleteTenantDialog';

export interface TenantContentProps {}

export const TenantContent: FC<TenantContentProps> = observer(() => {
  const { get, set, remove } = useSearchParamsTemplate();

  const tenantId = get(SearchParams.TenantId);

  const onFormSubmitSuccess = useCallback(
    (tenant: any) => {
      set([{ [SearchParams.TenantId]: tenant.id }]);
    },
    [set]
  );

  const onDialogCloseSuccess = () => {
    remove([SearchParams.TenantId]);
  };

  return (
    <>
      <Paper elevation={0} sx={{ padding: 4, flexGrow: 1 }}>
        <TenantForm tenantId={tenantId} onSuccess={onFormSubmitSuccess} />
      </Paper>
      <TenantSaveDialog tenantId={tenantId} onSuccess={onFormSubmitSuccess} />
      <TenantDeleteDialog tenantId={tenantId} onSuccess={onDialogCloseSuccess} />
    </>
  );
});

TenantContent.displayName = 'TenantContent';
