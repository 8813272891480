import Stack from "@mui/system/Stack";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { container } from "tsyringe";
import { observer } from "mobx-react-lite";

import { notify } from "@/shared/ui/Toast/notify";
import { ButtonVariants } from "@/shared/ui/Button/types";
import { FieldInput } from "@/shared/ui/Fields/components/FieldInput/FieldInput";
import { Button } from "../../../shared/ui/Button";
import { EditorUploadStore } from "../api/EditorUploadStore";
import { Attachment } from "../api/types";


const editorUploadStore = container.resolve(EditorUploadStore);
const schema = yup.object({
  fileName: yup.string().default('').required()
    .max(100, 'Название не должно превышать 100 символов'),
  url: yup.string().default('').required()
    .max(300, 'Название не должно превышать 300 символов'),
});

export const SettingsForm = observer(() => {
  const { selectedFile } = editorUploadStore;
  const isFile = !!selectedFile.fileSize;
  const isName = !!selectedFile.fileName;

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    shouldFocusError: false,
    values: selectedFile || undefined,
  });


  const onSubmit: SubmitHandler<Attachment> = (data) => {
    if (isName) editorUploadStore.update(data);
    else editorUploadStore.insert(data);
    editorUploadStore.selectedFile = null;
    notify.success('Изменения внесены');
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={2} p={2.5} justifyContent="space-between">
        <Stack spacing={2}>
          <FieldInput
            label="Наименование файла"
            name="fileName"
            register={register}
            error={errors.fileName}
            id="fileName"
            multiline
            maxRows={4}
          />
          <FieldInput
            label='Ссылка на файл'
            name='url'
            register={register}
            error={errors.url}
            id='url'
            disabled={isFile}
            multiline
            maxRows={4}
          />
          {!isFile && (
            <FieldInput
              label="Заголовки авторизации"
              name="downloadHeaders"
              register={register}
              error={errors.downloadHeaders}
              id="downloadHeaders"
              multiline
              maxRows={4}
              description="Формат построчный: <ключ>: <значение>"
            />
          )}
        </Stack>
        <Button
          variant={ButtonVariants.Primary}
          type="submit"
        >
          Сохранить
        </Button>
      </Stack>
    </form>
  );
});
