import { useCallback, useEffect, useState } from 'react';

const useLocalStorage = (key, defaultValue) => {
  const getValue = useCallback(() => {
    let currentValue;

    try {
      currentValue = JSON.parse(localStorage.getItem(key) || String(defaultValue));
    } catch (error) {
      currentValue = defaultValue;
    }

    return currentValue;
  }, [key, defaultValue]);

  const [value, setValue] = useState(getValue);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue, getValue];
};

export default useLocalStorage;
