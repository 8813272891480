import { CSSProperties, FC, useCallback } from 'react';

import Box from '@mui/system/Box';
import { observer } from 'mobx-react-lite';
import { Sidebar } from '@/shared/ui/Sidebar/Sidebar';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { TenantList } from '@/entities/Tenant/entities/TenantList';

export interface TenantSidebarProps {
  height?: CSSProperties['height'];
}

export const TenantSidebar: FC<TenantSidebarProps> = observer(({ height }) => {
  const { get, remove } = useSearchParamsTemplate();

  const handleAdd = useCallback(() => {
    remove([SearchParams.TenantId]);
  }, [remove]);

  return (
    <Box bgcolor='var(--paper-color)'>
      <Sidebar
        title='Тенанты'
        textAddButton='Добавить'
        onAdd={handleAdd}
        addonAfter={
          <Box height={height || 'calc(100vh - 272px)'} overflow='auto' width='100%'>
            <TenantList tenantId={get(SearchParams.TenantId)} />
          </Box>
        }
      />
    </Box>
  );
});

TenantSidebar.displayName = 'TenantSidebar';
