import { FC, useEffect } from 'react';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { Box, Stack } from '@mui/system';
import { useForm, useWatch } from 'react-hook-form';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { TenantStore } from '@/entities/Tenant/stores/TenantStore';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { TenantService } from '@/entities/Tenant/api/TenantService';
import { Tenant } from '@/entities/Tenant/stores/types';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

const tenantService = container.resolve(TenantService);
const tenantStore = container.resolve(TenantStore);

export interface DeleteTenantDialogProps {
  tenantId?: string;

  onSuccess?(): void;
}

export const TenantDeleteDialog: FC<DeleteTenantDialogProps> = observer(
  ({ tenantId, onSuccess }) => {
    const { has, remove } = useSearchParamsTemplate();

    const isDialogOpen = has(SearchParams.ShowDeleteTenantDialog);

    const currentTenant = tenantId ? tenantStore.tenants?.entities?.[tenantId] : null;

    const {
      control,
      register,
      reset,
      formState: { errors },
    } = useForm<Tenant>({ mode: 'onChange' });

    const companyId = useWatch({ control, name: 'companyId' });
    const isConfirmed = currentTenant?.companyId === companyId;

    const closeDialog = () => {
      remove([SearchParams.ShowDeleteTenantDialog]);
      reset({});
    };

    const handleAccept = async () => {
      if (!tenantId) return;
      await tenantService.deleteTenant(companyId);
      onSuccess?.();
      closeDialog();
    };

    useEffect(() => {
      if (!tenantId && isDialogOpen) {
        closeDialog();
      } else {
        reset(currentTenant ?? {});
      }
    }, [tenantId, isDialogOpen]);

    if (!tenantId || !isDialogOpen) return null;

    return (
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle onClose={closeDialog}>Удаление тенанта</DialogTitle>
        <DialogContent>
          <Box component='form'>
            <div>
              Вы уверены, что хотите удалить тенант? Все данные будут удалены без возможности
              восстановления. Для подтверждения удаления требуется указать идентификатор тенанта
            </div>
            <Stack paddingY={2.5}>
              <FieldInput
                register={register}
                control={control}
                label='Идентификатор тенанта'
                name='companyId'
                localeName='companyId'
                error={errors.companyId}
                disabled={!tenantId}
              />
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box width={212}>
            <Button variant={ButtonVariants.Secondary} onClick={closeDialog}>
              Отменить
            </Button>
          </Box>
          {isConfirmed && (
            <Box width={212}>
              <Button
                loading={tenantStore.isLoading}
                variant={ButtonVariants.Popup}
                onClick={handleAccept}
              >
                Удалить все данные
              </Button>
            </Box>
          )}
        </DialogActions>
      </Dialog>
    );
  }
);

TenantDeleteDialog.displayName = 'TenantDeleteDialog';
