import React, { FC, useCallback, useEffect, useMemo } from 'react';

import { observer, Observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { container } from 'tsyringe';
import { isEmpty } from 'lodash';
import { ActionPanel, ListWithInfiniteScroll } from '@/shared/ui';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { PythonLibsService } from '@/entities/PythonLib/api/PythonLibsService';
import { PythonLibsStore } from '@/entities/PythonLib/stores/PythonLibsStore';
import { PythonLibsRequest } from '@/entities/PythonLib/stores/types';
import { DeletePythonLibButton } from '@/features/DeletePythonLib/ui/DeletePythonLibButton';
import { modeUserLib } from '@/entities/PythonLib/utils/PythonLibConst';
import { EditPythonLibsButton } from '@/entities/PythonLib/features/actions/EditPythonLibsButton';
import { PythonLibsCard } from '@/entities/PythonLib/entities/PythonLibsCard/PythonLibsCard';
import { sortByField } from '@/entities/PythonLib/utils/utils';

export interface PythonLibsListProps {
  pythonLibTitle?: string;
}

const pythonLibsService = container.resolve(PythonLibsService);
const pythonLibsStore = container.resolve(PythonLibsStore);

export const PythonLibsList: FC<PythonLibsListProps> = observer(() => {
  const { set, remove } = useSearchParamsTemplate();

  const libsPython = JSON.parse(JSON.stringify(pythonLibsStore.libs))?.data?.sort(
    sortByField('title')
  );

  const selectedLibTitle = pythonLibsStore.selectedLib?.title;

  const filteredLibs =
    !isEmpty(pythonLibsService?.filteredLibs) &&
    JSON.parse(JSON.stringify(pythonLibsService?.filteredLibs));

  useEffect(() => {
    pythonLibsService.getPythonLibs();
  }, []);

  const handleLinkClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, title: PythonLibsRequest) => {
      event.stopPropagation();
      event.preventDefault();
      pythonLibsStore.selectedLib = title;
      remove([
        SearchParams.ShowEditPythonLibForm,
        SearchParams.ShowAddPythonLibForm,
        SearchParams.PythonLibName,
        SearchParams.ShowPythonPodLog,
        SearchParams.PythonPodsId,
      ]);
    },
    [remove, set]
  );

  const actions =
    pythonLibsStore.mode === modeUserLib ? (
      <ActionPanel small withoutBorder>
        <EditPythonLibsButton />
        <DeletePythonLibButton />
      </ActionPanel>
    ) : null;

  const libs = useMemo(
    () => (isEmpty(filteredLibs) && !isEmpty(libsPython) ? libsPython : filteredLibs),
    [filteredLibs, libsPython]
  );

  return (
    <ListWithInfiniteScroll
      animated
      elementWidth='calc(100% - 20px)'
      elements={libs || []}
      activeElementKey={selectedLibTitle}
      stopAnimatedForKey={selectedLibTitle}
      loading={pythonLibsService.isLoading}
      fetchNextElement={() => undefined}
      renderElement={(pythonLibs: PythonLibsRequest) => (
        <Observer key={pythonLibs.title}>
          {() => (
            <Link to={pythonLibs.title}>
              <PythonLibsCard
                pythonLib={pythonLibs?.title}
                active={pythonLibs?.title === selectedLibTitle}
                actions={actions}
                pythonLibs={pythonLibs}
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  handleLinkClick(event, pythonLibs)
                }
              />
            </Link>
          )}
        </Observer>
      )}
    />
  );
});

PythonLibsList.displayName = 'PythonLibsList';
