import * as React from 'react';
import { FC, useCallback } from 'react';

import { Stack } from '@mui/system';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { Observer, observer } from 'mobx-react-lite';
import { FilterIcon } from '@/shared/ui/Icons/FilterIcon/FilterIcon';
import Box from '@mui/system/Box';

import { ButtonWithModal } from '../ButtonWithModal/ButtonWithModal';

import styles from './Filter.module.scss';
import { isEmpty } from 'lodash';
import { Popover } from '@mui/material';

export interface FilterProps {
  title: string;
  children: JSX.Element;
  isActive?: boolean;
  tooltip?: JSX.Element;
  onReset: () => void;
  onFilter: () => void;
  python?: string;
  filterMessage?: boolean;
}

export const Filter: FC<FilterProps> = observer(
  ({ title, children, isActive, tooltip, filterMessage, python, onFilter, onReset }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const refreshText = isEmpty(python) ? 'Сбросить все фильтры' : 'Сбросить фильтр';
    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleButtonOkClick = useCallback(
      (close: () => void) => () => {
        onFilter();
        close();
      },
      [onFilter]
    );

    const handleButtonCancelClick = useCallback(
      (close: () => void) => () => {
        close();
      },
      []
    );

    const handleButtonResetClick = useCallback(
      (close: () => void) => () => {
        onReset();
        if (filterMessage === true) {
          close();
        }
      },
      [onReset, filterMessage]
    );

    return (
      <ButtonWithModal
        title={title}
        icon={<FilterIcon width={12} height={12} />}
        isActive={isActive}
        tooltip={tooltip}
        onClick={handleButtonClick}
        render={({ isOpen, close }) => {
          return (
            <Observer>
              {() => (
                <Popover open={isOpen} anchorEl={anchorEl} sx={{ marginTop: 0.5 }} onClose={close}>
                  <Box className={styles.menu}>
                    <Stack gap={5}>
                      <Stack direction='row' justifyContent='space-between' width='100%'>
                        <h2 className={styles.title}>Фильтр</h2>
                        <button
                          type='button'
                          className={styles.reset}
                          onClick={handleButtonResetClick(close)}
                        >
                          {refreshText}
                        </button>
                      </Stack>
                      {children}
                      <Stack direction='row' gap={2.5}>
                        <Button
                          doesUseInFormOrTable
                          variant={ButtonVariants.Secondary}
                          onClick={handleButtonCancelClick(close)}
                        >
                          Закрыть
                        </Button>
                        <Button
                          doesUseInFormOrTable
                          variant={ButtonVariants.Popup}
                          onClick={handleButtonOkClick(close)}
                        >
                          Применить
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                </Popover>
              )}
            </Observer>
          );
        }}
      />
    );
  }
);

Filter.displayName = 'Filter';
