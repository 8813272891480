import { inject, injectable } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { ResolverResult } from 'react-hook-form';

import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';
import { CustomEvents } from '@/shared/api/EventEmitter/types';
import { RegistrableValues } from '@/shared/lib/types';
import { notify } from '@/shared/ui/Toast/notify';
import { JoyRideService } from '@/entities/JoyRideOnboarding/api/JoyRideService';

import { ApiService } from '@/shared/api/Api/services/ApiService';
import { Connect, GetBothSkeletonConnectRequest, SkeletonConnect } from '../types';
import { SkeletonConnectStore } from '../stores/SkeletonConnectStore';

@injectable()
export class SkeletonConnectService {
  constructor(
    private apiService: ApiService,
    private skeletonConnectStore: SkeletonConnectStore,
    private joyrideService: JoyRideService,
    @inject(RegistrableValues.ConnectorName) private connectName: string,
    @inject(RegistrableValues.ConnectorType) private connectType: string
  ) {
    makeAutoObservable(this);
  }

  get skeleton() {
    if (this.connectType === 'processor' || this.connectType === 'exProcessor') {
      // @ts-ignore
      return this.skeletonConnectStore.processor[this.connectName]
    }
    // @ts-ignore
    return this.skeletonConnectStore[this.connectType][this.connectName];
  }

  get isLoadingSkeleton() {
    return this.skeletonConnectStore.isLoadingSkeleton;
  }

  resolver(connect: Connect) {
    let result: ResolverResult = {
      values: connect,
      errors: {},
    };

    return {
      async on(callback: (prevResult: ResolverResult) => ResolverResult) {
        result = await callback(result);
        return this;
      },
      result() {
        return result;
      },
    };
  }

  async getSkeleton(connect: Connect) {
    const url = ['processor', 'exProcessor'].includes(this.connectType)
      ? `editor/processor/type/${this.connectName}`
      : `/editor/connect/type/${this.connectName}/${this.connectType}`;
    const { data } = await this.apiService.instance.post<SkeletonConnect>(url, connect);
    return data;
  }

  updateSkeleton(data: SkeletonConnect) {
    const target = ['processor', 'exProcessor'].includes(this.connectType)
      ? this.skeletonConnectStore.processor
      // @ts-ignore
      : this.skeletonConnectStore[this.connectType];
    target[this.connectName] = data;
  }

  async initSkeleton(connect: Connect) {
    this.skeletonConnectStore.isLoadingSkeleton = true;

    try {
      const data = await this.getSkeleton(connect);
      this.updateSkeleton(data);

      eventEmitter.emit(CustomEvents.GetConnectSkeleton, this.connectType, data);
    } catch {
      notify.error('Не удалось открыть форму');
    } finally {
      this.skeletonConnectStore.isLoadingSkeleton = false;
    }
  }

  async getSkeletonForBoth(connectName: string, data: GetBothSkeletonConnectRequest) {
    const response = await this.apiService.instance.post<
      any,
      AxiosResponse<SkeletonConnect>,
      GetBothSkeletonConnectRequest
    >(`/editor/api/v2/connect/type/${connectName}/both`, data);

    eventEmitter.emit(CustomEvents.GetConnectSkeleton, 'input', response.data);

    return response.data;
  }
}
