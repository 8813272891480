import { FC, useCallback } from 'react';

import { Observer, observer } from 'mobx-react-lite';
import { Link, useSearchParams } from 'react-router-dom';
import { container } from 'tsyringe';
import Stack from '@mui/system/Stack';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { SearchParams } from '@/hooks/useTemplateSearchParams';
import { CardWithActions, ListWithInfiniteScroll } from '@/shared/ui';
import { TenantStore } from '@/entities/Tenant/stores/TenantStore';

export interface TenantListProps {
  tenantId?: string;
}

const tenantStore = container.resolve(TenantStore);

export const TenantList: FC<TenantListProps> = observer(({ tenantId }) => {
  const [searchParams] = useSearchParams();

  const tenantsId = tenantStore.tenants?.ids;
  const tenantData = tenantStore.tenants?.entities;

  const getSearchParams = useCallback(
    (id: string) => {
      searchParams.set(SearchParams.TenantId, id);
      return searchParams.toString();
    },
    [searchParams]
  );

  const handleLinkClick = () => {};

  return (
    <ListWithInfiniteScroll
      animated
      elements={tenantsId || []}
      fetchNextElement={() => undefined}
      activeElementKey={tenantId}
      stopAnimatedForKey={tenantId}
      renderElement={(id) => {
        const type = tenantData[id].isActive === true ? 'success' : 'warning';
        const date = dayjs(tenantData[id].createDate).format('DD.MM.YYYY');

        return (
          <Observer key={id}>
            {() => (
              <Link
                to={{ search: getSearchParams(id) }}
                title={tenantData[id].name}
                onClick={handleLinkClick}
              >
                <CardWithActions
                  active={tenantId === id}
                  title={tenantData[id].name || 'Название отсутсвует'}
                  showIndicator
                  indicatorType={type}
                >
                  <Stack>
                    <Typography variant={'description' as any}>{date}</Typography>
                  </Stack>
                </CardWithActions>
              </Link>
            )}
          </Observer>
        );
      }}
    />
  );
});

TenantList.displayName = 'TenantList';
