import { FC } from 'react';

import Stack from '@mui/material/Stack';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { CreateServiceAppCard } from '@/features/UpsertExternalApp';
import { DeleteExternalAppDialog } from '@/features/DeleteExternalApp';
import { WSDLAppStore } from '@/entities/WSDLService/stores/WSDLAppStore';
import { WSDLAppCard } from '@/entities/WSDLService/entities/WSDLAppCard/WSDLAppCard';
import { UpdateWSDLAppButton } from '@/entities/WSDLService/features/actions/UpdateWSDLAppButton';
import { DeleteWSDLAppButton } from '@/entities/WSDLService/features/actions/DeleteWSDLAppButton';

const wsdlAppStore = container.resolve(WSDLAppStore);

export interface WSDLListProps {}

export const WSDLList: FC<WSDLListProps> = observer(() => {
  return (
    <>
      {wsdlAppStore.wsdlApps?.ids.map((id) => {
        const wsdlApp = wsdlAppStore.wsdlApps!.entities[id];

        return (
          <WSDLAppCard
            key={id}
            {...wsdlApp}
            actions={
              <Stack direction='row' justifyContent='space-between'>
                <UpdateWSDLAppButton wsdlAppId={id} />
                <DeleteWSDLAppButton wsdlAppId={id} />
              </Stack>
            }
          />
        );
      })}
      <CreateServiceAppCard service='wsdl' />
      <DeleteExternalAppDialog />
    </>
  );
});

WSDLList.displayName = 'WSDLList';
