import React, { FC, memo, useCallback, useLayoutEffect, useMemo, useState } from 'react';

import { NotificationSidebar } from '@/widgets/NotificationSidebar';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { UpsertNotificationForm } from '@/features/UpsertNotification';
import { useSearchParams } from 'react-router-dom';
import { SearchParams } from '@/hooks/useTemplateSearchParams';
import { SendNotification } from '@/features/SendNotification';
import { DeleteNotificationButton, DeleteNotificationDialog } from '@/features/DeleteNotification';
import { Loader } from '@/shared/ui/Loader/Loader';

export interface NotificationPageProps {}

export const NotificationPage: FC<NotificationPageProps> = memo(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showAddForm, setShowAddForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const notificationId = searchParams.get(SearchParams.NotificationId);
  const showUpdateForm = Boolean(notificationId);
  const showUpsertForm = showUpdateForm || showAddForm;

  const handleDialogSuccess = useCallback(() => {
    searchParams.delete(SearchParams.NotificationId);
    setSearchParams(searchParams);
    setShowAddForm(false);
  }, [searchParams, setSearchParams]);

  const form = useMemo(() => {
    if (isLoading) {
      return <Loader width={60} height={60} />;
    }
    if (showUpsertForm) {
      return (
        <UpsertNotificationForm
          key={notificationId}
          notificationId={notificationId}
          footer={
            notificationId ? (
              <>
                <DeleteNotificationButton />
                <SendNotification notificationId={notificationId} />
              </>
            ) : undefined
          }
          onSuccess={handleDialogSuccess}
        />
      );
    }

    return null;
  }, [handleDialogSuccess, isLoading, notificationId, showUpsertForm]);

  const handleSidebarAdd = useCallback(() => {
    setShowAddForm(true);
    searchParams.delete(SearchParams.NotificationId);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  useLayoutEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, [showUpdateForm, showAddForm, notificationId]);

  return (
    <Stack height='100%' direction='row' gap={3}>
      <NotificationSidebar
        selectedNotificationId={searchParams.get(SearchParams.NotificationId)}
        onAdd={handleSidebarAdd}
      />
      <Stack alignItems="stretch" flexGrow={1}>
        {form}
      </Stack>
      {notificationId && (
        <DeleteNotificationDialog notificationId={notificationId} onSuccess={handleDialogSuccess} />
      )}
    </Stack>
  );
});

NotificationPage.displayName = 'NotificationPage';
