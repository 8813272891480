import { FC, memo, useCallback } from 'react';

import { Box } from '@mui/system';
import { TooltipProps } from '@mui/material/Tooltip';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Tooltip from '@/shared/ui/Tooltip';
import { ACTIONS_SEARCH_PARAMS, SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';

export interface OpenMessageQueueProps {
  blockId: string;
  toolbarPlacement?: TooltipProps['placement'];
}

export const OpenMessageQueue: FC<OpenMessageQueueProps> = memo(
  ({ blockId, toolbarPlacement = 'left' }) => {
    const { has, set, remove } = useSearchParamsTemplate();
    const isOpen = has(SearchParams.ShowMessageQueue);

    const handleClick = useCallback(() => {
      remove(ACTIONS_SEARCH_PARAMS);
      if (isOpen) return;
      set([SearchParams.ShowMessageQueue, { [SearchParams.BlockId]: blockId }]);
    }, [blockId, set]);

    eventEmitter.on(`OpenMessageQueue${blockId}`, handleClick);

    return (
      <Tooltip title='Очередь сообщений' placement={toolbarPlacement} disableInteractive>
        <Box height='max-content'>
          <Button
            tabIndex={1}
            circle
            size={ButtonSizes.Small}
            variant={ButtonVariants.Outlined}
            onClick={handleClick}
          >
            <AutoAwesomeMotionIcon />
          </Button>
        </Box>
      </Tooltip>
    );
  }
);

OpenMessageQueue.displayName = 'OpenMessageQueue';
