import { singleton } from 'tsyringe';
import { makeAutoObservable, runInAction } from 'mobx';
import { normalize } from '@/shared/lib/normalize';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { notify } from '@/shared/ui/Toast/notify';
import _ from 'lodash';

import {
  Block,
  IntegrationTest,
  IntegrationTestCheck,
  IntegrationTestImport,
  IntegrationTestRequest,
  IntegrationTestResponse,
  IntegrationTestResult,
} from '../types';
import { BlockTestingStore } from '../stores/BlockTestingStore';

@singleton()
export class BlockTestingService {
  constructor(private blockTestingStore: BlockTestingStore, private apiService: ApiService) {
    makeAutoObservable(this);
  }

  get tests() {
    return this.blockTestingStore.tests;
  }

  get testChecks() {
    return this.blockTestingStore.testsResult;
  }

  get isLoadingTests() {
    return this.blockTestingStore.isLoadingTests;
  }

  get isLoadingRunTests() {
    return this.blockTestingStore.isLoadingRunTests;
  }

  get isLoadingRunTest() {
    return this.blockTestingStore.isLoadingRunTest;
  }

  get isLoadingImportTests() {
    return this.blockTestingStore.isLoadingImportTests;
  }

  get isLoadingDeleteTest() {
    return this.blockTestingStore.isLoadingDeleteTest;
  }

  get isLoadingSaveTest() {
    return this.blockTestingStore.isLoadingSaveTest;
  }

  get isLoadingTestResult() {
    return this.blockTestingStore.isLoadingTestResult;
  }

  get isError() {
    return this.blockTestingStore.isError;
  }

  get totalPages() {
    return this.blockTestingStore.totalPages;
  }

  async exportAllTests(blockName: string, blockId: string) {
    await this.getTestsByBlockId(blockId, {
      pagination: { page: 0, size: this.blockTestingStore.totalElements },
    });

    const tests = Object.values(this.blockTestingStore.tests.entities);

    const text = JSON.stringify(tests, null, 2);

    const blob = new Blob([text], { type: 'application/json' });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');

    a.href = url;
    a.download = `Тесты блока ${blockName}`;
    a.click();
  }

  exportTest(testId: string) {
    const test = this.blockTestingStore.tests.entities[testId];

    const text = JSON.stringify(test, null, 2);

    const blob = new Blob([text], { type: 'application/json' });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');

    a.href = url;
    a.download = `Тест ${test.name || ''}`;
    a.click();
  }

  resetTests() {
    this.blockTestingStore.tests = { ids: [], entities: {} };
    this.blockTestingStore.testsResult = {};
  }

  async getTestsByBlockId(blockId: string, data: IntegrationTestRequest) {
    runInAction(() => {
      this.blockTestingStore.isLoadingTests = true;
      this.blockTestingStore.isError = false;
    });

    try {
      const response = await this.apiService.instance.post<IntegrationTestResponse>(
        `/editor/integrationTest/byBlockId/${blockId}`,
        data
      );

      const normalizedTests = normalize(response.data.integrationTestPage.content, 'id');

      runInAction(() => {
        this.blockTestingStore.totalPages = response.data.integrationTestPage.totalPages;
        this.blockTestingStore.totalElements = response.data.integrationTestPage.totalElements;

        this.blockTestingStore.tests.ids = _.uniq([
          ...this.blockTestingStore.tests.ids,
          ...normalizedTests.ids,
        ]);

        this.blockTestingStore.tests.entities = {
          ...this.blockTestingStore.tests.entities,
          ...normalizedTests.entities,
        };
      });
    } catch {
      runInAction(() => {
        this.blockTestingStore.isError = true;
      });
      notify.error('Не удалось получить интеграционные тесты');
    } finally {
      runInAction(() => {
        this.blockTestingStore.isLoadingTests = false;
      });
    }
  }

  async runTests(block: Block) {
    this.blockTestingStore.isLoadingRunTests = true;
    this.blockTestingStore.isError = false;

    try {
      const testsResult = await this.apiService.instance.post<IntegrationTestResult[]>(
        `/editor/integrationTest/checkAll`,
        block
      );

      const test = await this.apiService.instance.post<IntegrationTestResponse>(
        `/editor/integrationTest/byBlockId/${block.id}`,
        {
          pagination: { page: 0, size: this.blockTestingStore.tests.ids.length },
        }
      );

      this.blockTestingStore.tests = normalize(test.data.integrationTestPage.content, 'id');

      runInAction(() => {
        testsResult.data.forEach((testResult) => {
          this.blockTestingStore.testsResult[testResult.id] = testResult;
        });
      });
    } catch {
      this.blockTestingStore.isError = true;
      notify.error('Не удалось запустить интеграционные тесты');
    } finally {
      this.blockTestingStore.isLoadingRunTests = false;
    }
  }

  async runTest(integrationTestCheck: IntegrationTestCheck) {
    this.blockTestingStore.isLoadingRunTest = true;
    this.blockTestingStore.isError = false;

    try {
      const testResult = await this.apiService.instance.post<IntegrationTestResult>(
        `/editor/integrationTest/check`,
        integrationTestCheck
      );

      const test = await this.apiService.instance.get<IntegrationTest>(
        `/editor/integrationTest/${integrationTestCheck.integrationTest.id}`
      );

      this.blockTestingStore.tests.entities[test.data.id] = test.data;
      this.blockTestingStore.testsResult[testResult.data.integrationTestId] = testResult.data;

      if (test.data.lastResult) {
        notify.success('Тест пройден');
      } else {
        notify.error('Тест провален');
      }
    } catch {
      this.blockTestingStore.isError = true;
      notify.error('Не удалось запустить интеграционный тест');
    } finally {
      this.blockTestingStore.isLoadingRunTest = false;
    }
  }

  async importAllTests(integrationTestImport: IntegrationTestImport) {
    this.blockTestingStore.isLoadingImportTests = true;
    this.blockTestingStore.isError = false;

    try {
      const response = await this.apiService.instance.post<IntegrationTest[]>(
        `/editor/integrationTest/import`,
        integrationTestImport
      );

      this.blockTestingStore.tests = normalize(
        [...Object.values(this.blockTestingStore.tests.entities), ...response.data],
        'id'
      );
    } catch {
      this.blockTestingStore.isError = true;
      notify.error('Не удалось импортировать тесты');
    } finally {
      this.blockTestingStore.isLoadingImportTests = false;
    }
  }

  async deleteTest(testId: string) {
    runInAction(() => {
      this.blockTestingStore.isLoadingDeleteTest = true;
      this.blockTestingStore.isError = false;
    });

    try {
      await this.apiService.instance.delete(`/editor/integrationTest/${testId}`);

      runInAction(() => {
        delete this.blockTestingStore.tests.entities[testId];
        this.blockTestingStore.tests.ids.splice(
          this.blockTestingStore.tests.ids.indexOf(testId),
          1
        );
      });
    } catch {
      runInAction(() => {
        this.blockTestingStore.isError = true;
      });
      notify.error('Не удалось удалить тест');
    } finally {
      runInAction(() => {
        this.blockTestingStore.isLoadingDeleteTest = false;
      });
    }
  }

  async saveTest(test: IntegrationTest) {
    runInAction(() => {
      this.blockTestingStore.isLoadingSaveTest = true;
      this.blockTestingStore.isError = false;
    });

    try {
      const response = await this.apiService.instance.put<IntegrationTest>(
        `/editor/integrationTest/save`,
        test
      );

      runInAction(() => {
        if (!this.blockTestingStore.tests.entities[response.data.id]) {
          this.blockTestingStore.tests.ids.unshift(response.data.id);
        }

        this.blockTestingStore.tests.entities[response.data.id] = response.data;
      });
    } catch {
      runInAction(() => {
        this.blockTestingStore.isError = true;
      });
      notify.error('Не удалось сохранить тест');
    } finally {
      runInAction(() => {
        this.blockTestingStore.isLoadingSaveTest = false;
      });
    }
  }

  async getTestResultById(testId: string) {
    runInAction(() => {
      this.blockTestingStore.isLoadingTestResult = true;
      this.blockTestingStore.isError = false;
    });

    try {
      const response = await this.apiService.instance.get<IntegrationTestResult>(
        `/editor/integrationTestResult/findByIntegrationTestId/${testId}`
      );

      runInAction(() => {
        this.blockTestingStore.testsResult[testId] = response.data;
      });
    } catch {
      runInAction(() => {
        this.blockTestingStore.isError = true;
      });
      notify.error('Не удалось получить результаты теста');
    } finally {
      runInAction(() => {
        this.blockTestingStore.isLoadingTestResult = false;
      });
    }
  }
}
