import { FC } from 'react';

import { observer } from 'mobx-react-lite';

import { useDropzone } from "react-dropzone";
import { DEFAULT_MAX_FILE_SIZE_IN_BYTES } from "@/shared/lib/constants";
import { notify } from "@/shared/ui/Toast/notify";
import { transformBytesToMb } from "@/shared/lib/transformBytesToMb";

import styles from './FileField.module.scss';

export interface UploadFieldProps {
  onDrop(files: File[]): void;
}

export const UploadField: FC<UploadFieldProps> = observer(({
  onDrop,
  }) => {

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    onDrop,
    onDropRejected(fileRejections) {
      if (fileRejections.length === 1) {
        notify.error(
          `Файл не должен превышать ${transformBytesToMb(DEFAULT_MAX_FILE_SIZE_IN_BYTES)} Mb`
        );
      }
    },
  });


    return (
      <div className={styles['drop-zone']} {...getRootProps()}>
        <p className={styles.description}>Переместите или выберите файл</p>
        <input {...getInputProps()} />
      </div>
    );
  },
);

UploadField.displayName = 'UploadField';
