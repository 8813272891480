import { FC, useLayoutEffect } from 'react';

import { ReactFlowProvider } from 'reactflow';
import { observer } from 'mobx-react-lite';
import { AnimatePresence } from 'framer-motion';
import { container } from 'tsyringe';
import { Box, Stack } from '@mui/system';
import { useParams } from 'react-router-dom';
import { LoaderIcon } from '@/shared/ui/Icons/LoaderIcon/LoaderIcon';
import { ConnectDrawerContainer } from '@/entities/Connect/containers/ConnectDrawerContainer';
import { BlockDrawerContainer } from '@/entities/Block/containers/BlockDrawerContainer';
import { SendMessagePage } from '@/pages';
import { MessageTracing } from '@/widgets';
import { BlockTestingPage } from '@/entities/Block/containers/BlockTesting/pages/BlockTestingPage';
import {
  DeleteFlowModal,
  DeleteNodeModal,
  FlowFormDrawer,
  TFlowFormDrawer,
} from '@/entities/Flow/features';
import { MessageQueuePage } from '@/entities/Block/containers/MessageQueue/pages';
import { MessageDeliverySettingsContainer } from '@/entities/Block/containers/MessageDeliverySettingsContainer';
import { LogSettingsContainer } from '@/entities/Block/containers/LogSettingsContainer';
import { DeleteConnectModalContainers } from '@/entities/Connect/containers/DeleteConnectModalContainers';
import { CreatingTwoConnectsDrawerContainer } from '@/entities/Connect/containers/CreatingTwoConnectsDrawerContainer';
import { UnsavedChangesModal } from '@/entities/UnsavedChanges';
import { UnsavedFlowChangesStore } from '@/entities/UnsavedChanges/model/UnsavedFlowChangesStore';
import { FlowUpdaterService } from '@/entities/Flow/services/FlowUpdaterService';
import { RegistrableValues } from '@/shared/lib/types';
import { FlowWorkspace } from '@/entities/Flow/widgets/FlowWorkspace/FlowWorkspace';
import { SaveVersionForm } from '@/features/SaveVersion/ui/SaveVersionForm';
import { FlowVersionListByFlow } from '@/widgets/FlowVersionListByFlow';
import { CloneFlowDialog } from '@/features/CloneFlow';
import { CloseOnboarding } from '@/modules/JoyRideOnboarding/features/CloseOnboarding';
import { DublicateVersionDialog } from '@/features/DublicateFlow/ui/DublicateVersionDialog';
import { ExecutorLogBlockPage } from '@/pages/ExecutorLogBlockPage';

import { FlowService } from '../services/FlowService';
import { BlockDeletionModalContainer } from '../../Block/containers/BlockDeletionModalContainer';
import { DiffFlowDrawerContainer } from '@/entities/Flow/containers/DiffFlow/DiffFlowDrawerContainer';
import { ClearLogsModal } from '@/entities/Flow/features/ClearLogsModal';
import { SettingsDrawer } from "@/features/UploadFiles/ui/SettingsDrawer";

const flowService = container.resolve(FlowService);
const unsavedFlowChangesStore = container.resolve(UnsavedFlowChangesStore);

export const FlowContent: FC = observer(() => {
  const { flowId } = useParams();

  container.register(RegistrableValues.FlowId, { useValue: flowId || '' });
  const flowUpdaterService = container.resolve(FlowUpdaterService);

  useLayoutEffect(() => {
    if (!flowId) return;
    flowService.getFlow(flowId);
  }, [flowId]);

  if (flowService.isLoadingFlow)
    return (
      <Box display='grid' height='calc(100vh - 96px)'>
        <Stack alignItems='center' justifyContent='center'>
          <LoaderIcon width={24} height={24} />
        </Stack>
      </Box>
    );

  return (
    <AnimatePresence key={2} mode='wait'>
      <Box
        display='grid'
        gridTemplateColumns='1fr max-content'
        position='relative'
        height='calc(100vh - 96px)'
      >
        <Box zIndex={1000}>
          {flowId && (
            <ReactFlowProvider>
              <FlowWorkspace />
            </ReactFlowProvider>
          )}
        </Box>
        <Box
          position='absolute'
          display='grid'
          gridTemplateColumns='1fr max-content'
          width='100%'
          height='100%'
        >
          <div>
            <ConnectDrawerContainer />
            <MessageDeliverySettingsContainer />
            <LogSettingsContainer />
            <CreatingTwoConnectsDrawerContainer />
          </div>
          <Stack display='grid' gridTemplateColumns='max-content max-content'>
            <BlockDrawerContainer />
            <DiffFlowDrawerContainer />
            <SettingsDrawer />
          </Stack>
        </Box>
        <DeleteConnectModalContainers />
        <BlockDeletionModalContainer />
        <DeleteFlowModal />
        <CloseOnboarding />
        <MessageTracing />
        <FlowFormDrawer />
        <SaveVersionForm />
        <ClearLogsModal />
        <FlowVersionListByFlow />
        <CloneFlowDialog />
        <DeleteNodeModal />
        <TFlowFormDrawer />
      </Box>
      <SendMessagePage />
      <ExecutorLogBlockPage />
      <BlockTestingPage />
      <MessageQueuePage />
      <UnsavedChangesModal
        title='Есть несохранненные данные в потоке'
        text='Сохранить изменения в потоке перед переходом?'
        store={unsavedFlowChangesStore}
        onChangeSearchParams={(cur, next) => {
          const curMode = new URLSearchParams(cur).get('mode');
          const nextMode = new URLSearchParams(next).get('mode');
          return curMode === nextMode;
        }}
        onSave={() => flowUpdaterService.saveFlow()}
        onDecline={() => {
          flowService.getFlow(flowId);
        }}
      />
      <DublicateVersionDialog />
      {/**
       * TODO
       * Доделать компонент нотификации о изменениях
       * see UnsavedConnectorNotification
       */}
    </AnimatePresence>
  );
});
