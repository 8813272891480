import { Node } from 'reactflow';
import { MessageNodeProps } from '@/entities/MessageTracing';

import { TracingMessageModel } from '../model/TracingMessageModel';

export function getNodes(store: TracingMessageModel): Node<MessageNodeProps>[] {
  // fixme full (_!_)
  return (
    store.exchangeList?.ids.map((id, index) => {
      if (store.exchangeList!.entities[id].type === "start") {
        return {
          id,
          data: {
            id,
            processorName: `Входящее сообщение ${store.exchangeList?.entities[id].inputConnect?.name || ''}`,
            value: store.exchangeList!.entities[id].inputBody?.outputBody || '',
            headers: store.exchangeList!.entities[id].inputHeaders,
            pathParams: store.exchangeList!.entities[id].inputPathParams,
            queryParams: store.exchangeList!.entities[id].inputQueryParams,
            processorType: store.exchangeList?.entities[id].inputConnect?.typeConnect || '',
            exception: store.exchangeList!.entities[id].exception,
            stackTrace: store.exchangeList!.entities[id].stackTrace,
            messageId: '',
            dateExecute: store.exchangeList!.entities[id].timeStart ? new Date(store.exchangeList!.entities[id].timeStart).toISOString() : undefined,
            timeExecute: store.exchangeList!.entities[id].timeExecute
          },
          position: {
            x: store.canvas?.entities[id]?.left || 0,
            y: store.canvas?.entities[id]?.top || 0,
          },
          style: {
            width: store.canvas!.entities[id]?.sx?.width || 420,
            height: store.canvas!.entities[id]?.sx?.height || 420,
            display: 'flex',
          },
          type: 'messageNode',
        };
      }
      if (store.exchangeList!.entities[id].type === "work") {
        return {
          id,
          data: {
            id,
            processorName: store.exchangeList?.entities[id].processor?.name || '',
            processorType:
                store.exchangeList!.entities[id].processor?.typeProcessor || '',
            value: store.exchangeList!.entities[id].body?.outputBody || '',
            headers: store.exchangeList!.entities[id].headers,
            pathParams: store.exchangeList!.entities[id].inputPathParams,
            queryParams: store.exchangeList!.entities[id].inputQueryParams,
            exception: store.exchangeList!.entities[id].exception,
            stackTrace: store.exchangeList!.entities[id].stackTrace,
            messageId: store.exchangeList?.entities[id]?.headers?.messageId || '',
            dateExecute: store.exchangeList!.entities[id].timeStart ? new Date(store.exchangeList!.entities[id].timeStart).toISOString() : undefined,
            timeExecute: store.exchangeList!.entities[id].timeExecute
          },
          position: {
            x: store.canvas?.entities[id].left || 0,
            y: store.canvas?.entities[id].top || 0,
          },
          style: {
            width: store.canvas!.entities[id]?.sx?.width || 420,
            height: store.canvas!.entities[id]?.sx?.height || 420,
            display: 'flex',
          },
          type: 'messageNode',
        };
      }
      if (store.exchangeList!.entities[id].type === "finish") {
        return {
          id,
          data: {
            id,
            processorName: 'Исходящее сообщение',
            processorType: '',
            value: store.exchangeList!.entities[id].body?.outputBody || '',
            headers: store.exchangeList!.entities[id].outputHeaders,
            pathParams: store.exchangeList!.entities[id].inputPathParams,
            queryParams: store.exchangeList!.entities[id].inputQueryParams,
            exception: store.exchangeList!.entities[id].exception,
            stackTrace: store.exchangeList!.entities[id].stackTrace,
            messageId: '',
            dateExecute: store.exchangeList!.entities[id].timeStart ? new Date(store.exchangeList!.entities[id].timeStart).toISOString() : undefined,
            timeExecute: store.exchangeList!.entities[id].timeExecute
          },
          position: {
            x: store.canvas?.entities[id].left || 0,
            y: store.canvas?.entities[id].top || 0,
          },
          style: {
            width: store.canvas!.entities[id]?.sx?.width || 420,
            height: store.canvas!.entities[id]?.sx?.height || 420,
            display: 'flex',
          },
          type: 'messageNode',
        };
      }
      return {
        id,
        data: {
          id,
          processorName: store.exchangeList?.entities[id].processor?.name || '',
          processorType:
              store.exchangeList!.entities[id].processor?.typeProcessor || '',
          value: store.exchangeList!.entities[id].body?.outputBody || '',
          headers: store.exchangeList!.entities[id].headers,
          pathParams: store.exchangeList!.entities[id].inputPathParams,
          queryParams: store.exchangeList!.entities[id].inputQueryParams,
          exception: store.exchangeList!.entities[id].exception,
          stackTrace: store.exchangeList!.entities[id].stackTrace,
          messageId: store.exchangeList?.entities[id]?.headers?.messageId || '',
          dateExecute: store.exchangeList!.entities[id].timeStart ? new Date(store.exchangeList!.entities[id].timeStart).toISOString() : undefined,
          timeExecute: store.exchangeList!.entities[id].timeExecute
        },
        position: {
          x: store.canvas?.entities[id].left || 0,
          y: store.canvas?.entities[id].top || 0,
        },
        style: {
          width: store.canvas!.entities[id]?.sx?.width || 420,
          height: store.canvas!.entities[id]?.sx?.height || 420,
          display: 'flex',
        },
        type: 'messageNode',
      };
    }) || []
  );
}
