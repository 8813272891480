export const arrayType = [
  {
    value: 'string',
    label: 'Строка',
  },
  {
    value: 'boolean',
    label: 'Логическая переменная',
  },
  {
    value: 'numberOld',
    label: 'Число[Старая реализация]',
  },
  {
    value: 'number',
    label: 'Число',
  },
  {
    value: 'float',
    label: 'Вещественное число',
  },
  {
    value: 'date',
    label: 'Дата',
  },
  {
    value: 'object',
    label: 'Объект',
  },
  {
    value: 'array',
    label: 'Массив',
  },
  {
    value: 'arrayToObject',
    label: 'Массив в объект',
  },
];

export const arrayDateInputType = [
  {
    value: 'auto',
    label: 'Автоматически',
  },
  {
    value: 'unix',
    label: 'Миллисекунды(Unix)',
  },
  {
    value: 'second',
    label: 'Секунды(Unix)',
  },
  {
    value: 'stringDate',
    label: 'Строка',
  },
];

export const arrayDateOutputType = [
  {
    value: 'unix',
    label: 'Миллисекунды(Unix)',
  },
  {
    value: 'second',
    label: 'Секунды(Unix)',
  },
  {
    value: 'stringDate',
    label: 'Строка',
  },
];

export const arrayStatusObject = [
  {
    value: 'REMOVE',
    label: 'Исключить',
  },
  {
    value: 'OK',
    label: 'Нефиксированное добавление',
  },
  {
    value: 'OK_REMOVE_NEW',
    label: 'Фиксированное добавление',
  },
];

export const arrayStatusByObject = [
  {
    value: 'REMOVE',
    label: 'Исключить',
  },
  {
    value: 'OK',
    label: 'Скопировать объект',
  },
  {
    value: 'OK_REMOVE_NEW',
    label: 'Заполнить объект',
  },
];

export const arrayStatusByObjectByArray = [
  {
    value: 'OK',
    label: 'Скопировать объект',
  },
  {
    value: 'OK_REMOVE_NEW',
    label: 'Заполнить объект',
  },
];

export const arrayStatus = [
  {
    value: 'REMOVE',
    label: 'Исключить',
  },
  {
    value: 'OK',
    label: 'Скопировать значение',
  },
];

export const arrayEmptyRuleByArray = [
  {
    value: 'toNull',
    label: 'Выставить null',
  },
  {
    value: 'delete',
    label: 'Удалить массив',
  },
  {
    value: 'save',
    label: 'Оставить пустой массив',
  },
];

export const arrayEmptyRuleObject = [
  {
    value: 'toNull',
    label: 'Выставить null',
  },
  {
    value: 'delete',
    label: 'Удалить переменную',
  },
  {
    value: 'save',
    label: 'Оставить пустой объект',
  },
];

export const arrayEmptyRule = [
  {
    value: 'save',
    label: 'Сохранить пустым',
  },
  {
    value: 'toNull',
    label: 'Выставить null',
  },
  {
    value: 'delete',
    label: 'Удалить переменную',
  },
];

export const dateStringVariants = [
  "yyyy-MM-dd'T'HH:mm:ss.nnnnnnnnn",
  "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'",
  "yyyy-MM-dd'T'HH:mm:ss.SSSSSS",
  "yyyy-MM-dd'T'HH:mm:ss.SSSXXX",
  "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
  "yyyy-MM-dd'T'HH:mm:ss.SSS",
  "yyyy-MM-dd'T'HH:mm:ssXXX",
  "yyyy-MM-dd'T'HH:mm:ss'Z'",
  "yyyy-MM-dd'T'HH:mm:ss",
  'yyyy-MM-dd HH:mm:ss.nnnnnnnnn',
  'yyyy-MM-dd HH:mm:ss.SSSSSS',
  'yyyy-MM-dd HH:mm:ss.SSSXXX',
  "yyyy-MM-dd HH:mm:ss.SSS'Z'",
  'yyyy-MM-dd HH:mm:ss.SSS',
  'yyyy-MM-dd HH:mm:ssXXX',
  "yyyy-MM-dd HH:mm:ss'Z'",
  'yyyy-MM-dd HH:mm:ss',
  'dd/MM/yyyy HH:mm:ss',
  'EEE, dd MMM yyyy HH:mm:ss zzz',
  "EEE, d MMM yyyy HH:mm:ss 'Z'",
  'yyyy-MM-dd',
  'MM/dd/yyyy',
  'dd/MM/yyyy',
  'dd.MM.yyyy',
  'dd-MM-yyyy',
];

export const appendObject = {
  targetName: '',
  sourceName: '',
  type: 'string',
  status: 'OK',
  emptyRule: '',
};
