import React, { FC, memo, MouseEvent, useCallback, useState } from 'react';

import { Controller } from 'react-hook-form';

import { FormControlLabel, Popover, Radio } from '@mui/material';
import { styled } from '@mui/system';

import { FieldProps } from '@/shared/ui/Fields/Fields';
import { Label } from '@/shared/ui/Label/Label';
import Tooltip from '@/shared/ui/Tooltip';
import { RadioButtonTypeAssociation } from '@/shared/ui/Fields/components/FieldRadioGroup/type';
import {Parameter} from "@/entities/Connect/types";

export interface FieldRadioGroupProps extends FieldProps {
  groups?: Array<Parameter>;
  id?: string;
  onChange?: () => void;
  disabled?: boolean;
  isVisible?: boolean;
}

const CustomRadio = styled(Radio)(() => ({
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
}));

export const FieldRadioGroup: FC<FieldRadioGroupProps> = memo(
  ({ label, groups, name, control, isRequired, onChange, defaultValue }) => {
    const [selectedValue, setSelectedValue] = useState<RadioButtonTypeAssociation | null>(
      defaultValue
    );

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const id = open ? 'image-popover' : undefined;

    const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = () => {
      setAnchorEl(null);
    };

    const ImageRadioButton: FC<RadioButtonTypeAssociation> = useCallback(
      ({ value, src }) => (
        <img
          src={src}
          alt={value}
          style={{
            cursor: 'pointer',
            width: '100px',
            padding: '5px',
            borderRadius: '8px',
            backgroundColor: `var(--primary-color)`,
          }}
        />
      ),
      []
    );

    return (
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue.value}
        render={({ field }) => {
          const { src } = groups.find(
            (item: Parameter) => item.value === field.value
          );
          return (
            <div>
              <Label>{label}</Label>
              <FormControlLabel
                key={field.value}
                control={
                  <CustomRadio
                    {...field}
                    required={isRequired}
                    value={field.value}
                    onClick={(event) => handleClick(event)}
                    icon={<ImageRadioButton value={field.value} src={src} />}
                    checkedIcon={<ImageRadioButton value={field.value} src={src} />}
                  />
                }
                label=''
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: `var(--primary-color)`,
                  }}
                >
                  {groups.map((option: any) => (
                    <Tooltip title={option.value} key={option.value}>
                      <CustomRadio
                        value={option.value}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                          if (onChange) {
                            setSelectedValue({
                              value: event.target.value,
                              src: option.src,
                            });
                            field.onChange(event.target.value);
                            setAnchorEl(null);
                          }
                        }}
                        icon={<ImageRadioButton value={option.value} src={option.src} />}
                        checkedIcon={
                          <ImageRadioButton
                            value={selectedValue?.value}
                            src={selectedValue?.src}
                          />
                        }
                      />
                    </Tooltip>
                  ))}
                </div>
              </Popover>
            </div>
          );
        }}
      />
    );
  }
);
