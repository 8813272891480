import { FC } from 'react';

import Stack from '@mui/material/Stack';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { CreateServiceAppCard } from '@/features/UpsertExternalApp';
import { DeleteExternalAppDialog } from '@/features/DeleteExternalApp';
import { OpenApiAppStore } from '@/entities/OpenApi/stores/OpenApiAppStore';
import { OpenApiAppCard } from '@/entities/OpenApi/entities/OpenApiAppCard/OpenApiAppCard';
import { UpdateOpenAppButton } from '@/entities/OpenApi/features/actions/UpdateOpenApiAppButton';
import { DeleteOpenAppButton } from '@/entities/OpenApi/features/actions/DeleteOpenApiAppButton';

const openApiAppStore = container.resolve(OpenApiAppStore);

export interface OpenApiListProps {}

export const OpenApiList: FC<OpenApiListProps> = observer(() => {
  return (
    <>
      {openApiAppStore.openApiApps?.ids.map((id) => {
        const openApiApp = openApiAppStore.openApiApps!.entities[id];

        return (
          <OpenApiAppCard
            key={id}
            {...openApiApp}
            actions={
              <Stack direction='row' justifyContent='space-between'>
                <UpdateOpenAppButton openAppId={id} />
                <DeleteOpenAppButton openAppId={id} />
              </Stack>
            }
          />
        );
      })}
      <CreateServiceAppCard service='openApi' />
      <DeleteExternalAppDialog />
    </>
  );
});

OpenApiList.displayName = 'OpenApiList';
