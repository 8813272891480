import { FC, memo, MouseEvent, useCallback } from 'react';

import cn from 'classnames';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Tooltip from '@/shared/ui/Tooltip';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import { ACTIONS_SEARCH_PARAMS, SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import getSPKeys from '@/entities/Flow/features/Actions/getSPKeys';

export interface DeleteFlowProps {
  flowId: string;
}

export const DeleteFlow: FC<DeleteFlowProps> = memo(({ flowId }) => {
  const { has, set, get, remove } = useSearchParamsTemplate();
  const mode = get('mode');
  const keyMap = getSPKeys(mode);
  const active = has(keyMap.delete);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      remove(ACTIONS_SEARCH_PARAMS);
      set([keyMap.delete, { [SearchParams.FlowId]: flowId }]);
    },
    [flowId, set]
  );

  return (
    <Tooltip title='Удалить поток' placement='top'>
      <span>
        <Button
          className={cn({ active })}
          circle
          size={ButtonSizes.Small}
          variant={ButtonVariants.Outlined}
          onClick={handleClick}
        >
          <TrashIcon />
        </Button>
      </span>
    </Tooltip>
  );
});

DeleteFlow.displayName = 'DeleteFlow';
