import React from 'react';
import PropTypes from 'prop-types';


const mapLex = {
  d: ['дней', 'день', 'дня'],
  h: ['часов', 'час', 'часа'],
  m: ['минут', 'минута', 'минуты'],
  s: ['секунд', 'секунда', 'секунды'],
};

const formatByType = (type, val) => {
  const lexem = mapLex[type];
  if (!val) return null;
  if (val > 10 && val < 15) return `${val} ${lexem[0]}`;
  if (val % 10 === 1) return `${val}  ${lexem[1]}`;
  if (val % 10 > 1 && val % 10 < 5) return `${val}  ${lexem[2]}`;
  return `${val}  ${lexem[0]}`;
};

function TypographySecToText({ time }) {
  const parseDate = {}
  let newTime = time / 1.0;
  parseDate.s = newTime % 60;
  newTime = (newTime - parseDate.s) / 60;
  parseDate.m = newTime % 60;
  newTime = (newTime - parseDate.m) / 60;
  parseDate.h = newTime % 24;
  newTime = (newTime - parseDate.h) / 24;
  parseDate.d = newTime;

  const chain = ['d', 'h', 'm', 's'].map((type) => formatByType(type, parseDate[type]));

  return <span>{chain.join(' ')}</span>;
}

TypographySecToText.propTypes = {
  time: PropTypes.number
};

export default TypographySecToText;