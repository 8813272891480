import React, { FC, memo, useCallback, useContext, useState } from 'react';

import Grid from '@mui/system/Unstable_Grid';
import Stack from '@mui/system/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import { Controller, useFieldArray } from 'react-hook-form';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Box } from '@mui/system';

import { MapArray } from '@/shared/ui/Fields/components/MappingData/MapArray';
import { FieldSelect } from '@/shared/ui/Fields/components/FieldSelect/FieldSelect';
import {
  appendObject,
  arrayEmptyRule,
  arrayStatusByObject,
  arrayType,
} from '@/shared/ui/Fields/components/MappingData/constants';
import { MapPrimitives } from '@/shared/ui/Fields/components/MappingData/MapPrimitives';
import { DraggableItem, DraggableList } from '@/shared/ui/DraggableList';
import { amendDraggable } from '@/shared/lib/extendDndkit';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { PlusIcon } from '@/shared/ui/Icons/PlusIcon/PlusIcon';
import Button from '@/shared/ui/Button';
import { MapDate } from '@/shared/ui/Fields/components/MappingData/MapDate';
import Tooltip from '@/shared/ui/Tooltip';
import { Summary } from '@/shared/ui/Fields/components/MappingData/ui/Summary/Summary';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';

import { FieldInput } from '../FieldInput/FieldInput';
import { CopyPasteContext } from './lib/CopyPasteContext';
import { MapArrayToObject } from '@/shared/ui/Fields/components/MappingData/MapArrayToObject';

export interface MapObjectProps {
  control: any;
  name: string;
  register: any;
  draggable?: boolean;
}

export const MapObject: FC<MapObjectProps> = memo(
  ({ control, name, register, draggable = false }) => {
    const [expanded, setExpanded] = useState<string | false>(false);

    const { fields, append, remove, move } = useFieldArray({
      name,
      control,
    });

    const { copiedValue, setCopiedValue } = useContext(CopyPasteContext);

    const form = useCallback(
      (index: number, type: string) => {
        switch (type) {
          case 'string':
          case 'number':
          case 'boolean':
          case 'float': {
            return <MapPrimitives name={name} control={control} index={index} />;
          }
          case 'array': {
            return (
              <MapArray
                control={control}
                name={name}
                index={index}
                register={register}
                draggable={draggable}
              />
            );
          }
          case 'object': {
            return (
              <MapObject
                control={control}
                name={`${name}.${index}.children`}
                register={register}
                draggable={draggable}
              />
            );
          }
          case 'date': {
            return <MapDate control={control} index={index} name={name} register={register} />;
          }
          case 'arrayToObject': {
            return (
              <MapArrayToObject control={control} index={index} name={name} register={register} />
            );
          }
          default: {
            return null;
          }
        }
      },
      [control, name, register, draggable]
    );

    const handleAccordionChange =
      (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
      };
    const renderItem = ({ id }: { id: string }, index: number) => (
      <DraggableItem key={id} id={id} draggable={draggable}>
        <Accordion expanded={expanded === id} onChange={handleAccordionChange(id)} disableGutters>
          <AccordionSummary expandIcon={<ArrowRightIcon />} sx={{ width: '100%' }}>
            <Stack width='100%' direction='row' gap={2.5} alignItems='center'>
              <Summary control={control} name={`${name}.${index}`} />
            </Stack>
            <Button
              circle
              size={ButtonSizes.Small}
              variant={ButtonVariants.Outlined}
              onClick={() => remove(index)}
              onMouseDown={(event) => event.stopPropagation()}
            >
              <TrashIcon />
            </Button>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container spacing={2.5} marginLeft={2} alignItems='flex-end'>
              <Grid xs={12} sm={6} lg={4} xl={3} {...amendDraggable(true)}>
                <FieldInput
                  label='Название свойства'
                  key={id}
                  error=''
                  name={`${name}.${index}.targetName`}
                  register={register}
                />
              </Grid>
              <Grid xs={12} sm={6} lg={4} xl={3} {...amendDraggable(true)}>
                <FieldInput
                  label='Значение'
                  key={id}
                  error=''
                  name={`${name}.${index}.sourceName`}
                  register={register}
                />
              </Grid>
              <Grid xs={12} sm={6} lg={4} xl={3} {...amendDraggable(true)}>
                <FieldSelect
                  label='Тип данных'
                  control={control}
                  options={arrayType}
                  error=''
                  name={`${name}.${index}.type`}
                />
              </Grid>
              <Controller
                control={control}
                name={`${name}.${index}.type`}
                render={({ field }) => (
                  <>
                    {field.value === 'object' && (
                      <>
                        <Grid xs={12} sm={6} lg={4} xl={3} {...amendDraggable(true)}>
                          <FieldSelect
                            label='Действие'
                            control={control}
                            options={arrayStatusByObject}
                            error=''
                            name={`${name}.${index}.status`}
                          />
                        </Grid>
                        <Grid xs={12} sm={6} lg={4} xl={3} {...amendDraggable(true)}>
                          <FieldSelect
                            label='Если пустой'
                            control={control}
                            options={arrayEmptyRule}
                            error=''
                            name={`${name}.${index}.emptyRule`}
                          />
                        </Grid>
                      </>
                    )}
                    {form(index, field.value)}
                  </>
                )}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </DraggableItem>
    );

    return (
      <Grid xs={12}>
        <Grid container>
          <Grid xs={12}>
            <Stack gap={2.5}>
              <DraggableList
                items={fields}
                draggable={draggable}
                onReorder={move}
                renderItem={renderItem}
              />
              <Stack direction='row' gap={1.5}>
                <Tooltip title='Добавить'>
                  <Box paddingLeft={1.5} width='max-content'>
                    <Button
                      circle
                      variant={ButtonVariants.Outlined}
                      size={ButtonSizes.Small}
                      onClick={() => append(appendObject)}
                    >
                      <PlusIcon />
                    </Button>
                  </Box>
                </Tooltip>
                {copiedValue && (
                  <Tooltip title='Вставить'>
                    <Box width='max-content'>
                      <Button
                        circle
                        variant={ButtonVariants.Outlined}
                        size={ButtonSizes.Small}
                        onClick={(e) => {
                          e.stopPropagation();
                          append(copiedValue);
                          setCopiedValue(undefined);
                        }}
                        onMouseDown={(e) => e.stopPropagation()}
                      >
                        <ContentPasteOutlinedIcon />
                      </Button>
                    </Box>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

MapObject.displayName = 'MapObject';
