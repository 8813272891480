export enum CustomEvents {
  InitializeFlow = 'initialize-flow',
  SelectFlow = 'select-flow',
  SelectNode = 'select-node',
  RemoveFlow = 'remove-flow',
  InitializeDashboard = 'initialize-dashboard',
  InitializeBlocks = 'initialize-blocks',
  InitializeFilters = 'initialize-filter',
  CreateBlock = 'create-block',
  UpdateBlock = 'update-block',
  DeleteBlock = 'delete-block',
  UpdateCanvas = 'update-canvas',
  ChangeConnects = 'change-connects',
  GetConnectSkeleton = 'get-connect-skeleton',
}
