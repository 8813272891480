import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Normalized } from '@/shared/lib/normalize';
import { OpenApiResponse } from '@/entities/OpenApi/types';

@singleton()
export class OpenApiAppStore {
  openApiApps: Normalized<OpenApiResponse> | null;

  isLoadingUpdateOpenApi: boolean;

  isLoadingDeleteOpenApi: boolean;

  isLoadingOpenApi: boolean;

  isErrorSubmit: boolean;

  constructor() {
    this.openApiApps = null;
    this.isLoadingUpdateOpenApi = false;
    this.isLoadingDeleteOpenApi = false;
    this.isLoadingOpenApi = false;
    this.isErrorSubmit = false;

    makeAutoObservable(this);
  }
}
