import {makeAutoObservable, runInAction} from 'mobx';
import { injectable } from 'tsyringe';
import { AxiosResponse } from 'axios';
import { uniq } from "lodash";

import { ApiService } from '@/shared/api/Api/services/ApiService';
import { FileStorageStore } from "@/entities/FileStorage/stores/FileStorageStore";
import { normalize } from "@/shared/lib/normalize";

import FileStorage from "../types";

@injectable()
export class FileStorageService {
  constructor(
    private fileStorageStore: FileStorageStore,
    private apiService: ApiService
  ) {

    makeAutoObservable(this);
  }

  get isLoadingFileStorages() {
    return this.fileStorageStore.isLoading;
  }

  get storageList() {
    return this.fileStorageStore.storageList;
  }

  async getAllStorageList() {
    this.fileStorageStore.isLoading = true;

    try {
      const response = await this.apiService.instance.get<FileStorage[], AxiosResponse<FileStorage[]>>(
        `editor/storage/all`
      );

      const normalizedStorageList = normalize(response.data, 'id');

      runInAction(() => {
        if (!this.storageList) {
          this.fileStorageStore.storageList = normalizedStorageList;
          return;
        }

        this.fileStorageStore.storageList.ids = uniq([...this.fileStorageStore.storageList.ids, ...normalizedStorageList.ids]);
        this.fileStorageStore.storageList.entities = {
          ...this.fileStorageStore.storageList.entities,
          ...normalizedStorageList.entities,
        };
      });

    } catch (error) {
      throw error;
    } finally {
      this.fileStorageStore.isLoading = false;
    }
  }

}
