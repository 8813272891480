import React, { FC, useCallback, useEffect, useMemo } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { ObjectId } from 'bson';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/system/Stack';

import { ButtonVariants } from '@/shared/ui/Button/types';
import { FieldSwitch } from '@/shared/ui/Fields/components/FieldSwitch/FieldSwitch';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import {
  CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE,
  SearchParams,
  useSearchParamsTemplate,
} from '@/hooks/useTemplateSearchParams';
import { nameAndDescriptionFieldsValidation } from '@/shared/lib';
import { RegistrableValues } from '@/shared/lib/types';
import { ConnectService } from '@/entities/Connect/services/ConnectService';
import { FlowCanvasService } from '@/entities/Flow/services/FlowCanvasService';

import Button from '../../../shared/ui/Button';
import { ConnectList } from '../../Connect/components/ConnectList/ConnectList';
import { ConnectSizes, ConnectTypes } from '../../Connect/components/ConnectList/types';
import { BlockService } from '../services/BlockService';
import Block from '../types';
import { AllConnectService } from '../../Connect/services/AllConnectService';
import { DescriptorManager } from "@/entities/Block/components/DescriptorManager";

import styles from './BlockForContainer.module.scss';

const allConnectService = container.resolve(AllConnectService);

export const BlockFormContainer: FC = observer(() => {
  const { flowId } = useParams();
  const { get, remove, set } = useSearchParamsTemplate();

  const blockId = get(SearchParams.BlockId);

  const connectService = useMemo(() => {
    container.register(RegistrableValues.BlockId, { useValue: blockId });
    return container.resolve(ConnectService);
  }, [blockId]);

  const { blockService, flowCanvasService } = useMemo(() => {
    container.register(RegistrableValues.FlowId, { useValue: flowId || '' });
    const blockService = container.resolve(BlockService);
    const flowCanvasService = container.resolve(FlowCanvasService);
    return {
      blockService,
      flowCanvasService,
    };
  }, [flowId]);
  const block = blockService.blocks?.entities[blockId];

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(nameAndDescriptionFieldsValidation),
    values: blockService.blocks?.entities[blockId || ''] || undefined,
    mode: 'onChange',
    shouldFocusError: false,
  });

  useEffect(() => {
    if (blockId) return;
    reset({ name: '', description: '' });
  }, [blockId, reset]);

  const onSubmit: SubmitHandler<Block> = (data) => {
    if (blockId) {
      blockService.updateBlock({ ...data });
    } else {
      const id = new ObjectId().toString();
      blockService.createBlock({
        ...data,
        flowId: flowId || '',
        flowName: data.flowName,
        id,
      });
    }
  };

  const handleMessageDeliverySettingsButtonClick = useCallback(() => {
    remove([SearchParams.ShowLogSettings, ...CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE]);
    set([SearchParams.ShowMessageDeliverySettings]);
  }, [remove, set]);

  const handleLogSettingsButtonClick = useCallback(() => {
    remove([SearchParams.ShowMessageDeliverySettings, ...CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE]);
    set([SearchParams.ShowLogSettings]);
  }, [remove, set]);

  const handleConnectListReorder = useCallback(
    (newOrder: string[]) => {
      connectService.reorderProcessors(newOrder);
    },
    [connectService]
  );

  const handleExProcessorListReorder = useCallback(
      (newOrder: string[]) => {
        connectService.reorderExProcessors(newOrder);
      },
      [connectService]
  );

  const togglerRelationship = useCallback((isManyToOne: boolean) => () => {
    if (!block) return;
    blockService.updateBlock({ ...block, isManyToOne });
  }, [block, blockService]);

  return (
    <div className={styles.root}>
      <form onBlur={handleSubmit(onSubmit)} onSubmit={handleSubmit(onSubmit)}>
        <Stack className='p-5' gap={5}>
          <Stack spacing={2}>
            <Stack spacing={2} style={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                variant={
                  !block?.isManyToOne ? ButtonVariants.Popup : ButtonVariants.Secondary
                }
                onClick={togglerRelationship(false)}
                style={{
                  maxWidth: '193px',
                  borderTopRightRadius: '0',
                  borderBottomRightRadius: '0',
                }}
              >
                <span>Стандартный</span>
              </Button>
              <Button
                variant={
                  block?.isManyToOne ? ButtonVariants.Popup : ButtonVariants.Secondary
                }
                onClick={togglerRelationship(true)}
                style={{
                  maxWidth: '193px',
                  marginTop: 0,
                  borderTopLeftRadius: '0',
                  borderBottomLeftRadius: '0',
                }}
              >
                <span>N к 1</span>
              </Button>
            </Stack>
            <Stack spacing={2}>
              <FieldInput
                label='Наименование блока'
                name='name'
                register={register}
                error={errors.name}
                description='Введите наименование блока, длинной не более 100 символов'
                id='nameBlock'
              />
              <FieldInput
                label='Описание блока'
                name='description'
                register={register}
                error={errors.description}
                description='Введите описание блока, длинной не более 1000 символов'
                id='descriptionBlock'
              />
              <FieldSwitch
                defaultValue='true'
                label='Активность'
                name='isActive'
                control={control}
                error={errors.isActive}
                parameterList={[]}
              />
            </Stack>
            <Stack spacing={2} id='handlerStack'>
              <ConnectList
                title='Коннекторы входящих данных'
                connectors={connectService.inputList}
                connectorCreatorOptions={allConnectService.inputOptions}
                type={ConnectTypes.Input}
                size={ConnectSizes.Large}
                blockId={blockId || ''}
                id='handlerConnector'
              />
              <ConnectList
                title='Обработчик данных'
                connectors={connectService.processorList}
                connectorCreatorOptions={allConnectService.processorOptions}
                size={ConnectSizes.Large}
                type={ConnectTypes.Processor}
                blockId={blockId || ''}
                draggable
                onReorder={handleConnectListReorder}
                id='handlerInfo'
              />
              <ConnectList
                title='Коннекторы исходящих данных'
                connectors={connectService.outputList}
                connectorCreatorOptions={allConnectService.outputOptions}
                size={ConnectSizes.Large}
                type={ConnectTypes.Output}
                blockId={blockId || ''}
                id='handlerOutputConnector'
              />
              <ConnectList
                title='В случае ошибки'
                connectors={connectService.exProcessorList}
                connectorCreatorOptions={allConnectService.processorOptions}
                size={ConnectSizes.Large}
                type={ConnectTypes.ExProcessor}
                blockId={blockId || ''}
                draggable
                onReorder={handleExProcessorListReorder}
                id='handlerExProcessorList'
              />
              <DescriptorManager
                blockService={blockService}
                flowCanvasService={flowCanvasService}
              />
              <Button
                doesUseInFormOrTable
                variant={ButtonVariants.Secondary}
                onClick={handleMessageDeliverySettingsButtonClick}
              >
                Настройка доставки сообщений
              </Button>
              <Button
                doesUseInFormOrTable
                variant={ButtonVariants.Secondary}
                onClick={handleLogSettingsButtonClick}
              >
                Настройка логирования
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </div>
  );
});
