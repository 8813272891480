import { forwardRef, PropsWithChildren } from 'react';

import { IComponents } from '../types';

import styles from './styles.module.scss';
import { Box } from '@mui/system';
import {
  EXECUTOR_LOG_BLOCK_SEARCH_PARAMS_TEMPLATE,
  SearchParams,
  useSearchParamsTemplate,
} from '@/hooks/useTemplateSearchParams';

export const gridTable: IComponents = {
  Scroller: forwardRef<HTMLDivElement, PropsWithChildren>(({ children, ...props }, ref) => {
    return (
      <div className={styles.scroller}>
        <div className={styles.wrapper} {...props} ref={ref}>
          {children}
        </div>
      </div>
    );
  }),

  Table: forwardRef<HTMLDivElement, PropsWithChildren>(({ children }, ref) => {
    return (
      <div ref={ref} className={styles.table}>
        {children}
      </div>
    );
  }),

  TableHead: forwardRef<HTMLDivElement, PropsWithChildren>(({ children }, ref) => {
    return (
      <div ref={ref} className={styles.head}>
        {children}
      </div>
    );
  }),

  TableBody: forwardRef<HTMLDivElement, PropsWithChildren>(({ children }, ref) => {
    return (
      <div ref={ref} className={styles.body}>
        {children}
      </div>
    );
  }),

  TableRow: forwardRef<
    HTMLAnchorElement,
    { columnsWidth: number[]; columnsCount: number; to?: string; children: any; data: any }
  >(({ columnsWidth, columnsCount, to = '', children, data, ...restProps }, ref) => {
    const { set, remove } = useSearchParamsTemplate();

    const openTracing = () => {
      if (!(data.blockId && data.messageId)) return;

      remove([
        ...EXECUTOR_LOG_BLOCK_SEARCH_PARAMS_TEMPLATE,
        SearchParams.ShowMessageTracing,
        SearchParams.BlockId,
        SearchParams.MessageId,
      ]);
      set([
        SearchParams.ShowMessageTracing,
        {
          [SearchParams.BlockId]: data.blockId,
          [SearchParams.MessageId]: data.messageId,
        },
      ]);
    };

    return (
      <Box
        ref={ref}
        style={{
          gridTemplateColumns: columnsWidth.map((width) => `${width}%`).join(' '),
        }}
        className={styles.row}
        {...restProps}
        onClick={openTracing}
      >
        {children}
      </Box>
    );
  }),

  TableCell: forwardRef<HTMLDivElement, PropsWithChildren>(({ children }, ref) => {
    return (
      <div ref={ref} className={styles.cell}>
        {children}
      </div>
    );
  }),
};
