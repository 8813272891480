import { MarkerType } from 'reactflow';
import { Relation } from "@/entities/Flow/types";

export function buildMessageEdge({ inputId, outputId, inId, outId }: Relation) {
  return {
    id: `${inId || inputId}${outId || outputId}`,
    source: outId || outputId,
    target: inId || inputId,
    style: { strokeWidth: '2px', stroke: 'var(--edge-color)' },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      strokeWidth: 2,
      color: '#E4E4E4',
    },
  };
}
