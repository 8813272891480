import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';

import Block from '../types';
import {Normalized} from "@/shared/lib/normalize";
import FileStorage from "../types";

@singleton()
export class FileStorageStore {
  storageList: Normalized<FileStorage>;

  isLoading: boolean;

  constructor() {
    this.storageList = null;
    this.isLoading = false;

    makeAutoObservable(this);
  }
}
