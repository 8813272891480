import { FC, memo } from 'react';

import { Box } from '@mui/system';
import { Resizable } from '@/shared/ui';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

import { MessageTracingWorkspace } from './MessageTracingWorkspace';

export interface MessageTracingProps {}

export const MessageTracing: FC<MessageTracingProps> = memo(() => {
  const { has } = useSearchParamsTemplate();

  if (!has(SearchParams.ShowMessageTracing)) return null;
  return (
    <Box position='absolute' right={0} height='100%'>
      <Resizable maxWidth={window.innerWidth - 260} initialWidth={833}>
        <MessageTracingWorkspace />
      </Resizable>
    </Box>
  );
});

MessageTracing.displayName = 'MessageTracingWidget';
