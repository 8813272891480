export const INNER_STORAGE_TYPE = 'inner';

export const LOG_SETTINGS_STORAGES = [
  {
    id: 'innerLog',
    label: 'Внутреннее хранилище Интегры',
    logType: INNER_STORAGE_TYPE,
    isDefault: true,
  },
];
