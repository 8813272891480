import { FC, useCallback } from 'react';

import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import Stack from '@mui/material/Stack';
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';

import { CreateUserService } from '../api/CreateUserService';

const createUserService = container.resolve(CreateUserService);

export interface CreateUserButtonProps {}

export const CreateUserButton: FC<CreateUserButtonProps> = observer(() => {
  const handleClick = useCallback(() => {
    createUserService.open = true;
  }, []);

  return (
    <Button
      doesUseInFormOrTable
      variant={ButtonVariants.Secondary}
      onClick={handleClick}
      style={{
        padding: '2em 1em',
        minWidth: 240,
      }}
    >
      <Stack direction='row' gap={1} alignItems='center'>
        <AddBoxOutlinedIcon />
        Добавить пользователя
      </Stack>
    </Button>
  );
});

CreateUserButton.displayName = 'CreateUserButton';
