import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { useEdgesState, useNodesState } from 'reactflow';
import { Box } from '@mui/system';

import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { LoaderIcon } from '@/shared/ui/Icons/LoaderIcon/LoaderIcon';
import { FlowPlatform } from '@/shared/ui/FlowPlatform/FlowPlatform';
import ReactFlowToImageButton from '@/shared/ui/ReactFlowToImageButton';
import { DisplayMessageService, MessageNodeProps } from '@/entities/MessageTracing';

import { MessageNode } from './MessageNode/MessageNode'; // don't shorten
import { ExpanderNode } from './ExpanderNode';
import { IndexedDBFilterService } from '@/shared/ui/FilterMessageTracing/api/IndexedDBFilterService';
import { DisplayMessageStore } from '@/entities/MessageTracing/model/DisplayMessageStore';
import { defaultFilter } from '@/shared/ui/FilterMessageTracing/utils/constants';

const displayMessageService = container.resolve(DisplayMessageService);
const proOptions = {
  hideAttribution: true,
};
const nodeTypes = {
  messageNode: MessageNode,
  expanderNode: ExpanderNode,
};

interface MessagesFlowProps {
  blockId: string;
  messageId: string;
  isTrace?: boolean;
}

const indexedDBFilterService = container.resolve(IndexedDBFilterService);
const displayMessageStore = container.resolve(DisplayMessageStore);
export const MessagesFlow = observer(
  ({ blockId, messageId, isTrace = false }: MessagesFlowProps) => {
    const [nodes, setNodes] = useNodesState<MessageNodeProps>([]);
    const [edges, setEdges] = useEdgesState([]);
    const [shouldFetchMessages, setShouldFetchMessages] = useState(false);

    const {
      updateData,
      selectedExecutorHistoryId,
      filterList,
      messageNodes,
      messageEdges,
      exchangeList,
    } = displayMessageService;
    const { flowId } = useParams();

    const keyForIndexedDB = `filterData${flowId}.${blockId}`;

    useEffect(() => {
      indexedDBFilterService.getFilterData(keyForIndexedDB).then((hasData) => {
        if (hasData) {
          setShouldFetchMessages(true);
          return;
        }
        displayMessageStore.filterList = defaultFilter;
        setShouldFetchMessages(true);
      });
    }, [keyForIndexedDB]);

    useEffect(() => {
      if (!updateData) return;
      setNodes(messageNodes);
    }, [updateData]);

    useEffect(() => {
      if (!shouldFetchMessages) return;

      displayMessageService
        .getMessages(blockId, {
          pagination: { size: 1, page: 0 },
          filterList,
          addTrace: isTrace,
          messageId,
        })
        .then(() => {
          setNodes(messageNodes);
          setEdges(messageEdges);
          setShouldFetchMessages(false);
        });
    }, [shouldFetchMessages, filterList, blockId, messageId]);

    useEffect(() => {
      if (!selectedExecutorHistoryId) return;
      setNodes(messageNodes);
      setEdges(messageEdges);
    }, [selectedExecutorHistoryId]);

    if (displayMessageService.isLoadingMessages) {
      return (
        <Box height='100%' alignItems='center' justifyContent='center' display='flex'>
          <LoaderIcon width={24} height={24} />
        </Box>
      );
    }

    return (
      <Box height='100%'>
        {!exchangeList || isEmpty(filterList) ? (
          <p>Сообщения отсутствуют</p>
        ) : (
          <FlowPlatform
            nodeTypes={nodeTypes}
            proOptions={proOptions}
            nodes={nodes}
            edges={edges}
            defaultViewport={{ x: 0, y: 50, zoom: 0.75 }}
            onlyRenderVisibleElements={nodes.length > 30}
            translateExtent={[
              [-100, -100],
              [
                (displayMessageService.maxWidth || Infinity) + 1000,
                (displayMessageService.maxHeight || Infinity) + 1000,
              ],
            ]}
            className='messageTracing'
          >
            {!!nodes.length && nodes.length <= 30 && (
              <ReactFlowToImageButton
                selector='.messageTracing'
                imageWidth={displayMessageService.maxWidth || 1000}
                imageHeight={displayMessageService.maxHeight || 1000}
              />
            )}
          </FlowPlatform>
        )}
      </Box>
    );
  }
);
