import { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { OpenBlockForm } from '@/features';
import { useResolve } from '@/hooks/useResolve';
import { OpenMessageTracing } from '@/features/OpenMessageTracing';
import { AnimatePresence, motion } from 'framer-motion';
import { IndexedDBStore } from '@/store/IndexedDBStore/IndexedDBStore';
import { OpenUnSaveConnectForm } from '@/features/OpenModalUnSaveConectForm/OpenModalUnSaveConectForm';
import { IndexedDBService } from '@/service/indexedDB';

import styles from './ConnectorToolbar.module.scss';

export interface ConnectorToolbarProps {
  blockId: string;
  keyForIndexedDB?: string;
  visible?: boolean;
}

export const ConnectorToolbar: FC<ConnectorToolbarProps> = observer(
  ({ blockId, keyForIndexedDB }) => {
    const indexedDBStore = useResolve(IndexedDBStore);
    const indexedDBService = useResolve(IndexedDBService);
    const idbConnectName: string = indexedDBStore?.id;
    /** Условие для изменения состояния модального окна */
    const showSaveDataIndexedDB =
      idbConnectName === keyForIndexedDB && indexedDBStore.isShowIndexedDB;

    useEffect(() => {
      indexedDBService.getFieldData(keyForIndexedDB);
    }, []);

    return (
      <AnimatePresence mode='wait'>
        <motion.div
          className={styles.root}
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          transition={{ ease: 'easeInOut' }}
          exit={{ x: '100%' }}
        >
          <OpenBlockForm blockId={blockId} toolbarPlacement='top' />
          <OpenMessageTracing blockId={blockId} toolbarPlacement='top' />

          {showSaveDataIndexedDB && (
            <OpenUnSaveConnectForm
              blockId={blockId}
              toolbarPlacement='top'
              showSaveDataIndexedDB={showSaveDataIndexedDB}
            />
          )}
        </motion.div>
      </AnimatePresence>
    );
  }
);

ConnectorToolbar.displayName = 'ConnectorToolbar';
