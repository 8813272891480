export function buildMessageNode({
  exchangeData,
  canvasData,
  allConnectService,
  buildDownloadHandler,
}) {
  const { id, type } = exchangeData;
  const node = {
    id,
    type: 'messageNode',
    style: {
      width: canvasData?.sx?.width || 420,
      height: canvasData?.sx?.height || 420,
      display: 'flex',
    },
    position: {
      x: canvasData?.left || 0,
      y: canvasData?.top || 0,
    },
  }
  const data = {
    id,
    value: exchangeData.body?.outputBody || '',
    headers: exchangeData.headers,
    pathParams: exchangeData.inputPathParams,
    queryParams: exchangeData.inputQueryParams,
    exception: exchangeData.exception,
    stackTrace: exchangeData.stackTrace,
    messageId: '',
    dateExecute: exchangeData.timeStart ? new Date(exchangeData.timeStart).toISOString() : undefined,
    timeExecute: exchangeData.timeExecute,
    valueSize: exchangeData.body?.size || 0,
    onDownloadBody: buildDownloadHandler('body'),
  };
  let processorType = '';
  let processorTypeLocale = '';
  let cfg;
  switch (type) {
    case 'start':
      processorType = exchangeData.inputConnect?.typeConnect || '';
      processorTypeLocale = allConnectService.parameterInputByName(processorType)?.localeName;
      cfg = {
        processorName: `Входящее сообщение ${exchangeData.inputConnect?.name || ''}`,
        processorType,
        processorTypeLocale,
        value: exchangeData.inputBody?.outputBody || '',
        context: exchangeData.context?.outputBody || '',
        headers: exchangeData.inputHeaders,
        files: exchangeData.files,
        valueSize: exchangeData.inputBody?.size || 0,
        onDownloadBody: buildDownloadHandler('inputBody'),
      };
      break;
    case 'work':
      processorType = exchangeData.processor?.typeProcessor || '';
      processorTypeLocale = allConnectService.parameterProcessorByName(type)?.localeName;
      cfg = {
        processorName: exchangeData.processor?.name || '',
        processorType,
        processorTypeLocale,
        context: exchangeData.context?.outputBody || '',
        messageId: exchangeData?.headers?.messageId || '',
      };
      break;
    case 'finish':
      cfg = {
        processorName: 'Исходящее сообщение',
        processorType: '',
        headers: exchangeData.outputHeaders,
      };
      break;
    default:
      cfg = {
        processorName: exchangeData.processor?.name || '',
        processorType: exchangeData.processor?.typeProcessor || '',
        messageId: exchangeData?.headers?.messageId || '',
      };
      break;
  }
  return {
    ...node,
    data: {
      ...data,
      ...cfg,
    }
  }
}
