import React, { FC, useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { container } from "tsyringe";
import { observer } from 'mobx-react-lite';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Snackbar,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { KEYBOARD_KEYS } from "@/shared/lib/constants";
import { CodeEditor, API } from "@/shared/ui";
import { RunOnKeys } from "@/shared/ui/RunOnKeys/RunOnKeys";
import { SearchParams, useSearchParamsTemplate } from "@/hooks/useTemplateSearchParams";
import { usePasteBlock } from "@/entities/Block/utils/keyboardKeys";
import { BlockStore } from "@/entities/Block/stores/BlockStore";
import { BlockService } from "@/entities/Block/services/BlockService";
import { FlowCanvasService } from "@/entities/Flow/services/FlowCanvasService";
import { Toolbar } from "./Toolbar";

import styles from "./DescriptorManager.module.scss";

const blockStore = container.resolve(BlockStore);
interface DescriptorManagerProps {
  blockService: BlockService;
  flowCanvasService: FlowCanvasService;
}

export const DescriptorManager: FC<DescriptorManagerProps> = observer((props) => {
  const { blockService } = props;

  const { flowId } = useParams();
  const { get } = useSearchParamsTemplate();
  const blockId = get(SearchParams.BlockId);

  const [isOpenSnackbar, setIsOpenSnackbar] = React.useState(false);
  const [warn, setWarn] = useState('');

  const block = blockService.blocks?.entities[blockId];
  const copyBlock = blockStore.copyBlockNew;

  /** Данные  блока для отображения в форме */
  const [descriptorValue, setDescriptorValue] = useState('');

  const insertDescriptorValue = useCallback(() => {
    const data = block || copyBlock;
    const jsonString = JSON.stringify({ block: data });
    setDescriptorValue(jsonString);
  }, [block, copyBlock]);

  const handleBlockPaste = usePasteBlock(flowId, blockId, setWarn, setDescriptorValue);

  const codeViewerRef = useRef<API>();
  const openFullMode = () => {
    const { current: api } = codeViewerRef;
    if (!api) return;
    api.toggleFullMode(true);
  }

  const { Control, V } = KEYBOARD_KEYS;
  const keysEvents = [
    {
      keys: [Control, V],
      event: handleBlockPaste,
    },
  ];

  return (
    <>
      <RunOnKeys keysEvents={keysEvents} tabIndex={1!}>
        <Accordion
          elevation={0}
          variant='outlined'
          TransitionProps={{
            onEntered: insertDescriptorValue,
            onExit: () => {
              setDescriptorValue('');
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls='panel1-content'
            id='panel1-header'
          >
            <Typography>Копирование/Вставка блока</Typography>
          </AccordionSummary>

          <AccordionActions className={styles.buttonBlock}>
            <Toolbar
              {...props}
              setDescriptorValue={setDescriptorValue}
              handleBlockPaste={handleBlockPaste}
              setWarn={setWarn}
              setIsOpenSnackbar={setIsOpenSnackbar}
              openFullMode={openFullMode}
            />
          </AccordionActions>
          <AccordionDetails>
            <CodeEditor
              ref={codeViewerRef}
              className={styles.descriptor}
              defaultLanguage="json"
              defaultFormat="json"
              value={descriptorValue}
              readonly
              header={null}
            />
          </AccordionDetails>
        </Accordion>
      </RunOnKeys>
      <Snackbar
        className={styles.snackbars}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isOpenSnackbar}
        autoHideDuration={1500}
        onClose={() => setIsOpenSnackbar(false)}
        message='Блок скопирован'
      />
      <Snackbar
        className={styles['snackbar-warn']}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!warn}
        autoHideDuration={4000}
        onClose={() => setWarn('')}
        message={warn as string}
      />
    </>
  );
});
