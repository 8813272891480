import isArray from 'lodash/isArray';
import { ExecutorHistory } from "@/entities/Connect/types";

export const findExecutorHistoryByTracing = (executorHistory: ExecutorHistory, findId: string) : ExecutorHistory | null => {
  if (!executorHistory) return null;
  if (executorHistory.id === findId) return executorHistory;

  const { executorHistoryNext } = executorHistory;
  if (!isArray(executorHistoryNext)) return null;

  return executorHistoryNext.reduce((res, itm) => {
    if (res) return res;
    return findExecutorHistoryByTracing(itm, findId);
  }, null);
}
