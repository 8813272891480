import React, { FC } from 'react';

import Stack from '@mui/material/Stack';
import { Paper } from '@/shared/ui/Paper';
import { MessageTracing } from "@/widgets";

import { TracingTableContainer } from './TracingTableContainer';

import styles from './Tracing.module.scss';

export const Tracing: FC = () => {
  return (
    <Stack className={styles.wrapper}>
      <Paper className={styles.paper}>
        <TracingTableContainer />
      </Paper>
      <MessageTracing />
    </Stack>
  );
};
