import { FC, useCallback, useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { observer } from 'mobx-react-lite';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { container } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import { WSDLService } from '@/entities/WSDLService/features/api/WSDLService';
import { Checkbox } from '@/shared/ui/Checkbox/Checkbox';

const wsdlService = container.resolve(WSDLService);

export interface DeleteExternalAppDialogProps {
  onSuccess?(): void;
}

export const DeleteWSDLAppDialog: FC<DeleteExternalAppDialogProps> = observer(
  ({ onSuccess }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = useCallback(() => {
      wsdlService.openDeleteForm = false;
    }, []);

    const handleOkButtonClick = useCallback(async () => {
      try {
        setIsLoading(true);

        await wsdlService.deleteWSDLApp();

        notify.success('Внешний WSDL-сервис удален');
        handleClose();
        onSuccess?.();
      } catch {
        notify.error('Не удалось удалить WSDL-сервис');
      } finally {
        setIsLoading(false);
      }
    }, [handleClose, onSuccess]);
    return (
      <Dialog fullWidth open={wsdlService.openDeleteForm} onClose={handleClose}>
        <DialogTitle onClose={handleClose}>Удаление WSDL-сервиса</DialogTitle>
        <DialogContent>
          <form>
            <Checkbox
              name='service_deactivation'
              defaultValue='false'
              label=' Отключить обработчики/коннекторы, использующие данный сервис ?'
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
            Отмена
          </Button>
          <Button variant={ButtonVariants.Popup} loading={isLoading} onClick={handleOkButtonClick}>
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

DeleteWSDLAppDialog.displayName = 'DeleteWSDLAppDialog';
