import { FC, useCallback } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { container } from 'tsyringe';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { ACTIONS_SEARCH_PARAMS, SearchParams, useSearchParamsTemplate } from "@/hooks/useTemplateSearchParams";
import { FlowService } from '@/entities/Flow/services/FlowService';

import { CloneFlowService } from '../api/CloneFlowService';

const cloneFlowService = container.resolve(CloneFlowService);
const flowService = container.resolve(FlowService);

export const CloneFlowDialog: FC = observer(() => {
  const { flowId } = useParams();
  const flowName = flowService.flows.entities[flowId || '']?.name;
  const { get, remove } = useSearchParamsTemplate();
  const showDialog = get(SearchParams.ShowFlowCloneModal);

  const handleClose = () => {
    remove(ACTIONS_SEARCH_PARAMS);
  };

  const handleOkButtonClick = useCallback(() => {
    cloneFlowService.cloneFlow(flowId).then(handleClose);
  }, [handleClose, flowId]);

  if (!showDialog) return null;

  return (
    <Dialog fullWidth open onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Клонирование потока</DialogTitle>
      <DialogContent>Вы точно хотите клонировать поток {flowName}?</DialogContent>
      <DialogActions>
        <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
          Отмена
        </Button>
        <Button
          variant={ButtonVariants.Popup}
          loading={cloneFlowService.isLoading}
          onClick={handleOkButtonClick}
        >
          Клонировать
        </Button>
      </DialogActions>
    </Dialog>
  );
});

CloneFlowDialog.displayName = 'CloneFlowDialog';
