import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { observer } from 'mobx-react-lite';

import { identity } from "lodash";
import { container } from "tsyringe";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import IosShareIcon from "@mui/icons-material/IosShare";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FullscreenOutlined from "@mui/icons-material/FullscreenOutlined";

import UploadFileJSON from "@/shared/ui/UploadFileJSON";
import downloadJSON from "@/shared/lib/downloadJSON";
import { SearchParams, useSearchParamsTemplate } from "@/hooks/useTemplateSearchParams";
import Block from "@/entities/Block/types";
import { createCopyBlock } from "@/entities/Block/utils/keyboardKeys";
import { BlockStore } from "@/entities/Block/stores/BlockStore";
import { ActionBtn } from "./ActionBtn";
import { BlockService } from "@/entities/Block/services/BlockService";
import { FlowCanvasService } from "@/entities/Flow/services/FlowCanvasService";

interface ToolbarProps {
  blockService: BlockService;
  flowCanvasService: FlowCanvasService;
  setWarn: (warn: string) => void;
  setIsOpenSnackbar: (isOpen: boolean) => void;
  setDescriptorValue: (jsonStr: string) => void;
  handleBlockPaste: () => Promise<void>;
  openFullMode: () => void;
}

const blockStore = container.resolve(BlockStore);

export const Toolbar: FC<ToolbarProps> = observer(({
  blockService,
  flowCanvasService,
  setWarn,
  setIsOpenSnackbar,
  setDescriptorValue,
  handleBlockPaste,
  openFullMode,
}) => {
  const { flowId } = useParams();
  const { get } = useSearchParamsTemplate();
  const blockId = get(SearchParams.BlockId);

  const block = blockService.blocks?.entities[blockId];


  const getBlockData = () => {
    if (!block) return null;
    const sx = flowCanvasService.elements?.entities[block.id]?.sx || {};
    return { block, sx };
  }

    /** Копирование блока */
    const handleBlockCopy = async () => {
      const json = JSON.stringify(getBlockData());
      if (!json) return;
      await navigator.clipboard.writeText(json);
      setIsOpenSnackbar(true);
    };

  const handleBlockExport = async () => {
    const data = getBlockData();
    if (!data) return;
    const { name, flowName } = data.block;
    await downloadJSON(data, [name, flowName].filter(identity).join('_'));
  };

  const showJSONBlock = (block: Block) => {
    setDescriptorValue(JSON.stringify({ block }));
  };

  const handleBlockImport = async (obj: { block: Block }) => {
    const block = obj?.block;
    if (!flowId) return;
    await createCopyBlock(flowId, blockId, block);
    showJSONBlock(blockStore.copyBlockNew);
  };

  /** Очистка блока для сброса настроек */
  const cleanPastBlockHandler = () => {
    setDescriptorValue('');
  };

  return (
    <>
      <UploadFileJSON
        addFile={handleBlockImport}
        errHandler={() => {
          setWarn('Невалидный JSON-файл');
        }}
      >
        <ActionBtn
          slug="import"
          tooltip="Импорт блока из JSON-файла"
          icon={<SimCardDownloadIcon />}
        />
      </UploadFileJSON>
      <ActionBtn
        slug="export"
        tooltip="Экспорт блока в JSON-файл"
        icon={<IosShareIcon />}
        handler={handleBlockExport}
      />
      <ActionBtn
        slug="copy"
        tooltip="Копировать блок"
        icon={<ContentCopyIcon />}
        handler={handleBlockCopy}
      />
      <ActionBtn
        slug="paste"
        tooltip="Вставить блок"
        icon={<ContentPasteIcon />}
        handler={handleBlockPaste}
      />
      <ActionBtn
        slug="fullMode"
        tooltip="На весь экран"
        icon={<FullscreenOutlined />}
        handler={openFullMode}
      />
      <ActionBtn
        slug="delete"
        tooltip="Очистить поле"
        icon={<DeleteOutlineIcon />}
        handler={cleanPastBlockHandler}
      />
    </>
  );
});
