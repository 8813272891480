import { FC, memo, MouseEvent, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { SettingsIcon } from '@/shared/ui/Icons/SettingsIcon/SettingsIcon';
import Button from '@/shared/ui/Button';
import Tooltip from '@/shared/ui/Tooltip';
import { ACTIONS_SEARCH_PARAMS, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import getSPKeys from '@/entities/Flow/features/Actions/getSPKeys';

export interface OpenFlowFormProps {
  flowId: string;
}

export const EditFlow: FC<OpenFlowFormProps> = memo(({ flowId }) => {
  const { has, get, set, remove } = useSearchParamsTemplate();
  const navigate = useNavigate();
  const mode = get('mode');
  const keyMap = getSPKeys(mode);
  const active = has(keyMap.edit);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      navigate(flowId);
      remove(ACTIONS_SEARCH_PARAMS);
      if (active) return;
      set([keyMap.edit]);
    },
    [flowId, navigate, remove, set]
  );

  return (
    <Tooltip title='Редактировать поток' placement='top'>
      <span>
        <Button
          className={cn({ active })}
          circle
          size={ButtonSizes.Small}
          variant={ButtonVariants.Outlined}
          onClick={handleClick}
        >
          <SettingsIcon />
        </Button>
      </span>
    </Tooltip>
  );
});

EditFlow.displayName = 'OpenFlowForm';
