import { ChangeEvent, FC, useCallback, useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle, Input } from '@/shared/ui';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import Box from '@mui/material/Box';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import { notify } from '@/shared/ui/Toast/notify';
import { UpsertLicenseService } from '../api/UpsertLicenseService';
import { CompanyService } from "@/entities/Company";
import { runInAction } from "mobx";
import {isType} from "@/shared/lib/isType";
import {SelectOption} from "@mui/base";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import {SelectChangeEvent} from "@mui/material/Select";

const upsertLicenseService = container.resolve(UpsertLicenseService);

export interface UpsertLicenseDialogProps {}

export const UpsertLicenseDialog: FC<UpsertLicenseDialogProps> = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [valueHandle, setValueHandle] = useState<string>('');
  const [type, setType] = useState<string>('auto');

  const title = upsertLicenseService.selectedLicenseId
    ? 'Изменить лицензионный ключ'
    : 'Добавить лицензионный ключ';

  const handleClose = useCallback(() => {
    upsertLicenseService.open = false;
    upsertLicenseService.selectedLicenseId = null;
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValueHandle(event.target.value);
    runInAction(() => {
      upsertLicenseService.license = event.target.value;
    })
  }

  const handleChangeType = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | SelectChangeEvent>) => {
    setType(event.target.value);
    runInAction(() => {
      upsertLicenseService.type = event.target.value;
    })
  }

  const handleOk = useCallback(async () => {

    if (upsertLicenseService.selectedLicenseId) {
      try {
        setIsLoading(true);

        await upsertLicenseService.updateLicense(upsertLicenseService.license ?? '');
        handleClose();
      } catch {
        notify.error('Не удалось изменить лицензионный ключ');
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        setIsLoading(true);
        await upsertLicenseService.addLicense(upsertLicenseService.license || valueHandle || '');
        handleClose();
      } catch {
        notify.error('Не удалось добавить лицензионный ключ');
      } finally {
        setIsLoading(false);
      }
    }
  }, [handleClose, valueHandle]);

  return (
    <Dialog maxWidth='md' open={upsertLicenseService.open} fullWidth onClose={handleClose}>
      <DialogTitle onClose={handleClose}>{title}</DialogTitle>
      <DialogContent>
        <Box m='2px'>
          <Input
              select
              label={"Тип ключа"}
              defaultValue={upsertLicenseService.type}
              SelectProps={{
                value: upsertLicenseService.type,
                onChange: (event) => {
                  handleChangeType(event)
                },
              }}
          >
            <MenuItem key={"inner"} value={"inner"}>Ключ от Интегра</MenuItem>
            <MenuItem key={"7tech"} value={"7tech"}>Ключ от 7TECH</MenuItem>
            <MenuItem key={"yandex"} value={"yandex"}>Ключ на подписку от Яндекс Маркетплейс</MenuItem>
          </Input>
          <br/>
          <Input
            label='Лицензионный ключ'
            placeholder='Добавить ключ'
            multiline
            minRows={6}
            defaultValue={upsertLicenseService.license}
            onChange={handleChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box width={280}>
          <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
            Отмена
          </Button>
        </Box>
        <Box width={280}>
          <Button variant={ButtonVariants.Popup} loading={isLoading} onClick={handleOk}>
            Ок
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});

UpsertLicenseDialog.displayName = 'UpsertLicenseDialog';
