import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { normalize } from '@/shared/lib/normalize';
import { WSDLAppStore } from '@/entities/WSDLService/stores/WSDLAppStore';
import { WSDLList, WSDLResponse } from '@/entities/WSDLService/types';
import { notify } from '@/shared/ui/Toast/notify';

@singleton()
export class WSDLService {
  open: boolean;

  openDeleteForm: boolean;

  selectedId: string | null;

  constructor(private apiService: ApiService, private wsdlAppStore: WSDLAppStore) {
    this.open = false;
    this.selectedId = null;
    this.openDeleteForm = false;

    makeAutoObservable(this);
  }

  get currentWSDLApp() {
    if (this.selectedId) {
      return this.wsdlAppStore.wsdlApps?.entities[this.selectedId];
    }

    return null;
  }

  async getWSDLApps() {
    this.wsdlAppStore.isLoadingWSDLApp = true;
    try {
      const response = await this.apiService.instance.get<WSDLList[]>(
        '/editor/wsdl-services'
      );
      this.wsdlAppStore.wsdlApps = normalize(response.data, 'id');
    } catch (error) {
      notify.error('Не удалось получить данные');
    } finally {
      this.wsdlAppStore.isLoadingWSDLApp = false;
    }
  }

  async addWSDLApp(wsdlApp: WSDLResponse) {
    this.wsdlAppStore.isLoadingUpdateWSDLApp = true;
    try {
      await this.apiService.instance.post<WSDLResponse>(`/editor/wsdl-services`, wsdlApp);
      this.wsdlAppStore.isErrorSubmit = false;
      notify.success('Внешний сервис добавлен');
      await this.getWSDLApps();
    } catch (error) {
      this.wsdlAppStore.isErrorSubmit = true;
      notify.error('Не удалось добавить сервис');
    } finally {
      this.wsdlAppStore.isLoadingUpdateWSDLApp = false;
    }
  }

  async editWSDLApp(wsdlApp: WSDLResponse) {
    this.wsdlAppStore.isLoadingUpdateWSDLApp = true;
    try {
      await this.apiService.instance.put<WSDLResponse>(
        `/editor/wsdl-services/${this.selectedId}`,
        wsdlApp
      );
      this.wsdlAppStore.isErrorSubmit = false;
      notify.success('Внешний сервис изменен');
      await this.getWSDLApps();
    } catch (error) {
      this.wsdlAppStore.isErrorSubmit = true;
      notify.error('Не удалось изменить сервис');
    } finally {
      this.wsdlAppStore.isLoadingUpdateWSDLApp = false;
    }
  }

  async deleteWSDLApp() {
    this.wsdlAppStore.isLoadingDeleteWSDLApp = true;
    try {
      if (this.selectedId) {
        await this.apiService.instance.delete(`/editor/wsdl-services/${this.selectedId}`);
        await this.getWSDLApps();
      }
    } catch (error) {
      notify.error('Не удалось удалить сервис');
      throw error;
    } finally {
      this.wsdlAppStore.isLoadingDeleteWSDLApp = true;
    }
  }
}
