import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import { ApiService } from '@/shared/api/Api/services/ApiService';

import { SettingsStore } from '../model/SettingsStore';
import { Settings } from '../model/types';
import { LOG_SETTINGS_STORAGES } from "../constants";

@singleton()
export class SettingsService {
  isLoading: boolean;

  constructor(private apiService: ApiService, private settingsStore: SettingsStore) {
    this.isLoading = false;

    makeAutoObservable(this);
  }

  get logStorages() {
    return this.settingsStore.logLocations;
  }

  async getCurrentSettings() {
    try {
      this.isLoading = true;

      const response = await this.apiService.instance.get<Settings>('/editor/settings/current');

      this.settingsStore.settings = response.data;
    } catch (error) {
      notify.error('Не удалось получить текущие настройки');
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getLogLocations() {
    try {
      const { data } = await this.apiService.instance.get('editor/settings/logs');
      this.settingsStore.logLocations = data;
    } catch (err) {
      this.settingsStore.logLocations = LOG_SETTINGS_STORAGES;
    }
  }
}
