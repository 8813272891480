import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { container } from 'tsyringe';
import { useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import Button from '@/shared/ui/Button';
import { FieldPassword } from '@/shared/ui/Fields/components/FieldPassword/FieldPassword';
import { Label } from '@/shared/ui/Label/Label';
import { Input, Select } from '@/shared/ui';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';

import useLocalStorageRedux from '../../../../hooks/useLocalStorageRedux';
import useRedux from '../../../../hooks/useRedux';
import { showNotification } from '../../../../store/notifications/actions';
import useFetch from '../../../../hooks/useFetch';
import useDebounceState from '../../../../hooks/useDebounceState';
import { AuthService } from '@/shared/ui/AuthVerifier/services/AuthService';

const authService = container.resolve(AuthService);

const AuthForm = observer((props) => {
  const { onLogin } = props;
  const dispatch = useDispatch();
  const [context] = useLocalStorageRedux('context');
  const [authUserByCompany, setAuthUserByCompany] = useRedux('authUserByCompany');
  const [typeAuth, setTypeAuth] = useLocalStorageRedux('typeAuth');
  const [, setHeaderAuth] = useLocalStorageRedux('headerAuth');

  const [typeAuthLogin] = useLocalStorageRedux('typeAuthLogin', 'Basic');
  const fetchHook = useFetch();
  const [companyIdLocalStorage, setCompanyIdLocalStorage] = useLocalStorageRedux(
    'companyId',
    'default'
  );
  const [companyId, setCompanyId, debounceCompanyId] = useDebounceState('', 500, {
    value: companyIdLocalStorage,
    setValue: setCompanyIdLocalStorage,
  });

  const { register, handleSubmit } = useForm({
    defaultValues: {
      companyId: 'default',
    },
  });

  useEffect(() => {
    if (context && context.applicationMode === 'enterprise') {
      setCompanyId('default');
    }
  }, [context]);

  useEffect(() => {
    if (context) {
      if (debounceCompanyId) {
        getAuthUserByCompany();
      }
    }
  }, [context, debounceCompanyId]);
  const getAuthUserByCompany = async () => {
    const response = await fetchHook({
      method: 'get',
      url: `editor/settingsUserAuth/by/${debounceCompanyId}`,
    });
    if (response) {
      setAuthUserByCompany(response);
    } else {
      setAuthUserByCompany(undefined);
    }
  };

  const auth = async (data) => {
    if (authUserByCompany) {
      try {
        const { login, password } = data;

        const authInfo = JSON.parse(typeAuth);
        if (authInfo) {
          if (authInfo.type === 'Basic') {
            const basicAuth = `Basic ${btoa(`${login}:${password}`)}`;
            setHeaderAuth(basicAuth);
            const checkBasicAuth = await authService.sendIsCheck();
            if (!checkBasicAuth) {
              dispatch && dispatch(showNotification('error', 'Неверный логин или пароль'));
            }
          } else if (authInfo.type === 'Bearer') {
            const headers = {
              'Content-Type': 'application/x-www-form-urlencoded',
            };

            const urlencoded = new URLSearchParams();
            urlencoded.append('grant_type', 'password');
            urlencoded.append('username', login);
            urlencoded.append('password', password);
            urlencoded.append('client_id', authInfo.clientId);
            urlencoded.append('client_secret', authInfo.clientSecret);

            const response = await fetch(authInfo.urlGetToken, {
              headers,
              method: authInfo.methodGetToken,
              body: urlencoded,
            }).then((response) => response.json());
            if (response && !response.error) {
              setHeaderAuth(`Bearer ${response.access_token}`);
              setResultTokenRequest(response);
            } else {
              setHeaderAuth(null);
              dispatch && dispatch(showNotification('error', 'Неверный логин или пароль'));
            }
            setSendCheck(new Date().getTime());
          }
        }
      } catch {
        dispatch && dispatch(showNotification('error', 'Неверный логин или пароль'));
      }
    }
  };

  const renderLoginPassword = (type) => {
    switch (type) {
      case 'Basic':
        return (
          <>
            <FieldInput error='' name='login' label='Логин' register={register} />
            <FieldPassword label='Пароль' name='password' error='' register={register} />
            <Button type='submit'>Войти</Button>
          </>
        );
      case 'Bearer':
        return <Button type='submit'>Войти</Button>;
      default:
        return null;
    }
  };

  return (
    <form onSubmit={onLogin ? handleSubmit(onLogin) : handleSubmit(auth)}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: 'var(--layout-color)',
        }}
      >
        {context ? (
          <Box
            sx={{
              width: '500px',
              display: 'flex',
              flexDirection: 'column',
              gap: 2.5,
            }}
          >
            {context.applicationMode != 'enterprise' ? (
              <Input
                label='Компания'
                value={companyId}
                onChange={(e) => {
                  setCompanyId(e.target.value);
                }}
              />
            ) : null}
            {authUserByCompany?.active === true ? (
              <>
                <Stack spacing={2}>
                  <Label>Сервис авторизации</Label>
                  <Select
                    value={typeAuth || {}}
                    options={authUserByCompany.authUserOptionList?.map((value) => ({
                      label: value.name,
                      value: JSON.stringify(value),
                    }))}
                    onChange={(e) => setTypeAuth(e.target.value)}
                  />
                </Stack>
                {typeAuthLogin ? renderLoginPassword(typeAuthLogin) : null}
              </>
            ) : (
              <div style={{ color: '#721c24', padding: '15px' }}>
                {authUserByCompany?.deactivationComment}
              </div>
            )}
          </Box>
        ) : null}
      </Box>
    </form>
  );
});

AuthForm.propTypes = {};

export default AuthForm;
