import React, { FC, useCallback, useEffect, useState } from 'react';

import Grid from '@mui/system/Unstable_Grid';
import Stack from '@mui/system/Stack';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Box } from '@mui/system';
import { observer } from 'mobx-react-lite';
import MaterialTabs, { tabsClasses } from '@mui/material/Tabs';
import { FieldSwitch } from '@/shared/ui/Fields/components/FieldSwitch/FieldSwitch';
import { CodeEditor } from '@/shared/ui';
import { useResolve } from '@/hooks/useResolve';
import { BlockService } from '@/entities/Block/services/BlockService';
import { RegistrableValues } from '@/shared/lib/types';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { Exchange } from '@/entities/Block/types';
import { FlowUpdaterService } from '@/entities/Flow/services/FlowUpdaterService';
import { BlockMonitoringService } from '@/entities/Block/services/BlockMonitoringService';
import { Tab } from '@/shared/ui/Tab/Tab';
import { Tabs } from '@/entities/Block/containers/MessageQueue/constants';
import Typography from '@/shared/ui/Typography';
import { TabPanel } from '@/shared/ui/TabPanel/TabPanel';

export interface SendMessageFormProps {}

export const SendMessageForm: FC<SendMessageFormProps> = observer(() => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { control, handleSubmit } = useForm();
  const { get } = useSearchParamsTemplate();
  const { flowId } = useParams();
  const [activeTab, setActiveTab] = React.useState<Tabs>(Tabs.body);
  const handleTabClick = useCallback(
    (tab: Tabs) => () => {
      setActiveTab(tab);
    },
    []
  );

  const blockId = get(SearchParams.BlockId);

  const blockService = useResolve(BlockService, { [RegistrableValues.FlowId]: flowId });
  const flowUpdaterService = useResolve(FlowUpdaterService, {
    [RegistrableValues.FlowId]: flowId,
  });
  const blockMonitoringService = useResolve(BlockMonitoringService, {
    [RegistrableValues.FlowId]: flowId,
    [RegistrableValues.BlockId]: blockId,
  });

  const onSubmit = useCallback(
    async (data: unknown) => {
      if (isSubmitting) return;
      setIsSubmitting(true);
      const body = {
        ...(data as Exchange),
        isExecuteBlockForcible: true,
        isIntegrationTest: false,
        blockId: flowUpdaterService.areThereChanges ? undefined : blockId,
        block: flowUpdaterService.areThereChanges
          ? blockService.blocks?.entities[blockId || '']
          : undefined,
      };
      try {
        if (body.inputHeadersString) {
          body.inputHeaders = JSON.parse(body.inputHeadersString);
        }
      } catch (e) {}
      try {
        if (body.inputPathParamsString) {
          body.inputPathParams = JSON.parse(body.inputPathParamsString);
        }
      } catch (e) {}
      try {
        if (body.inputQueryParamsString) {
          body.inputQueryParams = JSON.parse(body.inputQueryParamsString);
        }
      } catch (e) {}

      await blockService.sendMessage(body);

      await blockMonitoringService.get();
    },
    [blockId, blockMonitoringService, blockService, flowUpdaterService.areThereChanges]
  );

  useEffect(
    () => () => {
      if (blockService.sendMessageResponse?.outputBody?.stringBody) {
        blockService.sendMessageResponse.outputBody.stringBody = '';
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (blockService.isLoadingSendMessage === false) {
      setIsSubmitting(false);
    }
  }, [blockService.isLoadingSendMessage]);

  const renderTabs = () => (
    <MaterialTabs
      variant='scrollable'
      scrollButtons='auto'
      aria-label='scrollable auto tabs'
      sx={{
        [`& .${tabsClasses.scrollButtons}`]: {
          '&.Mui-disabled': { opacity: 0.3 },
        },
      }}
    >
      <Tab isActive={activeTab === Tabs.body} onClick={handleTabClick(Tabs.body)}>
        <Typography size='small'>Сообщение</Typography>
      </Tab>
      <Tab isActive={activeTab === Tabs.headers} onClick={handleTabClick(Tabs.headers)}>
        <Typography size='small'>Заголовки</Typography>
      </Tab>
      <Tab isActive={activeTab === Tabs.pathParams} onClick={handleTabClick(Tabs.pathParams)}>
        <Typography size='small'>Параметры пути</Typography>
      </Tab>
      <Tab isActive={activeTab === Tabs.queryParams} onClick={handleTabClick(Tabs.queryParams)}>
        <Typography size='small'>Параметры запроса</Typography>
      </Tab>
    </MaterialTabs>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2} justifyContent='space-between'>
        <Grid container paddingX={2} alignItems='flex-start' height='max-content'>
          <Grid xs={6}>
            <Stack gap={2}>
              <Stack gap={2}>
                <Typography variant='h2' fontSize='1.125rem'>
                  Пакет данных
                </Typography>
                <FieldSwitch
                  name='isSendOutputConnect'
                  label='Передать в исходящие коннекторы для отправки'
                  error={undefined}
                  control={control}
                  defaultValue={true as unknown as string}
                />
              </Stack>
              <Stack>{renderTabs()}</Stack>
              <TabPanel index={Tabs.body} value={activeTab}>
                <Controller
                  control={control}
                  name='inputBody.stringBody'
                  render={({ field }) => (
                    <CodeEditor
                      title='Сообщение'
                      value={blockService.sendMessageRequest?.inputBody?.stringBody || field.value}
                      isFormatted={false}
                      defaultLanguage='json'
                      onChange={(value) => field.onChange(value)}
                    />
                  )}
                />
              </TabPanel>
              <TabPanel index={Tabs.headers} value={activeTab}>
                <Controller
                  control={control}
                  name='inputHeadersString'
                  render={({ field }) => (
                    <CodeEditor
                      title='Заголовки'
                      value={blockService.sendMessageRequest?.inputHeadersString || field.value}
                      isFormatted={false}
                      defaultLanguage='json'
                      onChange={(value) => field.onChange(value)}
                    />
                  )}
                />
              </TabPanel>
              <TabPanel index={Tabs.pathParams} value={activeTab}>
                <Controller
                  control={control}
                  name='inputPathParamsString'
                  render={({ field }) => (
                    <CodeEditor
                      title='Заголовки'
                      value={blockService.sendMessageRequest?.inputPathParamsString || field.value}
                      isFormatted={false}
                      defaultLanguage='json'
                      onChange={(value) => field.onChange(value)}
                    />
                  )}
                />
              </TabPanel>
              <TabPanel index={Tabs.queryParams} value={activeTab}>
                <Controller
                  control={control}
                  name='inputQueryParamsString'
                  render={({ field }) => (
                    <CodeEditor
                      title='Заголовки'
                      value={blockService.sendMessageRequest?.inputQueryParamsString || field.value}
                      isFormatted={false}
                      defaultLanguage='json'
                      onChange={(value) => field.onChange(value)}
                    />
                  )}
                />
              </TabPanel>
            </Stack>
          </Grid>
          <Grid xs={6}>
            <Stack gap={4}>
              <Stack gap={2.5}>
                <Typography variant='h2' fontSize='1.125rem'>
                  Ответ от текущего блока
                </Typography>
                <Box height='64px' />
              </Stack>
              <CodeEditor
                readonly
                key={blockService.sendMessageResponse?.outputBody?.outputBody || ''}
                loading={blockService.isLoadingSendMessage}
                title='Ответ от текущего блока'
                defaultLanguage='json'
                value={blockService.sendMessageResponse?.outputBody?.outputBody || ''}
              />
            </Stack>
          </Grid>
        </Grid>
        <Stack alignItems='flex-end' paddingX={2.5} paddingBottom={2.5}>
          <Box width={216}>
            <Button
              type='submit'
              loading={blockService.isLoadingSendMessage}
              variant={ButtonVariants.Popup}
              disabled={isSubmitting}
            >
              Отправить
            </Button>
          </Box>
        </Stack>
      </Stack>
    </form>
  );
});

SendMessageForm.displayName = 'SendMessageForm';
