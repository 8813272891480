import { FC } from 'react';

import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';
import debounce from 'lodash/debounce';
import Stack from '@mui/system/Stack';
import Box from '@mui/system/Box';

import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { Connect } from "@/entities/Connect/types";
import { notify } from "@/shared/ui/Toast/notify";

export interface UpdateSkeletonWrapperProps {
  isSendSkeletonForEnrichByButton: boolean;
  isSendSkeletonForEnrichByDebounce: boolean;
  sx?: object;
  render(onChange: () => void): JSX.Element;
}

export const UpdateSkeletonWrapper: FC<UpdateSkeletonWrapperProps> = observer(
  ({
    isSendSkeletonForEnrichByButton,
    isSendSkeletonForEnrichByDebounce,
     sx,
    render,
  }) => {
    const { getValues, getSkeleton, updateSkeleton } = useFormContext();

    if (!updateSkeleton || !getSkeleton) return render(undefined);

    const updateForm = async (connect: Connect) => {
      try {
        const data = await getSkeleton(connect);
        updateSkeleton(data);
      } catch {
        notify.error('Не удалось обновить форму');
      }
    }

    const debouncedChange = debounce(() => {
      if (!isSendSkeletonForEnrichByDebounce) return;
      updateForm(getValues() as Connect);
    }, 600);

    const onChange = () => {
      debouncedChange();
    };

    const handleEnrichButtonClick = () => {
      updateForm(getValues() as Connect);
    };

    return (
      <Stack alignItems='flex-end' gap={2} direction='row' sx={sx}>
        {render(onChange)}
        {isSendSkeletonForEnrichByButton && (
          <Box width={320}>
            <Button
              doesUseInFormOrTable
              variant={ButtonVariants.Secondary}
              onClick={handleEnrichButtonClick}
            >
              Применить
            </Button>
          </Box>
        )}
      </Stack>
    );
  }
);

UpdateSkeletonWrapper.displayName = 'UpdateSkeletonWrapper';
