import { FC, memo, useCallback } from 'react';

import Tooltip from '@/shared/ui/Tooltip';
import { Box } from '@mui/system';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { SettingsIcon } from '@/shared/ui/Icons/SettingsIcon/SettingsIcon';
import { container } from 'tsyringe';

import { WSDLService } from '@/entities/WSDLService/features/api/WSDLService';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

const wsdlService = container.resolve(WSDLService);

export interface UpdateWSDLAppButtonProps {
  wsdlAppId: string;
}

export const UpdateWSDLAppButton: FC<UpdateWSDLAppButtonProps> = memo(({ wsdlAppId }) => {
  const { set } = useSearchParamsTemplate();
  const handleClick = useCallback(() => {
    wsdlService.open = true;
    wsdlService.selectedId = wsdlAppId;
    set([SearchParams.ShowEditWSDLForm, { [SearchParams.WSDLId]: wsdlAppId }]);
  }, [wsdlAppId]);

  return (
    <Tooltip title='WSDL-сервис' placement='top'>
      <Box>
        <Button
          circle
          size={ButtonSizes.Small}
          variant={ButtonVariants.Outlined}
          onClick={handleClick}
        >
          <SettingsIcon />
        </Button>
      </Box>
    </Tooltip>
  );
});

UpdateWSDLAppButton.displayName = 'UpdateWSDLAppButton';
