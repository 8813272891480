import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { last } from 'lodash';

import { FileUploadService } from "./FileUploadService";
import { Attachment } from './types';

@singleton()
export class EditorUploadStore {
  service: FileUploadService;
  sync(attachments: Attachment[]): void;
  selectedFile: Attachment;
  isLoadingFiles: boolean;


  constructor() {
    this.service = null;
    this.sync = null;
    this.selectedFile = null;
    this.isLoadingFiles = false;

    makeAutoObservable(this);
  }

  setup(
    attachment: Attachment,
    fileUploadService: FileUploadService,
    onChange: (attachments: Attachment[]) => void,
  ) {
    if (attachment.guid === this.selectedFile?.guid) {
      this.selectedFile = null;
      this.service = null;
      return;
    }
    this.service = fileUploadService;
    this.selectedFile = attachment;
    this.sync = onChange;
  }

  update(data: { fileName: string, url: string, downloadHeaders: string }) {
    const { selectedFile } = this;
    if (!selectedFile) return;
    const attachment = this.service.attachments.find(({ guid }) => guid === selectedFile.guid);
    attachment.fileName = data.fileName;
    attachment.url = data.url;
    if (data.downloadHeaders) {
      attachment.downloadHeaders = data.downloadHeaders;
    }
    this.sync(this.service.attachments);
  }

  insert(data: Attachment) {
    const fileName = data.fileName || last(data.url.split('/')).slice(0, 20) || 'webFile';
    this.selectedFile = { ...data, fileName };
    this.service.attachments.push(this.selectedFile);
    this.sync(this.service.attachments);
  }
}
