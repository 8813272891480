import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { useFieldArray, UseFormGetValues } from 'react-hook-form';

import cn from "classnames";
import { Box, AppBar, Tab, Tabs as MuiTabs } from "@mui/material";
import { tabsClasses } from "@mui/material/Tabs";
import { Stack } from "@mui/system";
import AddIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/HighlightOffOutlined';

import { amendDraggable } from "@/shared/lib/extendDndkit";
import { Label } from "@/shared/ui/Label/Label";
import { template } from '@/shared/lib/template';
import { ListProps } from "./List";

import styles from "./Tabs.module.scss";


export interface TabsProps extends ListProps {
  getValues: UseFormGetValues<any>;
  labelTmpl?: string;
  draggable?: boolean;
}

export const Tabs: FC<PropsWithChildren<TabsProps>> = ({
  control,
  getValues,
  name,
  label,
  defaultValue = {},
  description,
  renderElement,
  // eslint-disable-next-line no-template-curly-in-string
  labelTmpl = '№${o.idx + 1}',
}) => {
  const {
    fields: arrayFields,
    append,
    remove,
  } = useFieldArray({
    name,
    control,
  });

  const [activeTabKey, setActiveTabKey] = useState(0);
  const isActiveTab = (idx: number) => idx === activeTabKey;
  const getLabel = useCallback((idx: number) => {
    const compiled = template(labelTmpl);
    return compiled({ ...getValues(`${name}[${idx}]`), idx });
  }, [labelTmpl]);
  const activeTab = arrayFields[activeTabKey];

  const addItem = useCallback(() => {
    append({ ...defaultValue });
    setActiveTabKey(arrayFields.length);
  }, [arrayFields.length, append]);

  useEffect(() => {
    if (arrayFields.length) {
      setActiveTabKey(0);
      return;
    }
    addItem();
  }, []);

  const handleChangeActiveTab = (event: React.SyntheticEvent, newTab: number) => {
    if (isActiveTab(newTab)) return;

    if (newTab !== -100) {
      setActiveTabKey(newTab);
      return;
    }
    addItem();
  };

  const handleRemove = (idx: number) => () => {
    remove(idx);
    const newActive = arrayFields.length - idx > 1 ? idx : idx - 1;
    setActiveTabKey(newActive);
  }

  const renderTab = (tab: { id: string }, idx: number) => (
    <Tab
      disableRipple
      className={cn({ 'Mui-selected': isActiveTab(idx) })}
      label={getLabel(idx)}
      value={idx}
      key={tab.id}
      draggable
      icon={isActiveTab(idx) && <CloseIcon fontSize="small" onClick={handleRemove(idx)} />}
      iconPosition="end"
    />
  );

  return (
    <Box className={styles.root} {...amendDraggable(true)}>
      {label && (
        <Label tooltip={description}>
          <h3 className={styles.title}>{label}</h3>
        </Label>
      )}
      <AppBar position="static" color="default" className={styles.tabs}>
        <MuiTabs
          value={activeTabKey}
          onChange={handleChangeActiveTab}
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >
          {arrayFields.map(renderTab)}
          <Tab
            className="add-trigger"
            disableRipple
            icon={<AddIcon />}
            value={-100}
          />
        </MuiTabs>
      </AppBar>
      {activeTab && (
        <div role="tabpanel" id={activeTab.id} key={activeTab.id}>
          <Stack gap={2} flexGrow={1} paddingTop={2}>
            {renderElement(activeTabKey)}
          </Stack>
        </div>
      )}
    </Box>
  );
}
