import { FC, memo, useEffect, useState } from 'react';
import { container } from 'tsyringe';
import { ErrorWrapper } from '@/shared/ui';
import { Loader } from '@/shared/ui/Loader/Loader';
import { WSDLList } from '@/entities/WSDLService/entities/WSDLList/WSDLList';
import { WSDLService } from '@/entities/WSDLService/features/api/WSDLService';
import { WSDLDialogForm } from '@/entities/WSDLService/WSDLForm/WSDLDialogForm';
import styles from './WSDLPage.module.scss';
import { DeleteWSDLAppDialog } from '@/entities/WSDLService/DeleteWSDLAppDialog/DeleteWSDLAppDialog';
import Paper from "@mui/material/Paper";

const wsdlService = container.resolve(WSDLService);

export interface WSDLPageProps {}

export const WSDLPage: FC<WSDLPageProps> = memo(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [thereIsError, setThereIsError] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    wsdlService
      .getWSDLApps()
      .then(() => setThereIsError(false))
      .catch(() => setThereIsError(true))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <Loader width={60} height={60} />;
  }

  if (thereIsError) {
    return (
      <ErrorWrapper title='Не удалось получить данные' repeat={wsdlService.getWSDLApps} />
    );
  }

  return (

    <Paper
      elevation={0}
      sx={{ p: 0, height: '100%', position: 'relative', display: 'flex', justifyContent: 'space-between' }}
    >
      <div className={styles.root}>
        <WSDLList />
      </div>
      <WSDLDialogForm />
      <DeleteWSDLAppDialog />
    </Paper>
  );
});

WSDLPage.displayName = 'WSDLPage';
