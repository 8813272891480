import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

export const dateParser = (date) => {
  if (isEmpty(date)) return null;
  const start = date[0] ? dayjs(dayjs(date[0]?.$d).valueOf()) : null;
  const end = date[1] ? dayjs(dayjs(date[1]?.$d).valueOf()) : null;

  return [start, end];
};
