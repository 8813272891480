export const MESSAGE_FILTER = [
  {
    value: 'isSuccess',
    label: 'Успешно обработанные сообщения',
  },
  {
    value: 'isFailed',
    label: 'Сообщения с ошибками',
  },
  {
    value: 'isLast',
    label: 'Переотправленные сообщения',
  },
];
export const defaultOption = ['isFailed', 'isSuccess'];

export const defaultValues = {
  isSuccess: true,
  isFailed: true,
  isLast: false,
  isText: false,
  endDate: null,
  searchText: '',
};
export const defaultFilter = [
  {
    typeCriteria: 'and',
    filterList: [
      {
        name: 'isLast',
        operator: 'is',
        value: true,
      },
    ],
  },
];

export const filterBodyIsSuccess = {
  typeCriteria: 'and',
  filterList: [
    {
      name: 'isLast',
      operator: 'is',
      value: true,
    },
    {
      name: 'isFailed',
      operator: 'is',
      value: false,
    },
  ],
};

export const filterBodyIsLast = {
  typeCriteria: 'and',
  filterList: [
    {
      name: 'isLast',
      operator: 'is',
      value: true,
    },
  ],
};

export const filterBodyIsFailed = {
  typeCriteria: 'and',
  filterList: [
    {
      name: 'isFailed',
      operator: 'is',
      value: true,
    },
  ],
};
export const filterBodyIsFailedIsLast = {
  typeCriteria: 'and',
  filterList: [
    {
      name: 'isLast',
      operator: 'is',
      value: true,
    },
    {
      name: 'isFailed',
      operator: 'is',
      value: true,
    },
  ],
};
