import { FC, useCallback, useState } from 'react';

import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { SubmitHandler, useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import { ExternalService } from '@/entities/ExternalApp';
import { Resizable } from '@/shared/ui';
import { Drawer } from '@/shared/ui/Drawer/Drawer';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { notify } from '@/shared/ui/Toast/notify';

import { UpsertExternalAppService } from '../../api/UpsertExternalAppService';

import styles from './UpsertExternalAppDialogForm.module.scss';

const upsertExternalAppService = container.resolve(UpsertExternalAppService);

export interface UpsertExternalAppFormProps {}

export const UpsertExternalAppDialogForm: FC<UpsertExternalAppFormProps> = observer(() => {
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, reset } = useForm<ExternalService>({
    values: upsertExternalAppService.currentExternalApp,
  });

  const title = upsertExternalAppService.selectedId
    ? 'Изменить внешний сервис'
    : 'Добавить внешний сервис';
  const buttonSubmitText = upsertExternalAppService.selectedId ? 'Изменить' : 'Добавить';

  const handleClose = useCallback(() => {
    upsertExternalAppService.open = false;
    upsertExternalAppService.selectedId = null;

    reset({ name: '', description: '', serviceUrl: '' });
  }, [reset]);

  const handleValidSubmit = useCallback<SubmitHandler<ExternalService>>(
    async (externalService) => {
      try {
        setIsLoading(true);

        await upsertExternalAppService.upsertExternalApp(externalService);

        handleClose();

        if (upsertExternalAppService.selectedId) {
          notify.success('Внешний сервис изменен');
        } else {
          notify.success('Внешний сервис добавлен');
        }
      } catch {
        if (upsertExternalAppService.selectedId) {
          notify.error('Не удалось изменить внешний сервис');
        } else {
          notify.error('Не удалось добавить внешний сервис');
        }
      } finally {
        setIsLoading(false);
      }
    },
    [handleClose]
  );
  if (!upsertExternalAppService.open) return null;

  return (
    <Resizable maxWidth={window.innerWidth - 260} initialWidth={434} minWidth={434}>
      <Drawer id='handlerTimer' className={styles.drawer} title={title} onClose={handleClose}>
        <form onSubmit={handleSubmit(handleValidSubmit)} className='p-5'>
          <Stack paddingBottom='100px' gap={2} padding={2}>
            <FieldInput error='' name='name' label='Название сервиса' register={register} />
            <FieldInput textarea error='' name='description' label='Описание' register={register} />
            <FieldInput
              textarea
              error=''
              name='serviceUrl'
              label='URL до сервиса'
              register={register}
            />
          </Stack>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
              padding: 5,
            }}
          >
            <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
              Отмена
            </Button>
            <Button type='submit' variant={ButtonVariants.Popup} loading={isLoading}>
              {buttonSubmitText}
            </Button>
          </div>
        </form>
      </Drawer>
    </Resizable>
  );
});

UpsertExternalAppDialogForm.displayName = 'UpsertExternalAppForm';
