import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';

import { Normalized } from '@/shared/lib/normalize';

import { CounterTimeline } from '../components/DashboardPanel/CounterPanel/types';
import { FlowStatistic } from "../components/DashboardPanel/CounterStatistic/types";

@singleton()
export class CounterStore {
  counterTimelines: Normalized<CounterTimeline>;

  statistic: Normalized<FlowStatistic>

  constructor() {
    this.counterTimelines = { ids: [], entities: {} };
    this.statistic = { ids: [], entities: {} };

    makeAutoObservable(this);
  }

}
