import { ExecutorHistory } from "@/entities/Connect/types";
import { collapseNodes } from "@/entities/MessageTracing/adapters/collapseNodes";
import { BlockTree } from "./types";
import { getNodesForBlockTracing } from "./getNodesForBlockTracing";
import { getEdgesForBlockTracing } from "./getEdgesForBlockTracing";

export const buildBlockTree = (executorHistory: ExecutorHistory): BlockTree => {
  const nodes = getNodesForBlockTracing(executorHistory);
  const edges = getEdgesForBlockTracing(executorHistory);

  return collapseNodes(nodes, edges);
}
