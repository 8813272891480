import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Normalized } from '@/shared/lib/normalize';

import { Tenant, TenantBody } from './types';

@singleton()
export class TenantStore {
  tenants: Normalized<Tenant> | null;

  lastPage: number;

  isLoading: boolean;

  showSaveForm: boolean;

  confirmAddTenant: boolean;

  bodyTenant: TenantBody;

  confirmationData: TenantBody | null;

  constructor() {
    this.tenants = null;
    this.lastPage = 0;
    this.isLoading = false;
    this.showSaveForm = false;
    this.confirmAddTenant = false;
    this.bodyTenant = null;
    this.confirmationData = null;

    makeAutoObservable(this);
  }
}
