import { FC } from "react";

import { List } from './view/List';
import { Tabs, TabsProps } from "./view/Tabs";

interface FieldListProps extends TabsProps {
  type?: 'list' | 'tabs';
}

export const FieldList: FC<FieldListProps> = ({ type = 'list', ...props }) => {
  if (type === 'tabs') {
    return (
      <Tabs {...props} />
    );
  }

  return (
    <List
      {...props}
    />
  );
}