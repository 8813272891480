import { FC, memo } from 'react';

import Grid from '@mui/system/Unstable_Grid';
import { FieldSelect } from '@/shared/ui/Fields/components/FieldSelect/FieldSelect';
import {
  arrayEmptyRuleByArray,
  arrayStatus,
  arrayStatusByObjectByArray,
  arrayType,
} from '@/shared/ui/Fields/components/MappingData/constants';
import { Controller } from 'react-hook-form';
import { MapObject } from '@/shared/ui/Fields/components/MappingData/MapObject';
import {FieldInput} from "@/shared/ui/Fields/components/FieldInput/FieldInput";
import {FieldAutocomplete} from "@/shared/ui/Fields/components/FieldAutocomplete/FieldAutocomplete";

export interface MapArrayToObjectProps {
  control: any;
  index: number;
  name: string;
  register: any;
}

export const MapArrayToObject: FC<MapArrayToObjectProps> = memo(({ control, name, index, register }) => {
  const renderTypeDataArray = () => {};

  return (
    <>
      <Grid xs={12} sm={6} lg={4} xl={3}>
        <FieldAutocomplete
          label='Относительный путь для получения списка атрибутов'
          control={control}
          error=''
          name={`${name}.${index}.targetArrayVariablePath`}
          register={register}
          multiple={true}
          options={[]}
        />
      </Grid>
      <Grid xs={12} sm={6} lg={4} xl={3}>
        <FieldAutocomplete
          label='Относительный путь для получения наименования атрибута'
          control={control}
          error=''
          multiple={true}
          name={`${name}.${index}.targetNamePath`}
          register={register}
          options={[]}
        />
      </Grid>
      <Grid xs={12} sm={6} lg={4} xl={3}>
        <FieldAutocomplete
          label='Относительный путь для получения значения атрибута'
          control={control}
          error=''
          name={`${name}.${index}.targetValuePath`}
          register={register}
          multiple={true}
          options={[]}
        />
      </Grid>
      <Grid xs={12} sm={6} lg={4} xl={3}>
        <FieldAutocomplete
          label='Относительный путь для получения типа атрибута'
          control={control}
          error=''
          name={`${name}.${index}.targetTypePath`}
          register={register}
          multiple={true}
          options={[]}
        />
      </Grid>
    </>
  );
});

MapArrayToObject.displayName = 'MapArrayToObject';
