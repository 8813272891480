import React, { FC, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { Control, Controller } from 'react-hook-form';
import Stack from '@mui/system/Stack';
import Grid from '@mui/system/Unstable_Grid';
import MaterialTabs, { tabsClasses } from '@mui/material/Tabs';
import { motion } from 'framer-motion';
import { CodeEditor } from '@/shared/ui';
import { FieldSelect } from '@/shared/ui/Fields/components/FieldSelect/FieldSelect';
import { ErrorPacket } from '@/entities/Block/types';
import Typography from '@/shared/ui/Typography';
import { TabPanel } from '@/shared/ui/TabPanel/TabPanel';
import { MESSAGE_STATUSES, Tabs } from '../../constants';
import { Tab } from '@/shared/ui/Tab/Tab';

import styles from './MessageQueueForm.module.scss';

export interface MessageQueueFormProps {
  control: Control<ErrorPacket>;
  errors: Record<string, unknown>;
}

export const MessageQueueForm: FC<MessageQueueFormProps> = observer(({ control, errors }) => {
  const [activeTab, setActiveTab] = React.useState<Tabs>(Tabs.body);
  const handleTabClick = useCallback(
    (tab: Tabs) => () => {
      setActiveTab(tab);
    },
    [],
  );

  const paramTabs = [
    { tab: Tabs.body, name: 'inputBody.stringBody', title: 'Сообщение', isString: true },
    { tab: Tabs.headers, name: 'inputHeaders', title: 'Заголовки' },
    { tab: Tabs.pathParams, name: 'inputPathParams', title: 'Параметры пути' },
    { tab: Tabs.queryParams, name: 'inputQueryParams', title: 'Параметры запроса' },
  ];

  const renderTabs = () => {
    return (<MaterialTabs
      variant='scrollable'
      scrollButtons='auto'
      aria-label='scrollable auto tabs'
      sx={{
        [`& .${tabsClasses.scrollButtons}`]: {
          '&.Mui-disabled': { opacity: 0.3 },
        },
      }}
    >
      {paramTabs.map(({ tab, title }) => (
        <Tab isActive={activeTab === tab} onClick={handleTabClick(tab)}>
          <Typography size={'small'}>
            {title}
          </Typography>
        </Tab>
      ))}
    </MaterialTabs>);
  };

  const renderTabsPanels = () => (
    <>
      {paramTabs.map(({ tab, name, title, isString = false }) => (
        <TabPanel index={tab} value={activeTab}>
          <Controller
            control={control}
            name={name}
            render={({ field }) => field.value !== undefined ? (
              <CodeEditor
                title={title}
                value={isString ? field.value : JSON.stringify(field.value)}
                defaultLanguage='json'
                onChange={(value) => field.onChange(isString ? value : JSON.parse(value ?? ''))}
              />
            ) : (
              <span />
            )}
          />
        </TabPanel>
      ))}
    </>
  );

  return (
    <motion.div
      className={styles.root}
      variants={{
        open: { opacity: 1 },
        close: { opacity: 0 },
      }}
      initial='close'
      animate='open'
      exit='close'
      transition={{ ease: 'easeInOut' }}
    >
      <form>
        <Grid container>
          <Grid width={312} xs={3}>
            <FieldSelect
              error={errors.status}
              name='status'
              label='Статус'
              control={control}
              options={MESSAGE_STATUSES}
            />
          </Grid>
        </Grid>

        <Grid xs={12}>
          <Stack gap={2}>
            <Stack>
              {renderTabs()}
            </Stack>
            {renderTabsPanels()}
          </Stack>
        </Grid>
      </form>
    </motion.div>
  );
});

MessageQueueForm.displayName = 'MessageQueueForm';
