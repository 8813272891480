import { FC, useCallback } from 'react';

import { Stack } from '@mui/system';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { cutText } from '@/shared/lib/cutText';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { ACTIONS_SEARCH_PARAMS, SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { useResolve } from '@/hooks/useResolve';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { RegistrableValues } from '@/shared/lib/types';

import { Button } from '../../../shared/ui/Button';
import { BlockService } from '../services/BlockService';

export const BlockDeletionModalContainer: FC = observer(() => {
  const { flowId } = useParams();
  const { get, has, remove } = useSearchParamsTemplate();

  const blockService = useResolve(BlockService, { [RegistrableValues.FlowId]: flowId || '' });

  const isOpenDeleteModal = has(SearchParams.ShowBlockDeleteModal);
  const blockId = get(SearchParams.BlockId);

  const handleDialogClose = useCallback(() => {
    blockService.isBlockDelete = false;
    remove([SearchParams.ShowBlockDeleteModal]);
  }, [remove]);

  const handleDialogOk = useCallback(() => {
    if (blockId) {
      blockService.isBlockDelete = true;
      blockService.deleteBlock(blockId);
    }
    handleDialogClose();
    remove(ACTIONS_SEARCH_PARAMS);
  }, [blockId, blockService, handleDialogClose]);

  return isOpenDeleteModal ? (
    <Dialog fullWidth open onClose={handleDialogClose}>
      <DialogTitle onClose={handleDialogClose}>Удалить блок</DialogTitle>
      <DialogContent>
        <Stack spacing={5}>
          <div>
            <p>
              <span>Вы точно хотите удалить блок</span>{' '}
              <span title={blockService.blocks?.entities[blockId || '']?.name}>
                ”{cutText(blockService.blocks?.entities[blockId || '']?.name || '', 20, true)}”?
              </span>
            </p>
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant={ButtonVariants.Secondary} onClick={handleDialogClose}>
          Отмена
        </Button>
        <Button variant={ButtonVariants.Popup} onClick={handleDialogOk}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
});
