import { FC, useCallback, useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { isEmpty } from 'lodash';
import { Drawer } from '@/shared/ui/Drawer/Drawer';
import {
  CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE,
  SearchParams,
  useSearchParamsTemplate,
} from '@/hooks/useTemplateSearchParams';
import { ConnectorToolbar } from '@/entities';
import { NotFoundConnectorCatcher, NotFoundConnectorDrawer } from '@/features/NotFoundConnector';
import { AllConnectService } from '@/entities/Connect/services/AllConnectService';
import { useResolve } from '@/hooks/useResolve';
import { IndexedDBStore } from '@/store/IndexedDBStore/IndexedDBStore';
import { IndexedDBService } from '@/service/indexedDB';

import { ConnectFormContainer } from './ConnectFormContainer/ConnectFormContainer';

const allConnectService = container.resolve(AllConnectService);

export const ConnectDrawerContainer: FC = observer(() => {
  const { remove, has, get, set } = useSearchParamsTemplate();
  const connectorId = get(SearchParams.ConnectorId);
  const connectorName = get(SearchParams.ConnectorName);
  const connectorType = get(SearchParams.ConnectorType);
  const indexedDBService = container.resolve(IndexedDBService);
  const indexedDBStore = useResolve(IndexedDBStore);
  const blockId = get(SearchParams.BlockId);
  const connectorNameLocale = allConnectService.parameterByName(
    connectorName,
    connectorType
  )?.localeName;
  const keyForIndexedDB = `fieldData${blockId}.${connectorName}`;
  /** Сохраненные данные по коннектору в indexedDB */
  const idbConnect = JSON.parse(JSON.stringify(indexedDBStore?.valuesForSaveIdb));

  const entityName: string =
    connectorType === 'processor' || connectorType === 'exProcessor' ? 'обработчик' : 'коннектор';
  const title = useMemo(
    () =>
      connectorId
        ? `Изменить ${entityName} ${connectorNameLocale}`
        : `Создать ${entityName} ${connectorNameLocale}`,
    [connectorId, connectorNameLocale, entityName]
  );

  const handleDrawerClose = useCallback(() => {
    const date = new Date();
    if (!isEmpty(idbConnect)) {
      indexedDBService.saveFieldData(keyForIndexedDB, idbConnect, date);
    }
    indexedDBStore.id = null;
    indexedDBStore.connector = null;
    indexedDBStore.indexedDbValues = null;
    indexedDBStore.valuesForSaveIdb = null;
    set(SearchParams.ShowDeleteUnSaveConnectModal); // fixme on close this drawer also close showBlockForm drawer

    remove(CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE);
  }, [idbConnect]);

  return has(SearchParams.ShowConnectorForm) ? (
    <div className='grid-cols-1'>
      <NotFoundConnectorCatcher
        thenElement={
          <Drawer
            largeTitle
            title={title}
            id='handlerTimer'
            titleAddon={
              <ConnectorToolbar
                blockId={get(SearchParams.BlockId)}
                keyForIndexedDB={keyForIndexedDB}
              />
            }
            onClose={handleDrawerClose}
          >
            <ConnectFormContainer key={`${blockId}${connectorName}`} />
          </Drawer>
        }
        catchElement={<NotFoundConnectorDrawer />}
      />
    </div>
  ) : null;
});
