import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';

import { WDay, Interval } from "@/entities/Dashboard/types";
import { intervalMap } from '../components/HistoryManager/constants';
import { parse } from "@/entities/Dashboard/widgets/DashboardPlatform/components/HistoryManager/datemath";

@singleton()
export class PeriodStore {

  period: [WDay, WDay];

  interval: string;

  constructor() {
    this.period = [parse('now-1h'), parse('now', true)];
    this.interval = '30s';

    makeAutoObservable(this);
  }

}
