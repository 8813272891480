import { observer } from "mobx-react-lite";

import { container } from "tsyringe"

import { Drawer } from "@/shared/ui/Drawer/Drawer";
import { EditorUploadStore } from "../api/EditorUploadStore";
import { SettingsForm } from "./SettingsForm";


const editorUploadStore = container.resolve(EditorUploadStore);

export const SettingsDrawer = observer(() => {
  const { selectedFile } = editorUploadStore;

  if (!selectedFile) return null;

  return (
    <Drawer
      title="Редактировать файл"
      width={360}
      onClose={() => { editorUploadStore.selectedFile = null }}
    >
      <SettingsForm />
    </Drawer>
  );
});
