import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';
import MenuItem from '@mui/material/MenuItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import MenuList from '@mui/material/MenuList';

import styles from '../SettingsDrawer.module.scss';
import { MenuItemLink } from '@/pages/SettingsPage/ui/components/MenuItemLink';

function Submenu({
  isOpen,
  expanded,
  items,
}: {
  isOpen: boolean,
  expanded: boolean,
  items: { [key: string]: any }[],
}) {
  const location = useLocation();
  const activePath = location.pathname;

  return (
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <MenuList component="div" disablePadding>
        {items.map(({
          slug,
          link,
          label: smlabel,
        }) => (
          <MenuItem
            key={slug}
            className={cn(styles['menu-item'], styles.submenu)}
          >
            <ListItemButton
              className={cn({
                  expand: expanded,
                  active2: activePath === link,
                })}
              component={NavLink}
              to={link}
            >
              <Typography
                className={styles.label}
                variant="inherit"
                noWrap
              >
                {smlabel}
              </Typography>
            </ListItemButton>
          </MenuItem>
          ))}
      </MenuList>
    </Collapse>
  );
}
interface MenuItemSubProps {
  expanded: boolean,
  isOpen: boolean,
  handler: () => void,
  data: { [key: string]: any },
}

export function MenuItemSub ({
  expanded,
  isOpen,
  handler,
  data,
}: MenuItemSubProps) {
  const {
    slug,
    items,
  } = data;
  return (
    <>
      <MenuItemLink
        key={slug}
        expanded={expanded}
        isOpen={isOpen}
        data={data}
        handler={handler}
      />
      <Submenu
        key={slug}
        expanded={expanded}
        isOpen={expanded && isOpen}
        items={items}
      />
    </>
  )
}