import React, { FC, memo } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";

import { Stack } from '@mui/system';
import { Handle, Position } from 'reactflow';
import cn from 'classnames';
import { Dropdown, MenuProps } from "antd";
import { EnterOutlined } from "@ant-design/icons";
import { container } from "tsyringe";

import { DisplayMessageService } from "@/entities/MessageTracing/api/DisplayMessageService";
import { ExecutorHistory } from "@/entities/Connect/types";
import { SearchParams } from '@/hooks/useTemplateSearchParams';

import styles from './BlockNode.module.scss';

export interface BlockNodeProps {
  id: string;
  blockId: string;
  name: string;
  flowId: string;
  flowName: string;
  value: string;
  dateExecute?: string;
  timeExecute?: number;
  executorHistory?: ExecutorHistory;
}

const displayMessageService = container.resolve(DisplayMessageService);

const items: MenuProps['items'] = [
  {
    label: 'Перейти к потоку',
    key: 'toFlow',
    icon: <EnterOutlined style={{ fontSize: '1em' }} />,
  },
];

export const BlockNode: FC<{ data: BlockNodeProps }> = memo(
  ({
    data: {
      blockId,
      name,
      flowId,
      flowName,
      id,
      executorHistory,
    },
  }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const onSelect = () => {
      displayMessageService.setExecutorHistory(executorHistory)
    }

    const actionsMap: { [index: string]: Function } = {
      toFlow: () => {
        searchParams.set(SearchParams.BlockId, blockId)
        searchParams.delete(SearchParams.ItemId)
        navigate(`../flow/${flowId}?${searchParams.toString()}`);
      },
    };

    const actionsHandler: MenuProps['onClick'] = ({ key }) => {
      const actionHandler = actionsMap[key];
      actionHandler();
    }

    return (
      <>
        <Handle className={styles.handle} type="source" position={Position.Right} id={id} />
        <Handle
          className={cn(styles.handle, styles.hidden)}
          type="target"
          position={Position.Left}
          id={id}
        />
        <Dropdown
          menu={{
            items,
            onClick: actionsHandler,
            theme: 'dark',
          }}
          trigger={['contextMenu']}
          overlayClassName={styles.dropdown}
        >
          <Stack
            className={cn(styles.root,
              'nodrag',
              'nowheel',
              {
                [styles.selected]: id === displayMessageService.selectedExecutorHistoryId,
              },
            )}
            onClick={onSelect}
          >
            <Stack className={styles.header}>
              <div
                className={styles.flowName}
              >
                {flowName}
              </div>
              <div
                className={styles.blockName}
              >
                {name}
              </div>
            </Stack>
          </Stack>
        </Dropdown>
      </>
    );
  },
);

BlockNode.displayName = 'BlockNode';
