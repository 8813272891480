import { useState } from 'react';

import { container, InjectionToken } from 'tsyringe';
import { RegistrableValues } from '@/shared/lib/types';

export const useResolve = <T>(
  service: InjectionToken<T>,
  registrableValues?: { [key in RegistrableValues]?: string }
) => {
  if (registrableValues) {
    Object.keys(registrableValues).forEach((key) => {
      container.register(key, { useValue: registrableValues[key as RegistrableValues] || '' });
    });
  }

  const [resolvedService] = useState(container.resolve(service));

  return resolvedService;
};
