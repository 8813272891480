import { useReactFlow, useUpdateNodeInternals, Node } from 'reactflow';
import last from 'lodash/last';

import { Button } from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from "@/shared/ui/Button/types";
import styles from './ExpanderNode.module.scss';

export function ExpanderNode({ id }: Node) {
  const updateNodeInternals = useUpdateNodeInternals();
  const reactFlow = useReactFlow();
  const parentNode = reactFlow.getNode(id);
  const {
    childIds,
    getLinked,
    calcPosition,
    showedCount,
    showBtn,
  } = parentNode.data;

  if (!showBtn) return null;

  const getHandler = (restrictCount: number) => () => {
    const updatedShowedCount = showedCount + restrictCount;
    const childSlice: string[] = childIds.slice(showedCount, updatedShowedCount);
    const idsToShow: string[] = childSlice.flatMap(getLinked);
    const updatedShowBtn = showedCount + childSlice.length < childIds.length;
    const lastId = last(childSlice);
    idsToShow.forEach((nodeId: string) => {
      const node = reactFlow.getNode(nodeId);
      node.hidden = false;
    });
    parentNode.data.showBtn = updatedShowBtn;
    parentNode.data.showedCount = updatedShowedCount;
    const { y } = calcPosition(reactFlow.getNode(lastId));
    parentNode.position.y = y;
    parentNode.positionAbsolute.y = y;
    updateNodeInternals(id);
  }

  return (
    <div className={styles.trigger}>
      <Button
        size={ButtonSizes.Small}
        variant={ButtonVariants.Popup}
        doesUseInFormOrTable
        onClick={getHandler(10)}
      >
        {`${showedCount} / ${childIds.length}`}
      </Button>
    </div>
  );
}