import { FC, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { ReactFlow, useEdgesState, useNodesState } from 'reactflow';
import { Box, Stack } from '@mui/system';
import { MessageNode } from '@/widgets';// fixme illegal import (by FSD)
import { MessageNodeProps } from '@/entities/MessageTracing';
import { LoaderIcon } from '@/shared/ui/Icons/LoaderIcon/LoaderIcon';
import { getNodes } from '@/entities/TracingMessage/lib/getNodes';
import { getEdges } from '@/entities/TracingMessage/lib/getEdges';

import { TracingMessageModel } from '../model/TracingMessageModel';

export interface TracingMessageWorkspaceProps {
  header?: JSX.Element;
  store: TracingMessageModel;
}

const proOptions = { hideAttribution: true };
const nodeTypes = { messageNode: MessageNode };

export const TracingMessageWorkspace: FC<TracingMessageWorkspaceProps> = observer(
  ({ header, store }) => {
    const [nodes, setNodes] = useNodesState<MessageNodeProps>([]);
    const [edges, setEdges] = useEdgesState([]);

    useEffect(() => {
      setNodes(getNodes(store));
      setEdges(getEdges(store));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.exchangeList?.ids, setEdges, setNodes]);

    return (
      <Stack padding={2.5} height='100%' gap={2.5}>
        {header}
        <Box height='100%'>
          {store.loading ? (
            <Box height='100%' alignItems='center' justifyContent='center' display='flex'>
              <LoaderIcon width={24} height={24} />
            </Box>
          ) : (
            <>
              {!store.exchangeList && <p>Нет отправленных сообщений</p>}
              <ReactFlow
                nodeTypes={nodeTypes}
                proOptions={proOptions}
                nodes={nodes}
                edges={edges}
                defaultViewport={{ x: 0, y: 0, zoom: 0.5 }}
                onlyRenderVisibleElements={nodes.length > 20}
                translateExtent={[[0,0], [Infinity,Infinity]]}
              />
            </>
          )}
        </Box>
      </Stack>
    );
  }
);

TracingMessageWorkspace.displayName = 'TracingMessageWorkspace';
