import React, { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { Chip } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/system/Stack';

import { useTracing } from '@/hooks/tracing/useTracing';
import { ButtonVariants } from '@/shared/ui/Button/types';
import Button from '@/shared/ui/Button';
import { SwitchWithLabel } from '@/shared/ui/Switch/SwitchWithLabel';
import { Autocomplete } from '@/shared/ui/Autocomplete/Autocomplete';
import { TracingFilterStore } from '@/features/TracingFilter';
import {Pagination, RangePicker} from '@/shared/ui';
import { Label } from '@/shared/ui/Label/Label';
import { Block } from '@/entities/Block/types';
import { FlowService } from '@/entities/Flow/services/FlowService';
import { FlowStore } from '@/entities/Flow/stores/FlowStore';
import { Flow } from '@/entities/Flow/types';
import Typography from "@/shared/ui/Typography";
import {formatNumberToString} from "@/shared/lib/numberUtils";
import { TracingTable } from './components/tracing-table/TracingTable';

const tracingFilterStore = container.resolve(TracingFilterStore);
const flowService = container.resolve(FlowService);
const flowStore = container.resolve(FlowStore);

export const TracingTableContainer: FC = observer(() => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const tracing = useTracing({
    key: 'tracingList_data',
    page,
    rowsPerPage,
    flowIdList: tracingFilterStore.selectedFlows.map((item) => item.id),
    filterList: tracingFilterStore.selectedBlocks.length
      ? [
          {
            typeCriteria: null,
            operator: 'in',
            name: 'blockId',
            value: tracingFilterStore.selectedBlocks.map((block) => block.id),
          },
        ]
      : undefined,
    timeInterval: {
      start: tracingFilterStore.fromDate?.toDate(),
      end: tracingFilterStore.toDate?.toDate(),
    },
    onlyError: tracingFilterStore.onlyError,
    getCount: false,
  });

  const tracingCount = useTracing({
    key: 'tracingList_total',
    page,
    rowsPerPage,
    flowIdList: tracingFilterStore.selectedFlows.map((item) => item.id),
    filterList: tracingFilterStore.selectedBlocks.length
      ? [
        {
          typeCriteria: null,
          operator: 'in',
          name: 'blockId',
          value: tracingFilterStore.selectedBlocks.map((block) => block.id),
        },
      ]
      : undefined,
    timeInterval: {
      start: tracingFilterStore.fromDate?.toDate(),
      end: tracingFilterStore.toDate?.toDate(),
    },
    onlyError: tracingFilterStore.onlyError,
    getData: false
  });

  const refetch = useCallback(async (): Promise<void> => {
    tracing.refetch();
    tracingCount.refetch();
  }, [tracing, tracingCount]);

  useEffect(() => {
    refetch();
    // Так как у нас все запросы идут по кнопке, а не при изменении, то необходимо в ручную делать первый запрос при onMount
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refetch();
    // Для пагинации обновляем данные при изменении
    // eslint-disable-next-line
  }, [page, rowsPerPage]);

  const handleFlowListChange = useCallback(
    async (event: SyntheticEvent<Element, Event>, newFlows: Flow[]) => {
      try {
        if (newFlows.length) {
          await flowService.getFlow(newFlows[newFlows.length - 1].id);
        } else {
          tracingFilterStore.selectedBlocks = [];
        }

        setPage(0);
        tracingFilterStore.selectedFlows = newFlows;
      } catch {}
    },
    []
  );

  const handleBlockListChange = useCallback(
    (event: SyntheticEvent<Element, Event>, newBlocks: Block[]) => {
      tracingFilterStore.selectedBlocks = newBlocks;
      setPage(0);
    },
    []
  );

  const handleOnlyErrorChange = useCallback(() => {
    tracingFilterStore.onlyError = !tracingFilterStore.onlyError;
  }, []);

  const handleChangePage = useCallback(
    async (event: unknown, newPage: number) => {
      setPage(newPage);
    },
    []
  );

  const handleChangeRowsPerPage = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    [setRowsPerPage]
  );

  const handleSearch = useCallback(async () => {
    if (page === 0) {
      // обновляем руками
      await refetch();
      return;
    }

    // обновляем за счет смены страницы
    setPage(0);
  }, [page, refetch]);

  const renderFlowTags = useCallback(
    (tagValue: Flow[], getTagProps: AutocompleteRenderGetTagProps) => {
      return tagValue.map((option, index) => {
        return (
          // @ts-ignore
          <Chip key={option.id} label={option.name} {...getTagProps({ index })} />
        );
      });
    },
    []
  );

  const renderBlockTags = useCallback(
    (tagValue: Block[], getTagProps: AutocompleteRenderGetTagProps) => {
      return tagValue.map((option, index) => {
        const label = `${option.name} (${flowStore.flows.entities[option.flowId].name})`;

        return (
          // @ts-ignore
          <Chip key={option.id} label={label} {...getTagProps({ index })} />
        );
      });
    },
    []
  );

  useEffect(() => {
    flowService.getAllFlows();
  }, []);

  return (
    <Stack height='100%' gap={1.5}>
      <Grid container spacing={2.5} maxWidth={1536}>
        <Grid item xs={12} xl={6} marginTop={{ xl: 4.5 }}>
          <Accordion elevation={0} variant='outlined'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Фильтрация по потокам и блокам
            </AccordionSummary>
            <AccordionSummary>
              <Grid container spacing={2.5}>
                <Grid item xs={12} sm={6}>
                  <Box minWidth={200}>
                    <Autocomplete<Flow, true>
                      multiple
                      filterSelectedOptions
                      id='fixed-tags-demo'
                      value={tracingFilterStore.selectedFlows}
                      options={Object.values(flowStore.flows?.entities) || []}
                      getOptionLabel={getOptionLabelForFlowSelect}
                      renderTags={renderFlowTags}
                      onChange={handleFlowListChange}
                      label='Потоки'
                      placeholder='Выбрать'
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box minWidth={200}>
                    <Autocomplete<Block, true>
                      multiple
                      filterSelectedOptions
                      options={tracingFilterStore.blocksOptions}
                      value={tracingFilterStore.selectedBlocks}
                      getOptionLabel={getOptionLabelForBlockSelect}
                      renderTags={renderBlockTags}
                      onChange={handleBlockListChange}
                      label='Блоки по выбранным потокам'
                      placeholder='Выбрать'
                    />
                  </Box>
                </Grid>
              </Grid>
            </AccordionSummary>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={8} lg={6}>
          <Stack gap={2.5}>
            <Label>Дата выполнения</Label>
            <RangePicker
              style={{ height: 50 }}
              value={[tracingFilterStore.fromDate, tracingFilterStore.toDate]}
              onCalendarChange={([fromDate, toDate]) => {
                if (fromDate && toDate) {
                  tracingFilterStore.fromDate = fromDate;
                  tracingFilterStore.toDate = toDate;
                } else {
                  tracingFilterStore.fromDate = null;
                  tracingFilterStore.toDate = null;
                }
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} lg={6} paddingBottom='14px' marginTop={{ md: 6, xl: 'unset' }}>
          <SwitchWithLabel
            checked={tracingFilterStore.onlyError}
            label='Только с ошибкой'
            onChange={handleOnlyErrorChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Box width={216}>
            <Button variant={ButtonVariants.Popup} onClick={handleSearch}>
              Поиск
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Stack marginTop='30px'>
        {!tracing.isLoading && tracing?.data?.executorHistoryPage.content?.length === 0 ? (
          <Box
            marginTop='10px'
            padding='20px'
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            Нет данных
          </Box>
        ) : (
          <TracingTable
            isLoading={tracing.isLoading}
            data={tracing?.data?.executorHistoryPage.content || []}
          />
        )}
      </Stack>

      <Stack marginTop='30px'>
        <Box
            display='flex'
            flex={1}
            justifyContent='center'
            alignItems='center'
            gap={2}
            paddingBottom={1}
        >
          <Pagination
              size='large'
              count={tracingCount.data?.total ? Math.ceil(tracingCount.data?.total / rowsPerPage) : 100}
              defaultPage={1}
              siblingCount={3}
              boundaryCount={0}
              showFirstButton={true}
              onChange={handleChangePage}
          />
          <Typography>
            Найдено : {tracingCount.data?.total ? formatNumberToString(tracingCount.data?.total) : '--'} пакетов
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
});

function getOptionLabelForBlockSelect(option: Block) {
  return `${option.name} (${flowStore.flows.entities[option.flowId].name})`;
}

function getOptionLabelForFlowSelect(option: Flow) {
  return option.name;
}
