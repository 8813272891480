import { FC, memo, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Tooltip from '@/shared/ui/Tooltip';
import {
  ACTIONS_SEARCH_PARAMS,
  getMessageTracingSearchParamsTemplate,
  SearchParams,
  useSearchParamsTemplate,
} from '@/hooks/useTemplateSearchParams';
import { Box } from '@mui/system';
import { MessageIcon } from '@/shared/ui/Icons/MessageIcon/MessageIcon';
import { TooltipProps } from '@mui/material/Tooltip';
import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';

export interface OpenMessageTracingProps {
  blockId: string;
  toolbarPlacement?: TooltipProps['placement'];
}

export const OpenMessageTracing: FC<OpenMessageTracingProps> = memo(
  ({ blockId, toolbarPlacement = 'left' }) => {
    const { has, set, remove } = useSearchParamsTemplate();
    const isOpen = has(SearchParams.ShowMessageTracing);

    const handleClick = useCallback(() => {
      remove(ACTIONS_SEARCH_PARAMS);
      if (isOpen) return;
      set(getMessageTracingSearchParamsTemplate(blockId, false));
    }, [blockId, remove, set]);

    eventEmitter.on(`OpenMessageTracing${blockId}`, handleClick);

    return (
      <Tooltip title='Мониторинг сообщений' placement={toolbarPlacement} disableInteractive>
        <Box height='max-content'>
          <Button
            tabIndex={1}
            circle
            size={ButtonSizes.Small}
            variant={ButtonVariants.Outlined}
            onClick={handleClick}
          >
            <MessageIcon />
          </Button>
        </Box>
      </Tooltip>
    );
  }
);

OpenMessageTracing.displayName = 'OpenMessageTracing';
