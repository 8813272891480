import { FC, PropsWithChildren } from 'react';

import { Drawer } from '@/shared/ui/Drawer/Drawer';
import { ACTIONS_SEARCH_PARAMS, SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

type InDrawerProps = {
  title: string;
  searchParams: {
    create: SearchParams;
    edit: SearchParams;
  }
}

export const InDrawer: FC<PropsWithChildren<InDrawerProps>> = ({
  children,
  title,
  searchParams: {
    create,
    edit,
  },
}) => {
  const { has, remove } = useSearchParamsTemplate();

  const isOpenDrawer = has(create) || has(edit);

  const handleDrawerClose = (): void => {
    remove(ACTIONS_SEARCH_PARAMS);
  };

  if (!isOpenDrawer) return null;
  return (
    <Drawer
      width={434}
      title={title}
      onClose={handleDrawerClose}
    >
      {children}
    </Drawer>
  );
};
