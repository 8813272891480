import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';

import { Connect } from '@/entities/Connect/types';

@singleton()
export class IndexedDBStore {
  connector?: Connect;

  valuesForSaveIdb?: any;

  isShowIndexedDB: boolean;

  id: string | null;

  indexedDbValues: Connect;

  constructor() {
    this.connector = null;
    this.id = null;
    this.valuesForSaveIdb = null;
    this.isShowIndexedDB = false;
    this.indexedDbValues = null;
    makeAutoObservable(this);
  }
}
