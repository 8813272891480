import * as yup from 'yup';

const transliterate = (text: string) => text.replace(/ /g, '_').replace(/[^a-zA-Z_]/g, ''); // Заменяем пробелы на подчеркивания и удаляем все не латинские символы
export const tenantFormAddValidation = yup.object({
  name: yup.string().default('').nullable().max(100, 'Название не должно превышать 100 символов'),
  companyId: yup.string().nullable(),
  accountList: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .matches(/^[a-zA-Z_]*$/, 'Only Latin characters are allowed')
        .transform((value) => transliterate(value))
        .required('Параметр не должен быть пустым'),
      login: yup.string().required('Параметр не должен быть пустым'),
      password: yup.string().required('Параметр не должен быть пустым'),
    })
  ),
});
export const tenantFormEditValidation = yup.object({
  active: yup.boolean(),
  deactivationComment: yup.string().nullable(),
});
