import { SearchParams, SearchParamsTemplate } from '@/hooks/useTemplateSearchParams/types';

export const getBlockFormSearchParamsTemplate = (blockId: string): SearchParamsTemplate => {
  return [SearchParams.ShowBlockForm, { [SearchParams.BlockId]: blockId }];
};

export const getMessageTracingSearchParamsTemplate = (
  blockId: string,
  isError: boolean
): SearchParamsTemplate => {
  return [
    SearchParams.ShowMessageTracing,
    {
      [SearchParams.BlockId]: blockId,
    },
  ];
};

export const getConnectorFormSearchParamsTemplate = ({
  connectorType,
  connectorId,
  connectorName,
  blockId,
}: {
  blockId: string;
  connectorId: string;
  connectorName: string;
  connectorType: string;
}): SearchParamsTemplate => {
  return [
    SearchParams.ShowConnectorForm,
    {
      [SearchParams.ConnectorName]: connectorName,
      [SearchParams.ConnectorType]: connectorType,
      [SearchParams.ConnectorId]: connectorId,
      [SearchParams.BlockId]: blockId,
    },
  ];
};

export const CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE: SearchParamsTemplate = [
  SearchParams.ConnectorType,
  SearchParams.ConnectorId,
  SearchParams.ConnectorName,
  SearchParams.ShowConnectorForm,
];

export const BLOCK_FORM_SEARCH_PARAMS_TEMPLATE: SearchParamsTemplate = [
  SearchParams.ShowBlockForm,
  SearchParams.BlockId,
];

export const MESSAGE_TRACING_SEARCH_PARAMS_TEMPLATE: SearchParamsTemplate = [
  SearchParams.ShowMessageTracing,
  SearchParams.IsError,
  SearchParams.MessageId,
];

export const EXECUTOR_LOG_BLOCK_SEARCH_PARAMS_TEMPLATE: SearchParamsTemplate = [
  SearchParams.ShowExecutorLogBlock,
  SearchParams.BlockId,
];

export const FLOW_ACTIONS_SEARCH_PARAMS: SearchParamsTemplate = [
  SearchParams.ShowFlowVersionForm,
  SearchParams.ShowFlowVersionList,
  SearchParams.ShowFlowCloneModal,
  SearchParams.ShowCreateFlowForm,
  SearchParams.ShowEditFlowForm,
  SearchParams.ShowTFlowDeleteModal,
  SearchParams.ShowCreateTFlowForm,
  SearchParams.ShowEditTFlowForm,
  SearchParams.ShowFlowDeleteModal,
  SearchParams.FlowId,
];

export const BLOCK_ACTIONS_SEARCH_PARAMS: SearchParamsTemplate = [
  SearchParams.ShowBlockForm,
  SearchParams.ShowBlockTesting,
  SearchParams.ShowSendMessage,
  SearchParams.ShowMessageQueue,
  SearchParams.ShowExecutorLogBlock,
  SearchParams.ShowMessageTracing,
  SearchParams.IsError,
  SearchParams.MessageId,
  SearchParams.BlockId,
];

export const ACTIONS_SEARCH_PARAMS: SearchParamsTemplate = [
  ...FLOW_ACTIONS_SEARCH_PARAMS,
  ...BLOCK_ACTIONS_SEARCH_PARAMS,
];
