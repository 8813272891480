export const MESSAGE_STATUSES = [
  {
    value: 'sent',
    label: 'Отправлено',
  },
  {
    value: 'notSent',
    label: 'Требуется отправить',
  },
  {
    value: 'limit',
    label: 'Превышение количества попыток отправки',
  },
  {
    value: 'skipped',
    label: 'Пропущено',
  },
];

export enum Tabs {
  body = 'body',
  context = 'context',
  files = 'files',
  headers = 'headers',
  pathParams = 'pathParams',
  queryParams = 'queryParams',
  stackTrace = 'stackTrace',
}

