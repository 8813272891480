import React, { FC, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import cn from "classnames";
import { Stack } from "@mui/system";
import { CreateButton } from "@/shared/ui/Sidebar/Sidebar";
import { FlowFilter, FlowSort } from '@/entities/Flow/features';
import { ACTIONS_SEARCH_PARAMS, SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { JoyRideService } from '@/entities/JoyRideOnboarding/api/JoyRideService';
import { ModeSwitcher, useMode, defaultMode } from '@/entities/Flow/widgets/FlowSidebar/ModeSwitcher';
import { FlowList } from '@/entities/Flow/widgets/FlowList';
import { FlowTree } from '@/entities/Flow/widgets/FlowTree/FlowTree';

import styles from './FlowSidebar.module.scss';

const renderMap = {
  list: FlowList,
  tree: FlowTree,
};

const joyrideService = container.resolve(JoyRideService);

export const FlowSidebar: FC = observer(
  () => {
    const params = new URLSearchParams(window.location.search);
    const viewMode = params.get(SearchParams.FlowsMode) || defaultMode;
    const isDefaultMode = viewMode === defaultMode;

    useMode();

    const { set, remove } = useSearchParamsTemplate();
    const addFlow = useCallback(() => {
      if (isDefaultMode && joyrideService.run) {
        joyrideService.updateCurrentStep(joyrideService.stepIndex + 1);
      }
      remove(ACTIONS_SEARCH_PARAMS);
      if (isDefaultMode) set([SearchParams.ShowCreateFlowForm]);
      else set({ [SearchParams.ShowCreateTFlowForm]: 'flow' });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remove, set, joyrideService.stepIndex]);


    const listBar = isDefaultMode && (
      <Stack direction='row' justifyContent='space-between'>
        <FlowFilter />
        {/* TODO Доделать сортировку */}
        <FlowSort />
      </Stack>
    );
    // @ts-ignore
    const Component = renderMap[viewMode] || FlowList;
    // @ts-ignore
    const className = styles[viewMode];

    return (
      <Stack
        className={cn(styles.root)}
        spacing={2}
      >
        <Stack
          spacing={2.5}
          marginX={2.5}
          marginTop={2.5}
          style={{ overflow: 'hidden' }}
        >
          <CreateButton text='Создать поток' onClick={addFlow} />
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <span className={styles.title}>Потоки</span>
            <ModeSwitcher />
          </Stack>
          {listBar}
        </Stack>
        <Stack alignItems='center' className={className}>
          <Component />
        </Stack>
      </Stack>
    );
  }
);

FlowSidebar.displayName = 'FlowSidebar';
