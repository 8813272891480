import React, { FC, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import Tooltip from '@/shared/ui/Tooltip';

import { WSDLService } from '@/entities/WSDLService/features/api/WSDLService';

const wsdlService = container.resolve(WSDLService);

export interface DeleteWSDLAppButtonProps {
  wsdlAppId: string;
}

export const DeleteWSDLAppButton: FC<DeleteWSDLAppButtonProps> = observer(({ wsdlAppId }) => {
  const handleClick = useCallback(async () => {
    wsdlService.openDeleteForm = true;
    wsdlService.selectedId = wsdlAppId;
  }, [wsdlAppId]);

  return (
    <Tooltip title='Удалить WSDL-сервис' placement='top'>
      <span>
        <Button
          circle
          variant={ButtonVariants.Outlined}
          size={ButtonSizes.Small}
          onClick={handleClick}
        >
          <TrashIcon />
        </Button>
      </span>
    </Tooltip>
  );
});

DeleteWSDLAppButton.displayName = 'DeleteWSDLAppButton';
