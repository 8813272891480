import { FC, memo } from 'react';

import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { CardActions } from '@mui/material';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';

import { OpenApiList } from '@/entities/OpenApi/types';

import styles from './OpenApiAppCard.module.scss';

export interface ExternalAppCardProps extends Omit<OpenApiList, 'companyId'> {
  actions?: JSX.Element;
  toggle?: JSX.Element;
}

export const OpenApiAppCard: FC<ExternalAppCardProps> = memo(
  ({ name, description, serviceStartDate, lastCheckUrl, url, actions, toggle }) => {
    return (
      <Card
        variant='outlined'
        sx={{
          width: 300,
          height: 240,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <CardContent
          style={{
            padding: '16px 8px 16px 16px',
          }}
        >
          <Stack justifyContent='space-between' flex={1} height='100%'>
            <Stack gap={1}>
              <Stack gap={2.5} direction='row' justifyContent='space-between'>
                <Stack gap={1} width='100%'>
                  <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography fontWeight={600}>{name || 'Название отсутствует'}</Typography>
                  </Stack>

                  <Typography noWrap fontSize='0.75rem'>
                    {description}
                  </Typography>
                </Stack>
                {toggle}
              </Stack>

              <Link to={url} target='_blank'>
                <Typography className={styles.link} noWrap>
                  {url}
                </Typography>
              </Link>
            </Stack>
          </Stack>
        </CardContent>
        <CardActions>
          <Stack gap={2} flex={1}>
            <Stack gap={0.5} width='100%' paddingX={1}>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography fontWeight={600} fontSize='0.75rem'>
                  Проверка обновления:
                </Typography>
                <Typography fontSize='0.75rem' sx={{ opacity: 0.5 }}>
                  {lastCheckUrl ? dayjs(lastCheckUrl).format('HH:mm:ss DD.MM.YYYY') : 'Нет'}
                </Typography>
              </Stack>

              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography fontSize='0.75rem' sx={{ opacity: 0.5 }}>
                  Начало работы:
                </Typography>
                <Typography fontSize='0.75rem' sx={{ opacity: 0.5 }}>
                  {serviceStartDate ? dayjs(serviceStartDate).format('HH:mm:ss DD.MM.YYYY') : 'Нет'}
                </Typography>
              </Stack>
            </Stack>

            {actions}
          </Stack>
        </CardActions>
      </Card>
    );
  }
);

OpenApiAppCard.displayName = 'OpenApiAppCard';
