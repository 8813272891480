import { FC } from 'react';

import { Dialog, DialogActions, DialogContent } from '@/shared/ui';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { TenantService } from '@/entities/Tenant/api/TenantService';
import { TenantStore } from '@/entities/Tenant/stores/TenantStore';
import { Stack } from '@mui/system';

const tenantService = container.resolve(TenantService);
const tenantStore = container.resolve(TenantStore);

export interface DeletePythonBeanDialogProps {
  tenantId?: string;

  onSuccess?(): void;
}

export const TenantSaveDialog: FC<DeletePythonBeanDialogProps> = observer(
  ({ tenantId, onSuccess }) => {
    const handleClose = () => {
      tenantStore.confirmAddTenant = false;
    };

    const handleAccept = () => {
      tenantService.addTenant(tenantStore.bodyTenant, tenantId).then(onSuccess);
      tenantStore.confirmAddTenant = false;
    };

    return (
      <Dialog open={tenantStore.confirmAddTenant} onClose={handleClose}>
        <Stack paddingY={2.5}>
          <DialogContent>Вы уверены, что хотите изменить активность тенанта?</DialogContent>
          <DialogActions>
            <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
              Отмена
            </Button>
            <Button
              loading={tenantStore.isLoading}
              variant={ButtonVariants.Popup}
              onClick={handleAccept}
            >
              Да
            </Button>
          </DialogActions>
        </Stack>
      </Dialog>
    );
  }
);

TenantSaveDialog.displayName = 'TenantSaveDialog';
