import { FC, useCallback, useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { observer } from 'mobx-react-lite';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { container } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import { OpenApiService } from '@/entities/OpenApi/features/api/OpenApiService';
import { Checkbox } from '@/shared/ui/Checkbox/Checkbox';

const openApiService = container.resolve(OpenApiService);

export interface DeleteExternalAppDialogProps {
  onSuccess?(): void;
}

export const DeleteOpenApiAppDialog: FC<DeleteExternalAppDialogProps> = observer(
  ({ onSuccess }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [deactivation, setDeactivation] = useState(false);

    const handleClose = useCallback(() => {
      openApiService.openDeleteForm = false;
    }, []);

    const handleOkButtonClick = useCallback(async () => {
      try {
        setIsLoading(true);
        await openApiService.deleteOpenApiApp(deactivation);

        notify.success('Внешний OpenAPI-сервис удален');
        handleClose();
        onSuccess?.();
      } catch {
        notify.error('Не удалось удалить OpenAPI-сервис');
      } finally {
        setIsLoading(false);
      }
    }, [handleClose, onSuccess, deactivation]);
    return (
      <Dialog fullWidth open={openApiService.openDeleteForm} onClose={handleClose}>
        <DialogTitle onClose={handleClose}>Удаление OpenAPI-сервиса</DialogTitle>
        <DialogContent>
          <form>
            <Checkbox
              name='service_deactivation'
              checked={deactivation}
              label='Отключить обработчики/коннекторы, использующие данный сервис ?'
              onChange={() => { setDeactivation(!deactivation); }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
            Отмена
          </Button>
          <Button variant={ButtonVariants.Popup} loading={isLoading} onClick={handleOkButtonClick}>
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

DeleteOpenApiAppDialog.displayName = 'DeleteOpenApiAppDialog';
