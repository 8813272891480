import { MarkerType } from 'reactflow';
import { isArray } from 'lodash';

import { ExecutorHistory } from "@/entities/Connect/types";

interface Edge {
  id: string;
  source: string;
  target: string;
  style: any;
  markerEnd: any;
}
const edgeStyle = {
  style: { strokeWidth: '2px', stroke: 'var(--edge-color)' },
  markerEnd: {
    type: MarkerType.ArrowClosed,
    strokeWidth: 2,
    color: '#E4E4E4',
  },
};

const buildEdges = (executorHistory: ExecutorHistory): Edge[] => {
  const { executorHistoryNext } = executorHistory;
  if (!isArray(executorHistoryNext)) return [];
  return executorHistoryNext.reduce((acc, executorHistory2) => {
    const edge: Edge = {
      ...edgeStyle,
      id: `${executorHistory.id}${executorHistory2.id}`,
      source: executorHistory.id,
      target: executorHistory2.id,
    };
    return [
      ...acc,
      edge,
      ...buildEdges(executorHistory2)
    ];
  }, []);
}

export function getEdgesForBlockTracing(executorHistory: ExecutorHistory) {
  if (!executorHistory) return [];

  return buildEdges(executorHistory);
}
