import { FC, useEffect } from 'react';

import { Observer, observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import Stack from "@mui/material/Stack";
import { ListWithInfiniteScroll } from '@/shared/ui';
import { UserService } from '@/entities/User/api/UserService';
import { UpdateUserForm } from "@/features/UpdateUser";
import { DeleteUserButton } from "@/features/DeleteUser";

const userService = container.resolve(UserService);

export const UserList: FC = observer(() => {

  useEffect(() => () => {
    userService.resetUsers();
  }, []);

  const fetchNextPage = (page: number) => userService.getUsers(page);
  return (

    <Stack gap={5} height='calc(100vh - 350px)' sx={{ overflowY: 'auto' }}>
      <ListWithInfiniteScroll
        elementWidth='100%'
        elements={userService.users.ids}
        lastPage={userService.totalPages}
        loading={false}
        fetchNextElement={fetchNextPage}
        renderElement={(id) => (
          <Observer key={id}>
            {() => (
              <UpdateUserForm
                key={id}
                userId={id}
                deleteButton={<DeleteUserButton userId={id} />}
              />
            )}
          </Observer>
        )}
      />
    </Stack>
  );
});
