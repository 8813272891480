import { FC, memo, useCallback } from 'react';

import Tooltip from '@/shared/ui/Tooltip';
import { Box } from '@mui/system';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { SettingsIcon } from '@/shared/ui/Icons/SettingsIcon/SettingsIcon';
import { container } from 'tsyringe';

import { OpenApiService } from '@/entities/OpenApi/features/api/OpenApiService';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

const openApiService = container.resolve(OpenApiService);

export interface UpdateOpenAppButtonProps {
  openAppId: string;
}

export const UpdateOpenAppButton: FC<UpdateOpenAppButtonProps> = memo(({ openAppId }) => {
  const { set } = useSearchParamsTemplate();
  const handleClick = useCallback(() => {
    openApiService.open = true;
    openApiService.selectedId = openAppId;
    set([SearchParams.ShowEditOpenApiForm, { [SearchParams.OpenApiId]: openAppId }]);
  }, [openAppId]);

  return (
    <Tooltip title='OpenAPI-сервис' placement='top'>
      <Box>
        <Button
          circle
          size={ButtonSizes.Small}
          variant={ButtonVariants.Outlined}
          onClick={handleClick}
        >
          <SettingsIcon />
        </Button>
      </Box>
    </Tooltip>
  );
});

UpdateOpenAppButton.displayName = 'UpdateOpenAppButton';
