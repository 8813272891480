import { FC, useCallback, useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { useParams } from 'react-router-dom';
import Stack from '@mui/system/Stack';
import Box from '@mui/system/Box';
import { yupResolver } from '@hookform/resolvers/yup';
import { nameAndDescriptionFieldsValidation } from '@/shared/lib';
import Button from '@/shared/ui/Button';
import { Drawer } from '@/shared/ui/Drawer/Drawer';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { Version } from '@/entities/FlowVersion';
import { FlowService } from '@/entities/Flow/services/FlowService';
import { ACTIONS_SEARCH_PARAMS, SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

import { SaveVersionService } from '../api/SaveVersionService';
import { FlowUpdaterService } from '@/entities/Flow/services/FlowUpdaterService';
import { RegistrableValues } from '@/shared/lib/types';

const saveVersionService = container.resolve(SaveVersionService);
const flowService = container.resolve(FlowService);

export const SaveVersionForm: FC = observer(() => {
  const { flowId = '' } = useParams();
  const { get, remove } = useSearchParamsTemplate();
  const showForm = get(SearchParams.ShowFlowVersionForm);

  container.register(RegistrableValues.FlowId, { useValue: flowId || '' });
  const flowUpdaterService = container.resolve(FlowUpdaterService);
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm<Version>({
    resolver: yupResolver(nameAndDescriptionFieldsValidation),
    defaultValues: {
      name: '',
      description: '',
      flowId,
      versionDate: new Date(),
      versionFlow: flowService.flows.entities[flowId] as any,
    },
    mode: 'onChange',
  });

  const handleDrawerClose = () => {
    remove(ACTIONS_SEARCH_PARAMS);
  };

  const handleOkButtonClick = useCallback<SubmitHandler<Version>>(
    (data) => {
      data.versionFlow = flowUpdaterService.updatedFlow;
      saveVersionService.saveVersion(data).then(handleDrawerClose);
    },
    [flowId]
  );

  useEffect(() => {
    if (!showForm) {
      reset((formValues) => ({ ...formValues, name: '', description: '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showForm]);

  if (!showForm) return null;

  return (
    <Drawer width={434} onClose={handleDrawerClose} title='Сохранить версию потока'>
      <Box paddingBottom={2.5}>
        <Box component='form' paddingX={2.5}>
          <Stack gap={2.5}>
            <FieldInput
              label='Наименование версии'
              error={errors.name}
              name='name'
              localeName='name'
              register={register}
              description='Введите название потока, длинной не более 100 символов'
            />
            <FieldInput
              textarea
              label='Описание версии'
              error={errors.description}
              name='description'
              localeName='description'
              register={register}
              description='Введите описание потока, длинной не более 1000 символов'
            />
          </Stack>
        </Box>
        <Box paddingX={2.5}>
          <Button
            disabled={!isValid}
            loading={saveVersionService.isLoading}
            onClick={handleSubmit(handleOkButtonClick)}
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
});

SaveVersionForm.displayName = 'SaveVersionForm';
