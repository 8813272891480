import { DBSchema, IDBPDatabase, openDB } from 'idb';
import { injectable } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import { IndexedDBStore } from '@/store/IndexedDBStore/IndexedDBStore';
import { notify } from '@/shared/ui/Toast/notify';
import { Connect } from '@/entities/Connect/types';
import { daysBetween } from '@/service/utils/daysBetween';
import { DisplayMessageStore } from '@/entities/MessageTracing/model/DisplayMessageStore';

export interface FieldDataDB extends DBSchema {
  fieldData: {
    key: string;
    value: {
      value: any;
      key: string;
      date: Date;
    };
  };
}

export interface FilterDataDB extends DBSchema {
  filterData: {
    key: string;
    value: {
      value: any;
      key: string;
      date: Date;
    };
  };
}

let db: IDBPDatabase<FieldDataDB>;
let dbFilterData: IDBPDatabase<FilterDataDB>;

@injectable()
export class IndexedDBService {
  openNotification: boolean;

  constructor(
    private indexedDBStore: IndexedDBStore,
    private displayMessageStore: DisplayMessageStore
  ) {
    this.openNotification = false;

    makeAutoObservable(this);
  }

  /** Создание базы */
  initDBFieldData = async () => {
    db = await openDB<FieldDataDB>('fieldData', 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains('fieldData')) {
          db.createObjectStore('fieldData');
        }
      },
    });
  };

  /** Функция  сохранения данных  */
  saveFieldData = async (key: string, value: any, date: Date) => {
    if (!db) await this.initDBFieldData();
    return db.put('fieldData', { value, key, date }, key);
  };

  /** Функция поиска данных по ключу */
  async getFieldData(key: string): Promise<Connect> {
    if (!db) await this.initDBFieldData();
    try {
      const response = await db.get('fieldData', key);
      const today = new Date();
      const date = new Date(response?.date);
      const limitDays = 1;
      const diffDays = daysBetween(today, date);
      if (diffDays >= limitDays) {
        await this.deleteFieldData(key);
      }
      if (diffDays <= limitDays) {
        this.indexedDBStore.connector = response?.value;
        this.indexedDBStore.id = response?.key;
        this.indexedDBStore.isShowIndexedDB = true;
        return this.indexedDBStore.connector;
      }
    } catch (err) {
      notify.error('Не удалось получить данные');
    }
  }

  /** Удаление записи */
  deleteFieldData = async (key: string) => {
    if (!db) await this.initDBFieldData();
    return db.delete('fieldData', key);
  };
}
