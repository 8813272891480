/* eslint-disable react/function-component-definition */
import * as React from 'react';
import { FC } from 'react';

import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';
import { get, pick } from 'lodash';
import { Controller, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { Stack } from '@mui/system';
import Typography from '@mui/material/Typography';
import { FieldPassword } from '@/shared/ui/Fields/components/FieldPassword/FieldPassword';
import { FieldCron } from '@/shared/ui/Fields/components/FieldCron/FieldCron';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { Parameter } from '@/entities/Connect/types';
import { FieldCode } from '@/shared/ui/Fields/components/FieldCode/FieldCode';
import { MappingData } from '@/shared/ui/Fields/components/MappingData/MappingData';
import { ChoosePythonBeanForm } from '@/features/ChoosePythonScript';
import { AnimatedBox } from '@/shared/ui';
import { Connector1cTree } from '@/entities/Connector1c/ui/Connector1cTree';
import { UpdateSkeletonWrapper } from '@/features/UpdateSkeleton';
import { FieldAutocomplete } from '@/shared/ui/Fields/components/FieldAutocomplete/FieldAutocomplete';
import { SmartTypography } from '@/shared/ui/Fields/components/SmartTypography/SmartTypography';
import { UploadFiles } from '@/features/UploadFiles/ui/UploadFiles';
import { FieldAccordion } from '@/shared/ui/Fields/components/FieldAccordion/FieldAccordion';
import { FieldSwitchWithParameter } from '@/shared/ui/Fields/components/FieldSwitchWithParameter/FieldSwitchWithParameter';
import { FieldListString } from '@/shared/ui/Fields/components/FieldListString/FieldListString';
import { FieldTabs } from '@/shared/ui/Fields/components/FieldTabs/FieldTabs';
import { BlockSelectContainer } from '@/shared/ui/Fields/containers/BlockSelectContainer';
import { FieldSwitch } from '@/shared/ui/Fields/components/FieldSwitch/FieldSwitch';
import { amendDraggable } from '@/shared/lib/extendDndkit';
import { FieldInputNumber } from '@/shared/ui/Fields/components/FieldInputNumber/FieldInputNumber';
import { FieldObject } from '@/shared/ui/Fields/components/FieldObject/FieldObject';
import { FieldSelect } from '@/shared/ui/Fields/components/FieldSelect/FieldSelect';
import { FieldRadioGroup } from '@/shared/ui/Fields/components/FieldRadioGroup';

import { FieldList } from './components/FieldList';
import { RoleSelectContainer } from './containers/RoleSelectContainer';
import { SelectByParameter } from './components/SelectByParameter/SelectByParameter';

export interface FieldsProps {
  parameters: Parameter[];
  namePrefix?: string;
  register: any;
  control: any;
  errors: any;
  warnings?: any;
  setValue: UseFormSetValue<any>;
  id?: string;
  getValues: UseFormGetValues<any>;
  defaultValue?: any;
  isNamePrefixProps?: boolean;
}

type OnChange = () => void;

export interface FieldProps
  extends Omit<Parameter, 'isSendSkeletonForEnrichByButton' | 'isSendSkeletonForEnrichByDebounce'> {
  register?: any;
  control?: any;
  error: any;
  warning?: any;
  animationProps?: typeof ANIMATION_PROPS;
  id?: string;
  invertChecked?: boolean;
  onChange?: OnChange;
}

export interface FieldWhichRenderInsideFieldsProps
  extends Omit<FieldsProps, 'parameters'>,
    Pick<FieldProps, 'animationProps' | 'error'> {
  parameter: Parameter;
  defaultValue?: any;
}

const ANIMATION_PROPS = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};


const buildRender = ({
  namePrefix = 'params',
  isNamePrefixProps = false,
  control,
  register, // todo clean hook-form api drill
  errors,
  warnings,
  setValue,
  getValues,
  defaultValue,
}: Omit<FieldsProps, 'parameters'>) => (
  parameter: Parameter,
) => {
  const {
    type,
    name: baseName,
    label,
    description,
    defaultValue: prmDefault,
    style,
    childrenBoxStyle,
    parameterList,
    props,
  } = parameter;
  const name = `${namePrefix}.${baseName}`;
  const nameWithPrefix = namePrefix === 'params'
    ? `paramsObject.${baseName}`
    : name;
  const error = get(errors, name, '');
  const warning = get(warnings, name, '');
  const draggable = get(props, 'draggable', false);

  const dispatchRender = (type: string) => {
    switch (type) {
      case 'Label': {
        return (
          <Typography className='preWrap'>
            <div
              dangerouslySetInnerHTML={{ __html: String(label) }}
              {...amendDraggable(true)}
            />
          </Typography>
        );
      }

      case 'SwitchWithParameter': {
        return (
          <FieldSwitchWithParameter
            parameter={{ ...parameter, name }}
            error={error}
            control={control}
            register={register}
            namePrefix={isNamePrefixProps ? namePrefix : undefined}
            isNamePrefixProps={isNamePrefixProps}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            defaultValue={defaultValue}
          />
        );
      }

      case 'Accordion': {
        return (
          <FieldAccordion
            parameter={{ ...parameter, name }}
            control={control}
            namePrefix={isNamePrefixProps ? namePrefix : undefined}
            isNamePrefixProps={isNamePrefixProps}
            register={register}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            defaultValue={defaultValue}
            error={error}
          />
        );
      }

      case 'SelectByParameter': {
        return (
          <SelectByParameter
            parameter={{ ...parameter, name }}
            register={register}
            control={control}
            errors={errors}
            error={error}
            isNamePrefixProps={isNamePrefixProps}
            namePrefix={namePrefix}
            setValue={setValue}
            getValues={getValues}
          />
        );
      }

      case 'Object': {
        return (
          <AnimatedBox {...ANIMATION_PROPS} sx={style}>
            <FieldObject
              name={baseName}
              description={description}
              label={label || ''}
              control={control}
              defaultValue={prmDefault}
              renderElement={() => (
                <Fields
                  parameters={parameterList || []}
                  namePrefix={nameWithPrefix}
                  control={control}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                />
              )}
              draggable={draggable}
              {...props}
            />
          </AnimatedBox>
        );
      }

      case 'ListObject': {
        const prps = pick(parameter, ['description', 'label', 'defaultValue']);
        return (
          <AnimatedBox {...ANIMATION_PROPS} sx={style}>
            <FieldList
              name={nameWithPrefix}
              control={control}
              renderElement={(index) => (
                <Stack direction='row' gap={2.5} sx={childrenBoxStyle}>
                  <Fields
                    parameters={parameterList || []}
                    namePrefix={`${nameWithPrefix}.${index}`}
                    control={control}
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    getValues={getValues}
                  />
                </Stack>
              )}
              draggable={draggable}
              getValues={getValues}
              {...prps}
              {...props}
            />
          </AnimatedBox>
        );
      }

      case 'SelectRoles': {
        return (
          <AnimatedBox width='100%' {...ANIMATION_PROPS}>
            <RoleSelectContainer
              error={error}
              {...parameter}
              control={control}
              register={register}
              name={nameWithPrefix}
            />
          </AnimatedBox>
        );
      }

      case 'SelectBlocks': {
        return (
          <AnimatedBox width='100%' {...ANIMATION_PROPS}>
            <BlockSelectContainer
              error={error}
              {...parameter}
              control={control}
              register={register}
              name={nameWithPrefix}
            />
          </AnimatedBox>
        );
      }

      case 'Cron': {
        return (
          <AnimatedBox {...ANIMATION_PROPS} sx={style}>
            <FieldCron
              {...parameter}
              error={error}
              control={control}
              name={name}
              getValues={getValues}
            />
          </AnimatedBox>
        );
      }

      case 'Code': {
        return (
          <AnimatedBox {...ANIMATION_PROPS} sx={style}>
            <FieldCode
              error={error}
              {...parameter}
              name={name}
              control={control}
              id='handlerMessage'
            />
          </AnimatedBox>
        );
      }

      case 'Group': {
        return (
          <Box sx={style}>
            <Fields
              parameters={parameterList || []}
              register={register}
              control={control}
              errors={errors}
              warnings={warnings}
              setValue={setValue}
              namePrefix={namePrefix}
              isNamePrefixProps={isNamePrefixProps}
              getValues={getValues}
            />
          </Box>
        );
      }

      case 'MappingData': {
        return (
          <MappingData
            name={nameWithPrefix}
            register={register}
            control={control}
            draggable={draggable}
          />
        );
      }

      case 'FileField': {
        return (
          <Box marginY='1rem'>
            <Controller
              control={control}
              name={nameWithPrefix}
              render={({ field }) => (
                <UploadFiles
                  attachments={field.value}
                  parameter={parameter}
                  onChange={(attachment) => field.onChange(attachment)}
                />
              )}
            />
          </Box>
        );
      }

      case 'Tabs': {
        return (
          <Stack gap={2} flexGrow={1}>
            <FieldTabs
              parameters={parameterList || []}
              register={register}
              control={control}
              errors={errors}
              warnings={warnings}
              setValue={setValue}
              namePrefix={namePrefix}
              isNamePrefixProps={isNamePrefixProps}
              getValues={getValues}
            />
          </Stack>
        );
      }

      case 'ChoosePythonScript': {
        return (
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <ChoosePythonBeanForm
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        );
      }

      case 'Tree': {
        return (
          <Controller
            control={control}
            name={nameWithPrefix}
            render={({ field }) => (
              <div {...amendDraggable(true)}>
                <Connector1cTree
                  parameters={parameterList!}
                  defaultValue={field.value}
                  /* eslint-disable-next-line react/jsx-handler-names */
                  onChange={field.onChange}
                />
              </div>
            )}
          />
        );
      }
      default: return null;
    }
  };

  const component = dispatchRender(type);

  if (component) return component;


  const dispatchDynamicRender = (type: string) => {
    switch (type) {
      case 'RadioGroup': {
        return (onChange: OnChange) => (
          <AnimatedBox {...ANIMATION_PROPS} width='100%' sx={style}>
            <FieldRadioGroup
              {...parameter}
              groups={parameter?.parameterList}
              error={error}
              name={name}
              control={control}
              register={register}
              onChange={onChange}
            />
          </AnimatedBox>
        );
      }

      case 'Select': {
        return (onChange: OnChange) => (
          <AnimatedBox {...ANIMATION_PROPS} width='100%' sx={style}>
            <FieldSelect
              {...parameter}
              options={parameter.options || []}
              error={error}
              name={name}
              control={control}
              register={register}
              onChange={onChange}
            />
          </AnimatedBox>
        );
      }

      case 'DoubleField': { // todo unused onChange
        return (onChange: OnChange) => (
          <AnimatedBox {...ANIMATION_PROPS} width='100%'>
            <FieldInputNumber
              {...parameter}
              key={name}
              control={control}
              register={register}
              error={error}
              warning={warning}
              name={name}
              onChange={onChange}
            />
          </AnimatedBox>
        );
      }

      case 'NumberField': { // todo unused onChange
        return (onChange: OnChange) => (
          <AnimatedBox {...ANIMATION_PROPS} width='100%'>
            <FieldInputNumber
              {...parameter}
              key={name}
              control={control}
              register={register}
              error={error}
              warning={warning}
              name={name}
              onChange={onChange}
            />
          </AnimatedBox>
        );
      }

      case 'TextField': {
        return (onChange: OnChange) => (
          <AnimatedBox {...ANIMATION_PROPS} width='100%'>
            <FieldInput
              {...parameter}
              key={name}
              register={register}
              error={error}
              warning={warning}
              name={name}
              multiline
              maxRows={4}
              onChange={onChange}
            />
          </AnimatedBox>
        );
      }

      case 'TextFieldWithText': {
        return (onChange: OnChange) => (
          <Stack gap={2} sx={style}>
            <AnimatedBox {...ANIMATION_PROPS} flex={1}>
              <FieldInput
                {...parameter}
                register={register}
                error={error}
                name={name}
                onChange={onChange}
              />
            </AnimatedBox>
            {parameter.bottomText && (
              <AnimatedBox {...ANIMATION_PROPS}>
                <Controller
                  control={control}
                  name={name}
                  render={({ field }) => (
                    <SmartTypography parameter={parameter} field={field} />
                  )}
                />
              </AnimatedBox>
            )}
          </Stack>
        );
      }

      case 'Switch': {
        return (onChange: OnChange) => (
          <AnimatedBox {...ANIMATION_PROPS} sx={style}>
            <FieldSwitch
              {...parameter}
              error={error}
              name={name}
              control={control}
              onChange={onChange}
            />
          </AnimatedBox>
        );
      }

      case 'Autocomplete': {
        const prefixedName = props?.useParams ? name : nameWithPrefix;
        return (onChange: OnChange) => (
          <Stack gap={2} flexGrow={1}>
            <AnimatedBox width='100%' {...ANIMATION_PROPS}>
              <FieldAutocomplete
                error={error?.message}
                {...parameter}
                options={parameter.options || []}
                control={control}
                register={register}
                name={prefixedName}
                onChange={onChange}
              />
            </AnimatedBox>
            {parameter.bottomText && (
              <AnimatedBox {...ANIMATION_PROPS}>
                <Controller
                  control={control}
                  name={name}
                  render={({ field }) => (
                    <SmartTypography parameter={parameter} field={field} />
                  )}
                />
              </AnimatedBox>
            )}
          </Stack>
        )
      }

      case 'Password': {
        return (onChange: OnChange) => (
          <AnimatedBox width='100%' {...ANIMATION_PROPS}>
            <FieldPassword
              error={error}
              {...amendDraggable(true)}
              {...parameter}
              register={register}
              name={name}
              onChange={onChange}
            />
          </AnimatedBox>
        );
      }

      case 'ListString': { // todo unused onChange
        return (onChange: OnChange) => (
          <AnimatedBox width='100%' {...ANIMATION_PROPS} sx={style}>
            <FieldListString
              error={error}
              {...parameter}
              name={nameWithPrefix}
              control={control}
              onChange={onChange}
            />
          </AnimatedBox>
        );
      }

      default: return null;
    }
  };

  const render = dispatchDynamicRender(type);

  if (!render) return null;

  const {
    isSendSkeletonForEnrichByButton,
    isSendSkeletonForEnrichByDebounce,
  } = parameter;

  return (
    <UpdateSkeletonWrapper
      isSendSkeletonForEnrichByButton={isSendSkeletonForEnrichByButton}
      isSendSkeletonForEnrichByDebounce={isSendSkeletonForEnrichByDebounce}
      sx={style}
      render={render}
    />
  );
}

/**
 * Компонент генерации формы по параметрам с бэка
 */
export const Fields: FC<FieldsProps> = observer(
  (props) => {
    const { parameters } = props;

    if (!parameters?.length) return null;
    const fieldRender = buildRender(props);
    return (
      <>
        {parameters.map(fieldRender)}
      </>
    );
  }
);
