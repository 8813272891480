import { computed, makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';

import { Normalized } from '@/shared/lib/normalize';
import { buildBlockTree } from '@/widgets/MessageTracing/ui/BlockNode/lib/buildBlockTree'; // fixme illegal import by FSD
import { Exchange, ExecutorHistory } from '../../Connect/types';
import { ElementEditor, Relation } from '../../Flow/types';
import { defaultFilter, defaultOption } from '@/shared/ui/FilterMessageTracing/utils/constants';
import { FilterListIDB, FilterRequest } from '@/shared/ui/FilterMessageTracing/type';
import { MessagesTree, Tabs } from './types';

@singleton()
export class DisplayMessageStore {
  executorHistory: ExecutorHistory;

  exchangeList: Normalized<Exchange> | null; // todo eliminate

  relationSet: Relation[];

  filterOption: string[];

  filterList: FilterRequest[];

  filterValues: FilterListIDB | null;

  canvas: Normalized<ElementEditor> | null;

  messagesTree: MessagesTree | null;

  messages: Normalized<ExecutorHistory> | null;

  messageNumber: number;

  messageTotal: number;

  isLoadingMessages: boolean;

  selectedExecutorHistoryId: string | null;

  selectedExecutorHistory: ExecutorHistory | null;

  isResendLoading: boolean;

  activeTab: string;

  maxWidth?: number | null;

  maxHeight?: number | null;

  updateData?: number;

  page?: number | null;

  messageId?: string;

  blockId?: string | null;

  flowId: string | null;

  filterId: string | null;

  constructor() {
    this.executorHistory = null;
    this.exchangeList = null;
    this.canvas = null;
    this.messages = null;
    this.messagesTree = null;
    this.filterOption = defaultOption;
    this.filterList = defaultFilter;
    this.filterValues = null;
    this.messageId = null;
    this.relationSet = [];
    this.messageNumber = 0;
    this.messageTotal = 0;
    this.isLoadingMessages = false;
    this.selectedExecutorHistoryId = null;
    this.selectedExecutorHistory = null;
    this.isResendLoading = false;
    this.activeTab = Tabs.tracing;
    this.updateData = 0;
    this.page = 0;
    this.blockId = null;
    this.flowId = null;
    this.filterId = null;

    makeAutoObservable(this, { blockTree: computed });
  }

  get blockTree() {
    return buildBlockTree(this.executorHistory);
  }
}
