import * as React from 'react';
import { FC, memo } from 'react';

import cn from 'classnames';
import { Controller } from 'react-hook-form';
import { Stack } from '@mui/system';

import { amendDraggable } from '@/shared/lib/extendDndkit';
import { Switch } from '@/shared/ui/Switch/Switch';
import { FieldProps } from '@/shared/ui/Fields/Fields';
import { CHECKED_DICTIONARY } from '@/shared/ui/Fields/constants';
import { Label } from '@/shared/ui/Label/Label';

import styles from './FieldSwitch.module.scss';

export interface FieldSwitchProps extends Omit<FieldProps, 'register' | 'error'> {
  onChange?: () => void;
}

export const FieldSwitch: FC<FieldSwitchProps> = memo(
  ({ label, name, control, defaultValue, isDisabled, description, invertChecked, onChange }) => {
    return (
      <Stack
        className='nodrag'
        direction='row'
        alignItems='center'
        gap={label && 1.5}
        {...amendDraggable(true)}
      >
        <Controller
          name={name}
          defaultValue={defaultValue}
          control={control}
          render={({ field }) => {
            const checked =
              typeof field.value === 'string'
                ? CHECKED_DICTIONARY[field.value as keyof typeof CHECKED_DICTIONARY]
                : field.value;
            const finalChecked = invertChecked ? !checked : checked;

            return (
              <div className={cn({ [styles.disabled]: isDisabled })}>
                <Switch
                  id={name}
                  checked={finalChecked}
                  invertChecked={invertChecked}
                  onChange={(checked) => {
                    field.onChange(checked);
                    if (onChange) onChange();
                  }}
                  disabled={isDisabled}
                />
              </div>
            );
          }}
        />

        <Label
          name={name}
          className={cn(styles.label, { [styles.disabled]: isDisabled })}
          tooltip={description}
        >
          {label}
        </Label>
      </Stack>
    );
  }
);
