import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Normalized } from '@/shared/lib/normalize';
import { WSDLResponse } from '@/entities/WSDLService/types';

@singleton()
export class WSDLAppStore {
  wsdlApps: Normalized<WSDLResponse> | null;

  isLoadingUpdateWSDLApp: boolean;

  isLoadingDeleteWSDLApp: boolean;

  isLoadingWSDLApp: boolean;

  isErrorSubmit: boolean;

  constructor() {
    this.wsdlApps = null;
    this.isLoadingUpdateWSDLApp = false;
    this.isLoadingDeleteWSDLApp = false;
    this.isLoadingWSDLApp = false;
    this.isErrorSubmit = false;

    makeAutoObservable(this);
  }
}
