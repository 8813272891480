import React, { FC, useCallback, useState } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import Grid from '@mui/material/Grid';
import { Notification, NotificationStore } from '@/entities/Notification';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { FieldSwitch } from '@/shared/ui/Fields/components/FieldSwitch/FieldSwitch';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import Paper from '@mui/material/Paper';
import Button from '@/shared/ui/Button';
import { notify } from '@/shared/ui/Toast/notify';
import { isEmpty } from 'lodash';
import { UpsertNotificationService } from '@/features/UpsertNotification/api/UpsertNotificationService';

import { AdditionalNotificationSettings } from './AdditionalNotificationSettings';
import { validateForm } from '../lib/validateForm';
import { MainNotificationSettings } from './MainNotificationSettings';

const notificationStore = container.resolve(NotificationStore);
const upsertNotificationService = container.resolve(UpsertNotificationService);

export interface UpsertNotificationFormProps {
  notificationId: string | null;
  footer?: JSX.Element;

  onSuccess?(): void;
}

export const UpsertNotificationForm: FC<UpsertNotificationFormProps> = observer(
  ({ notificationId, footer, onSuccess }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const methods = useForm<Notification>({
      values: notificationId
        ? notificationStore.notifications?.entities[notificationId]
        : (validateForm.cast({}) as Notification),
      resolver: yupResolver(validateForm),
      mode: 'onBlur',
    });

    const { register, control, handleSubmit } = methods;

    const submitButtonText = notificationId ? 'Применить изменения' : 'Добавить';

    const handleFormSubmit = useCallback<SubmitHandler<Notification>>(
      async (data) => {
        try {
          setIsLoading(true);

          if (notificationId) {
            await upsertNotificationService.upsertNotification({
              ...data,
              id: notificationId,
            });
            notify.success('Уведомление изменено');
          } else {
            if (isEmpty(data.cron)) {
              data.cron = '0 * * ? * * *';
            }

            await upsertNotificationService.upsertNotification(data);
            notify.success('Уведомление добавлено');
            onSuccess?.();
          }
        } catch {
          if (notificationId) {
            notify.error('Не удалось изменить уведомление');
          } else {
            notify.error('Не удалось создать уведомление');
          }
        } finally {
          setIsLoading(false);
        }
      },
      [notificationId, onSuccess]
    );

    return (
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        style={{
          display: 'flex',
          flexGrow: 1,
        }}
      >
        <Paper elevation={0} sx={{ width: '100%' }}>
          <Stack gap={2} justifyContent="space-between" height="100%">
            <Box sx={{ overflowY: 'auto' }} p={4} maxHeight='calc(100vh - 282px)'>
              <FormProvider {...methods}>
                <Stack gap={5}>
                  <Grid container spacing={2.5}>
                    <Grid xs={12} lg={6} xl={3} item>
                      <FieldInput error='' name='name' label='Наименование' register={register} />
                    </Grid>
                    <Grid xs={12} lg={6} xl={5} item>
                      <FieldInput
                        error=''
                        name='description'
                        label='Описание'
                        register={register}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <FieldSwitch
                        error=''
                        control={control}
                        name='isActive'
                        defaultValue='true'
                        label='Активность уведомлений'
                      />
                    </Grid>
                  </Grid>
                  <Box>
                    <MainNotificationSettings notificationId={notificationId} />
                  </Box>
                  <Box>
                    <AdditionalNotificationSettings />
                  </Box>
                </Stack>
              </FormProvider>
            </Box>
            <Stack direction='row' gap={2.5} p={2} justifyContent='flex-end'>
              {footer}
              <Box width={212}>
                <Button type='submit' loading={isLoading}>
                  {submitButtonText}
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Paper>
      </form>
    );
  }
);

UpsertNotificationForm.displayName = 'UpsertNotificationForm';
